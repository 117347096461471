import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import toast, {Toaster} from "react-hot-toast";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    '@media (min-width: 1024px)': {
        width: '30%',
    },
};

const UpdateModal = ({ open, handleCloseDetails, selectedResponse }) => {
    const validation = (value, pattern) => {
        return pattern.test(value);
    };
    const [formData, setFormData] = useState({
        user_id: '',
        name: '',
        lastname: '',
        email: '',
        password: '',
        phone: '',
    });

    // Cuando selectedResponse cambie, actualiza los datos del formulario
    useEffect(() => {
        if (selectedResponse) {
            setFormData({
                user_id: selectedResponse.user_id,
                name: selectedResponse.name,
                lastname: selectedResponse.lastname,
                email: selectedResponse.email,
                password: '', // Asegúrate de manejar la contraseña adecuadamente si es necesario
                phone: selectedResponse.phone_number,
            });
        }
    }, [selectedResponse]);

    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;
        switch (name) {
            case "name":
            case "lastname":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            default:
                pattern = null;
        }
        const formatted = value.replace(/-/g, '');
        const isValidated = pattern && validation(formatted, pattern);
        if (value === '' || isValidated) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = formatted.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            // Actualiza el estado local según el nombre del campo
            setFormData({
                ...formData,
                [name]: formattedInput
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // Actualiza el estado local según el nombre del campo
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const submit = (e) => {
        e.preventDefault();
        const id = selectedResponse.id;
        const formattedData = {
            ...formData,
            user_id: id,
        };
        console.log(formattedData);
        fetch('http://127.0.0.1:5000/api/v3/manageUsersUPD', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('No data to fetch')
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    })
                }
            })
            .then(data =>{
                console.log(data)
                if (data) {
                    alert("User has been updated")
                }else{
                    alert("Data is empty")
                }
            })
            .catch(error => {
                alert(error.message || 'Server responded with an error.');
                console.log(error);
            })
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCloseDetails}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {selectedResponse && (
                            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex justify-end px-4 pt-4">
                                    <button
                                        id="dropdownButton"
                                        onClick={handleCloseDetails}
                                        className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                        type="button"
                                    >
                                        <span className="sr-only">Open dropdown</span>
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6 18 17.94 6M18 18 6.06 6"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex flex-col items-center pb-10 space-y-2">
                                    <img
                                        className="w-auto h-32 mb-3"
                                        src="/modales.png"
                                        alt="Avatars"
                                    />
                                    <h1 className={"font-bold text-xl"}>Update user:</h1>
                                    <h1 className={"text-md mb-4"}>{selectedResponse.name}</h1>
                                    <form className={"p-4"} onSubmit={submit}>
                                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                                            <div>
                                                <label
                                                    htmlFor="first_name"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="first_name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="John"
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="last_name"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="last_name"
                                                    name="lastname"

                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Doe"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="phone"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Phone number
                                            </label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={(e) => {
                                                    handle(e);
                                                    handleInputChange(e)
                                                }}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="123-45-678"
                                                required
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <label
                                                htmlFor="email"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="john.doe@company.com"
                                                required
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <label
                                                htmlFor="21_password"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                id="21_password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                minLength={8}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="*********"
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};


const CreateModal = ({openCreate, handleCloseCreate}) => {
    const [phone, setPhone] = useState("");
    const [first_name, setFirst] = useState("");
    const [last_name, setLast] = useState("");
    const [parent, setParents] = useState([]);
    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;
        let sqlInjectionPattern;

        // Patrón para validación de entrada
        switch(name) {
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            case "name":
            case "last_name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            default:
                pattern = null;
        }

        // Patrón para prevenir la inyección SQL
        sqlInjectionPattern = /(;|\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|TRUNCATE|EXEC|UNION|FROM|WHERE|AND|OR)\b|[-'";\\])/i;

        const isValid = pattern ? pattern.test(value) : true;
        const isSafeFromInjection = !sqlInjectionPattern.test(value);

        if ((value === '' || isValid) && isSafeFromInjection) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "phone":
                    setPhone(formattedInput);
                    break;
                case "name":
                    setFirst(formattedInput);
                    break;
                case "last_name":
                    setLast(formattedInput);
                    break;
                default:
            }
        }
    }
    const [formData, setFormData] = useState({
        password: '',
        repeatPassword: '',
        name: '',
        last_name: '',
        parent_id: '',
        phone: '',
        email: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendUser = async (e) =>{
        e.preventDefault();
        console.log(formData);
        if (formData.password !== formData.repeatPassword) {
            alert('Please check passwords, are different');
            return;
        }
        fetch('http://127.0.0.1:5000/api/v6/registro', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    console.log("success")
                } else {
                    return response.json().then(error => {
                        throw new Error(error.error || 'Server responded with an error.');
                    });
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        toast.promise(
            sendUser(e),
            {
                loading: 'Saving...',
                success: <b>Save user Account successfully!</b>,
                error: <b>Could not save.</b>,
            },

        );
    };
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        const formsdata = {
            parent_id: storedUser.parents_id,
        }
        console.log(formsdata)
        fetch('http://127.0.0.1:5000/api/get_parents_byID', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formsdata)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.error || 'Server responded with an error.');
                    });
                }
            })
            .then(data =>{
                setParents(data)
                console.log(data)
            })
            .catch(error => console.error('Error fetching languages:', error));
    }, []);

    return (
        <div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCreate}
                onClose={handleCloseCreate}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCreate}>
                    <Box sx={style}>
                            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex justify-end px-4 pt-4">
                                    <button
                                        id="dropdownButton"
                                        onClick={handleCloseCreate}
                                        className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                        type="button"
                                    >
                                        <span className="sr-only">Open dropdown</span>
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6 18 17.94 6M18 18 6.06 6"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex flex-col items-center pb-10 space-y-2">
                                    <h1 className={"font-semibold text-3xl py-4"}>Create an User Account</h1>
                                    <form className="px-4" onSubmit={handleSubmit}>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <input type="email" name="email" onChange={(e) => {
                                                handleInputChange(e)
                                            }} id="floating_email"
                                                   className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                   placeholder=" " required/>
                                            <label htmlFor="floating_email"
                                                   className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email
                                                address</label>
                                        </div>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <input type="password" name="password" minLength="8" onChange={(e) => {
                                                handleInputChange(e)
                                            }} id="floating_password"
                                                   className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                   placeholder=" " required/>
                                            <label htmlFor="floating_password"
                                                   className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                                        </div>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <input type="password" name="repeatPassword" minLength="8"
                                                   onChange={(e) => {
                                                       handleInputChange(e)
                                                   }} id="floating_repeat_password"
                                                   className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                   placeholder=" " required/>
                                            <label htmlFor="floating_repeat_password"
                                                   className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm
                                                password</label>
                                        </div>
                                        <div className="grid md:grid-cols-2 md:gap-6">
                                            <div className="relative z-0 w-full mb-5 group">
                                                <input type="text" name="name" value={first_name} onChange={(e) => {
                                                    handleInputChange(e)
                                                    handle(e);
                                                }} id="floating_first_name"
                                                       className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                       placeholder=" " required/>
                                                <label htmlFor="floating_first_name"
                                                       className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                                                    name</label>
                                            </div>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <input type="text" name="last_name" value={last_name} onChange={(e) => {
                                                    handleInputChange(e)
                                                    handle(e);
                                                }} id="floating_last_name"
                                                       className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                       placeholder=" " required/>
                                                <label htmlFor="floating_last_name"
                                                       className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last
                                                    name</label>
                                            </div>
                                        </div>
                                        <div className="grid md:grid-cols-2 md:gap-6">

                                            <div className="relative z-0 w-full mb-5 group">
                                                <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone"
                                                       value={phone}
                                                       onChange={(e) => {
                                                           handleInputChange(e)
                                                           handle(e);
                                                       }}
                                                       id="floating_phone"
                                                       className="block py-2.5 px-0 w-full text-lg text-gray-800 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                       placeholder=" " required/>
                                                <label htmlFor="floating_phone"
                                                       className="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                                                    number</label>
                                            </div>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <select
                                                    id="underline_select_parent"
                                                    name="parent_id"
                                                    value={formData.parent}
                                                    onChange={(e) => {
                                                        handleInputChange(e);
                                                    }}
                                                    className="block py-2.5 px-0 w-full text-base text-gray-800 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                                >
                                                    <option value="1">Company</option>
                                                    {Object.entries(parent).map(([code, name]) => (
                                                        <option key={code} value={code}
                                                                className={"text-gray-900"}>{name}</option>
                                                    ))}

                                                </select>

                                            </div>
                                        </div>
                                        <nav className="text-center space-x-0 sm:space-x-9 py-4">
                                            <button type="submit"
                                                    className="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create
                                            </button>
                                                <button type="reset" onClick={handleCloseCreate}
                                                        className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                                                </button>
                                        </nav>
                                    </form>
                                </div>
                            </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export {UpdateModal, CreateModal};
