import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import CookieModal from "../modal/cookieModal";

const Footer = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    // /policy?pdf=MASTER_AML_BSA_03_23_2021-1.monesure.pdf ||| /policy?pdf=B2_Underwriting_Risk_Monitoring_Guidelines.monesure.pdf || /policy?pdf=B2_Underwriting_Risk_Monitoring_Guidelines.monesure2.pdf
    return(
        <>
            <footer className="mt-8 content-center">
                <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center h-fit  lg:mx-32 md:mx-8 sm:mx-8 ">
                    <nav className="w-96 text-center grid justify-items-center content-center">
                        <div className="grid justify-center">
                            <img src="/MoneSureLogoG.png" alt=""/>
                        </div>
                        <p className="text-base py-4">MoneSure is a one-stop platform with endless solutions. This
                            platform
                            enables you to Pay or Get Paid Anyway You Want. Credit, Debit, ACH, Check, Wallet-to-Wallet,
                            Wire, and More.</p>
                        <button className="text-red-500 font-bold cursor-pointer text-lg" onClick={openModal} >Show Cookies Policy</button>
                        <div className="flex justify-items-center ">
                            <Link to={""}  rel="noopener noreferrer" className="text-blue-400 font-bold cursor-pointer" >Polices & Procedures</Link>
                            <span className="mx-2 font-bold">| </span>
                            <Link to={""}  rel="noopener noreferrer"><h1 className="text-blue-400 font-bold">Guides</h1></Link>
                            <span className="mx-2 font-bold">| </span>
                            <Link to={""}  rel="noopener noreferrer"><h1 className="text-blue-400 font-bold" >More Guides</h1></Link>
                        </div>

                    </nav>
                    <nav className="w-96 text-center">
                        <h1 className="font-bold text-lg">Menu</h1>
                        <ul>
                            <li className="p-2"><Link to="/">Home</Link></li>
                            <li className="p-2"><Link to="/about">About Us</Link></li>
                            <li className="p-2"><Link to="/service">Services</Link></li>
                            <li className="p-2"><Link to="/pricing">Pricing</Link></li>
                            <li className="p-2"><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </nav>
                    <nav className="w-96">
                        <h1 className="font-bold text-lg">Subscribe Now</h1>
                        <ul className={"py-4"}>
                            <li className="flex justify-start p-2">
                                <svg className="w-6 h-6 text-card2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
                                 </svg>
                                (424) 303-3228
                            </li>
                            <li className="flex justify-start p-2">
                                <svg className="w-6 h-6 text-card2" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M11 16v-5.5A3.5 3.5 0 0 0 7.5 7m3.5 9H4v-5.5A3.5 3.5 0 0 1 7.5 7m3.5 9v4M7.5 7H14m0 0V4h2.5M14 7v3m-3.5 6H20v-6a3 3 0 0 0-3-3m-2 9v4m-8-6.5h1"/>
                                </svg>

                                <span className="font-semibold mr-2">Contact Us:</span> info@MoneSure.com
                            </li>
                            <li className="flex justify-start p-2">
                                <svg className="w-6 h-6 text-card" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
                                </svg>

                                1309 Coffeen Sheridan Wy. 82801
                            </li>
                        </ul>
                    </nav>
                </div>
                <nav className="content-center grid justify-items-center bg-navfooter h-24 mt-8">
                    <h2 className="text-white text-sm">Copyright @ 2024 MoneSure All Right Reserved</h2>
                </nav>
            </footer>
            <CookieModal modalIsOpen={modalIsOpen} closeModal={closeModal}/>
        </>
    );
}
export default Footer;