import React from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import { format } from 'date-fns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    '@media (min-width: 1024px)': {
        width: '30%',
    },
};

const formatDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return format(parsedDate, "dd/MM/yyyy HH:mm:ss");
}
const ResponseModal = ({open, handleCloseDetails, selectedResponse  }) =>{
    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleCloseDetails}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={open}>
                        <Box sx={style}>
                            {selectedResponse && (
                                <>
                                    <div
                                        className="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex justify-end px-4 pt-4">
                                            <button id="dropdownButton" onClick={handleCloseDetails}
                                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                                    type="button">
                                                <span className="sr-only">Open dropdown</span>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1"
                                                          d="M6 18 17.94 6M18 18 6.06 6"/>
                                                </svg>

                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center pb-10 space-y-2">
                                            <img className="w-auto h-32 mb-3"
                                                 src="/modales.png" alt="Avatars"/>
                                            <h1 className={"b-1 text-xl font-medium text-gray-900 dark:text-white"}>ID: {selectedResponse.addresses_response_id}</h1>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Date to Create: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{formatDate(selectedResponse.created_at)}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Date to Update: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{formatDate(selectedResponse.updated_at)}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Object: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponse.object}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Mode: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponse.object}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Shipment Identifier: </h1>
                                                <span
                                                   className="text-sm text-gray-500 dark:text-gray-400 text-center p-4">{selectedResponse.id}</span>
                                            </dt>
                                        </div>
                                    </div>

                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
const ResponseParcelModal = ({openP, handleClose, selectedResponseParcel  }) =>{
    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openP}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={openP}>
                        <Box sx={style}>
                            {selectedResponseParcel && (
                                <>
                                    <div
                                        className="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex justify-end px-4 pt-4">
                                            <button id="dropdownButton" onClick={handleClose}
                                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                                    type="button">
                                                <span className="sr-only">Open dropdown</span>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1"
                                                          d="M6 18 17.94 6M18 18 6.06 6"/>
                                                </svg>

                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center pb-10 space-y-2">
                                            <img className="w-auto h-32 mb-3"
                                                 src="/modales.png" alt="Avatars"/>
                                            <h1 className={"b-1 text-xl font-medium text-gray-900 dark:text-white"}>ID: {selectedResponseParcel.parcel_response_id}</h1>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Date to Create: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{formatDate(selectedResponseParcel.created_at)}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Date to Update: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{formatDate(selectedResponseParcel.updated_at)}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Height: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseParcel.height}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Length: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseParcel.length}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Width: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseParcel.width}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Weight: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseParcel.weight}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Shipment
                                                    Identifier: </h1>
                                                <span
                                                    className="text-sm text-gray-500 dark:text-gray-400 text-center p-4">{selectedResponseParcel.id}</span>
                                            </dt>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
const ResponseTrackModal = ({openT, handleCloseT, selectedResponseTracker  }) =>{
    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openT}
                    onClose={handleCloseT}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={openT}>
                        <Box sx={style}>
                            {selectedResponseTracker && (
                                <>
                                    <div
                                        className="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex justify-end px-4 pt-4">
                                            <button id="dropdownButton" onClick={handleCloseT}
                                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                                    type="button">
                                                <span className="sr-only">Open dropdown</span>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1"
                                                          d="M6 18 17.94 6M18 18 6.06 6"/>
                                                </svg>

                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center pb-10 space-y-2">
                                            <img className="w-auto h-32 mb-3"
                                                 src="/modales.png" alt="Avatars"/>
                                            <h1 className={"b-1 text-xl font-medium text-gray-900 dark:text-white"}>ID: {selectedResponseTracker.tracker_response_id}</h1>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Object: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseTracker.object}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Mode: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseTracker.mode}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Tracking Code: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseTracker.tracking_code}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Carrier: </h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedResponseTracker.carrier}</span>
                                            </dt>
                                            <dt className={"w-full grid grid-cols-1 content-center justify-center"}>
                                                <h1 className={"font-bold text-lg text-center"}>Tracker ID: </h1>
                                                <span
                                                    className="text-sm text-gray-500 dark:text-gray-400 text-center p-4">{selectedResponseTracker.tracker_id}</span>
                                            </dt>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
export {ResponseModal, ResponseParcelModal,ResponseTrackModal};