import React from 'react';
import {Link} from "react-router-dom";

const SlideVDigitalPayments = () =>{
    return(
        <>
            <section className="bg-gradient-to-l from-oscuro from-40% to-card2 to-90% w-full h-fit content-center">
                <nav
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center px-16">
                    <div>
                        <img src="/payments.svg" alt="" className="h-full w-full p-8"/>
                    </div>
                    <div className="content-center ">
                        <h1 className="text-white font-semibold text-4xl p-4">Empowering You to Manage Your Digital
                            Payments</h1>
                        <p className="text-white text-md p-4">We offer a digital payment network that is reliable and
                            secure, featuring advanced fraud protection and compliance measures. Our real-time payment
                            processing ensures that transactions are completed swiftly and seamlessly.</p>
                        <p className="text-white text-md p-4 mb-8">Our platform integrates with major financial
                            institutions, allowing you to easily and securely manage payments across various methods and
                            services.</p>
                        <Link to="/pricing">
                            <button className="bg-blue-600 rounded-full"><p
                                className="text-white font-semibold p-3 mx-6">Learn More</p></button>
                        </Link>
                    </div>
                </nav>
            </section>
        </>
    );
}
export default SlideVDigitalPayments;