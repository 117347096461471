import React, {useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

const CheckFormUser = ( ) => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();

    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [bank_name, setBankName] = useState("");
    const [bank_addr, setBankAddress] = useState("");
    const [name_on_checks, setName_on_checks] = useState("");
    const [starting_check_num, setStarting] = useState("")
    const [fractional_routing, setFractionalRouting] = useState("")
    const [address_on_checks, setAddressCheck] = useState("")
    const [return_mailto, setMailto] = useState("");
    const [return_address, setretretAddress] = useState("");
    const [void_text, setvoidText] = useState("");
    const [sig_text, setSignatureText] = useState("");
    const [sec_sig_threshold, setAmount] = useState("");
    const [redirect_uri, setredirect_uri] = useState("")
    const [buttonValue, setButtonValue] = useState('Checking Information');
    const [isLoading, setIsLoading] = useState(false);
    const [formsDatas, setFormsData] = useState({
        name:'',
        desc: '',
        acct_type: '',
        bank_name: '',
        bank_addr: '',
        name_on_checks: '',
        starting_check_num: '',
        fractional_routing: '',
        address_on_checks: '',
        return_mailto: '',
        return_address: '',
        void_text: '',
        sig: null,
        sig2: null,
        sig_text: '',
        sec_sig_threshold: '',
        is_business: '',
        redirect_uri: '',
        user_profile_id: ''
    });
    const validation = (value, pattern) => {
        return pattern.test(value);
    };
    const changes = (e) =>{
        const { name, value } = e.target;
        let patter;

        switch(name) {
            case "name":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s']+$/;
                break;
            case "desc":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "bank_name":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s']+$/;
                break;
            case "bank_addr":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "name_on_checks":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "starting_check_num":
                patter = /^\d{1,28}$/;
                break;
            case "fractional_routing":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "address_on_checks":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9@.\s']+$/;
                break;
            case "return_mailto":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9@.\s']+$/;
                break;
            case "return_address":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9@.\s']+$/;
                break;
            case "void_text":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "sig2":
            case "sig":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "sig_text":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s']+$/;
                break;
            case "sec_sig_threshold":
                patter = /^\d{1,28}$/;
                break;
            case "redirect_uri":
                patter = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9/:.\s']+$/;
                break
            default:
                patter = null;
        }

        const formattedValue = value.replace(/-/g, '');
        const isValid = patter && validation(formattedValue, patter);

        if (value === '' || isValid) {
            let formattedInput = value;

            switch(name) {
                case "name":
                    setName(formattedInput);
                    break;
                case "desc":
                    setDesc(formattedInput);
                    break;
                case "bank_name":
                    setBankName(formattedInput);
                    break;
                case "bank_addr":
                    setBankAddress(formattedInput);
                    break;
                case "name_on_checks":
                    setName_on_checks(formattedInput);
                    break;
                case "starting_check_num":
                    setStarting(formattedInput);
                    break;
                case "fractional_routing":
                    setFractionalRouting(formattedInput);
                    break;
                case "address_on_checks":
                    setAddressCheck(formattedInput);
                    break;
                case "return_mailto":
                    setMailto(formattedInput);
                    break;
                case "return_address":
                    setretretAddress(formattedInput);
                    break;
                case "void_text":
                    setvoidText(formattedInput);
                    break;
                case "sig_text":
                    setSignatureText(formattedInput);
                    break;
                case "sec_sig_threshold":
                    setAmount(formattedInput);
                    break;
                case "redirect_uri":
                    setredirect_uri(formattedInput);
                    break;
                default:
            }
        }
    };
    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            setFormsData({
                ...formsDatas,
                [fieldName]: file
            });
        }
    };
    const HandleInputChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            handleFileChange(e, name);
        } else {
            setFormsData({
                ...formsDatas,
                [name]: value
            });
        }
    };
    const SubmitHandle = (e) =>{
        e.preventDefault()

        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formDataToSend = {
            ...formsDatas,
            user_profile_id: storedUser.profile.user_profile_id,
            sig: formsDatas.sig
        };
        console.log(formDataToSend);
        fetch('http://127.0.0.1:5000/api/v3/add_bankaccounts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataToSend)
        })
            .then(response =>{
                if (response.ok){
                    return response.json();
                }else {
                    return response.json().then(error =>{
                        throw new Error(error.message || 'Server respond with an error')
                    })
                }
            })
            .then(data => {
                console.log(data)

                    setIsLoading(false)
                    setButtonValue('Checking Information');
                    const alertaSuccess = document.getElementById('alertaS');
                    alertaSuccess.classList.remove('hidden');
                    alertaSuccess.classList.add('flex');
                    setTimeout(() => {
                        alertaSuccess.classList.add('hidden');
                        alertaSuccess.classList.remove('flex');
                    }, 3000);
            })
            .catch(error =>{
                setIsLoading(false)
                setButtonValue('Send Information');
                const alertaError = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaError.classList.remove('hidden');
                alertaError.classList.add('flex');

                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaError.classList.add('hidden');
                    alertaError.classList.remove('flex');
                }, 3000);
            })

    }

    return(
        <>

            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <div>
                        <h1 className={"font-bold text-4xl"}>Add a Bank Account</h1>
                        <p className={"py-2 text-md text-gray-400"}>Here you can create a new Bank account</p>
                    </div>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
            <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Information sent successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="w-fit flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p></span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24"  onSubmit={SubmitHandle}>
                    <nav
                        className={`w-full  rounded-xl border-2 p-8 bg-white shadow-3xl ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="name" value={name} onChange={(e) => {
                                HandleInputChange(e);
                                changes(e)
                            }} id="floating_name"
                                   className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_name"
                                   className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name
                                of Bank Account</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <label htmlFor="message"
                                   className="block mb-2 text-base font-medium text-gray-900 dark:text-white">Bank
                                Account Description</label>
                            <textarea id="message" rows="3" name="desc" value={desc} onChange={(e) => {
                                HandleInputChange(e);
                                changes(e)
                            }}
                                      className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      placeholder="Write your short description..."></textarea>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <select id="underline_select" name="acct_type" onChange={(e) => {
                                HandleInputChange(e);
                                changes(e)
                            }}
                                    className="block py-2.5 px-0 w-full text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                <option value="" selected={true} disabled>Choose a type</option>
                                <option value="checking">Checking</option>
                                <option value="saving">Savings</option>
                            </select>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="bank_name" id="floating_bank_name" value={bank_name}
                                       onChange={(e) => {
                                           HandleInputChange(e);
                                           changes(e)
                                       }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_bank_name"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Bank
                                    name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="bank_addr" id="floating_bank_adrr" value={bank_addr}
                                       onChange={(e) => {
                                           HandleInputChange(e);
                                           changes(e)
                                       }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_bank_adrr"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Bank
                                    Address</label>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="name_on_checks" value={name_on_checks} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       id="floating_name_checks"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_name_checks"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name
                                    to print on checks</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="starting_check_num" id="floating_starting_num"
                                       value={starting_check_num} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_starting_num"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Check
                                    number to start</label>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="fractional_routing" value={fractional_routing}
                                       onChange={(e) => {
                                           HandleInputChange(e);
                                           changes(e)
                                       }}
                                       id="floating_routing"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_routing"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Routing
                                    number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="address_on_checks" id="floating_address_check"
                                       value={address_on_checks} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_address_check"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address
                                    to print on checks</label>
                            </div>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="return_mailto" value={return_mailto} onChange={(e) => {
                                HandleInputChange(e);
                                changes(e)
                            }}
                                   id="floating_return_mailto"
                                   className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_return_mailto"
                                   className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address
                                to checks mailed.</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="return_address" id="floating_return_address" value={return_address}
                                   onChange={(e) => {
                                       HandleInputChange(e);
                                       changes(e)
                                   }}
                                   className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_return_address"
                                   className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address
                                to checks mailed from this account.</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="void_text" value={void_text} onChange={(e) => {
                                HandleInputChange(e);
                                changes(e)
                            }}
                                   id="floating_void_text"
                                   className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_void_text"
                                   className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Void
                                line on checks</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                   htmlFor="file_input">Upload Signature IMG</label>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="file_input_help" id="file_input" type={"file"}
                                onChange={(e) => handleFileChange(e, 'sig')}
                                accept="image/*"/>

                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                   htmlFor="file_input">Upload Signature IMG Two</label>
                            <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="file_input_help2" id="file_input2" type={"file"}
                                onChange={(e) => handleFileChange(e, 'sig2')}
                                accept="image/*"/>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="sig_text" value={sig_text} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       id="floating_sig_text"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_sig_text"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name
                                    to use for the signature</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <span className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500">
                                        <AttachMoneyOutlinedIcon/>
                                        <i className="fas fa-dollar-sign text-gray-500"></i>
                                    </span>
                                <input type="text" name="sec_sig_threshold" id="floating_sec_sig_threshold"
                                       value={sec_sig_threshold} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_sec_sig_threshold"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Dollar
                                    amount</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="redirect_uri" id="floating_redirect_uri"
                                       value={redirect_uri} onChange={(e) => {
                                    HandleInputChange(e);
                                    changes(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_sec_sig_threshold"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Url
                                    to redirect:</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input id="default-checkbox" type="checkbox" name={"is_business"} value={1}
                                       onChange={(e) => {
                                           HandleInputChange(e);
                                       }}
                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor="default-checkbox"
                                       className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is business
                                    account?</label>
                            </div>
                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-8 group">
                        <button type="submit" disabled={isLoading}
                                className=" text-gray-200 bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-2/3 px-5 sm:px-24 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{isLoading ? (
                            <div role="status" className="">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            buttonValue
                        )}
                        </button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default CheckFormUser;