import React from 'react';
import {Link} from "react-router-dom";

const MarketingAnnounced = () => {
    return(
        <>
            <section className="bg-gray-900 ">
                <nav className="w-full grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 px-16">
                    <div className="content-start p-14">
                        <h1 className="font-bold text-4xl text-white"><span className="text-primaryV">What is included in the</span> 'Clean
                            House'</h1>
                        <p className="text-white">Our 'Clean House' service includes system evaluations, local reviews
                            and search optimization, issue identification, and ongoing maintenance.</p>
                    </div>
                    <div className="content-start p-14">
                        <h1 className="font-bold text-4xl text-white"><span className="text-primaryV">How can your marketing solutions</span> benefit
                            my business?</h1>
                        <p className="text-white mb-8">We provide tailored marketing strategies, content creation, SEO
                            optimization, and
                            reputation management to enhance your business's visibility and engagement.</p>

                    </div>
                </nav>
                <Link to="/contact" className="w-full flex justify-center p-8">
                    <button className="bg-primaryV rounded-full"><p className="text-white font-semibold p-2">Contact
                        Us</p></button>
                </Link>
            </section>
        </>
    );
}

export default MarketingAnnounced;