import React from 'react';
import Menu from '../Components/navbar/menu'
import Footer from '../Components/footer/footer'
import Solutions from "../Components/Services/solutions";
import RiskVerify from "../Components/Services/riskverify";
import Start from "../Components/about/background_start"
import Sales from "../Components/Services/sales_advertising";

const Services = () =>{
    return(
        <>
            <Menu />
            <Solutions />
            <RiskVerify />
            <Start />
            <Sales />
            <Footer />
        </>
    );
}

export default Services;