import React from 'react';
import useVisibility from "../JS/Lazylow"
import {Link} from "react-scroll";

export default function MarketingTop (){
    const { ref: refCards30, isVisible: isVisibleCards30 } = useVisibility();
    const { ref: refCards21, isVisible: isVisibleCards21 } = useVisibility();
    return(
        <>
            <nav className="mt-24 diagonal-gradient w-full lg:h-[850px] md:h-[650px] sm:h-auto py-12 sm:py-2">
                <div className="conic-gradient  h-60 hidden lg:block absolute opacity-75 rounded-br-full"></div>
                <section className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2 lg:mx-28 md:mx-8 sm:mx-8 lg:py-32 md:py-4 sm:py-4 ">
                    <nav className={`p-2 sm:p-12 text-center sm:text-start  ${isVisibleCards30 ? 'animated-right' : 'visible'}`} ref={refCards30}>
                        <h1 className="font-bold text-4xl sm:text-5xl p-2">Transform Your Business with Real Buy and Sell Solutions</h1>
                        <h2 className="text-xl sm:text-2xl p-4 font-bold text-primaryV animate-pulse">Human Enabled Sales Performance Automation, sell your unselling inventory</h2>
                        <p className="text-sm sm:text-lg p-2 mb-4">At MoneSure, we specialize in elevating your business through comprehensive
                            system evaluations and targeted marketing strategies. Discover how our two-pillar approach can
                            drive your success</p>
                        <div className="text-center sm:text-start ">
                            <Link activeClass={"active"} to={"seccion-destine"} spy={true} smooth={true} offset={-70} duration={800}>
                                <button className="bg-primaryV p-3 rounded-full hover:bg-blue-700"><p className="font-bold text-white dark:text-gray-800 px-4">Learn more about our services</p>
                                </button>
                            </Link>
                            <Link activeClass={"active"} to={"seccion-destine1"} spy={true} smooth={true} offset={-70} duration={800}>
                                <button
                                    className="bg-gray-200 dark:bg-green-300 p-3 mx-4 rounded-full hover:bg-primaryV hover:text-white font-bold">
                                    <p className="px-4">Get Started</p></button>
                            </Link>
                        </div>
                    </nav>
                    <nav className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden justify-center content-center ${isVisibleCards21 ? 'animated-left' : 'visible'}`} ref={refCards21}>
                        <img src="/top1.jpg" alt="default" className="object-cover rounded-3xl"/>
                    </nav>
                </section>
            </nav>
        </>
    );
};
