import React, {useState} from 'react';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import useVisibility from "../../../JS/Lazylow";

const ShipInsuranceForm = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard24, isVisible: isVisibleCard24 } = useVisibility();

    const [tracking, setTracking] = React.useState("");
    const [carrier, setCarrier] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const [reference, setReference] = React.useState("");
    const [buttonValue, setButtonValue] = useState('Create Insurance');
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = React.useState({
        toAddress: '',
        fromAddress: '',
        tracking_code: '',
        carrier: '',
        amount: '',
        reference: ''
    });
    const validation = (value, pattern) => {
        return pattern.test(value);
    };
    const handle = (e) =>{
        e.preventDefault();
        const { name, value } = e.target;
        let pattern;

        switch(name) {
            case "amount":
                pattern = /^\d*\.?\d{0,2}$/;
                break;
            case "tracking_code":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            case "carrier":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            case "reference":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            default:
                pattern = null;
        }
        const formatValue = value.replace(/-/g, '');
        const isValid = pattern && validation(formatValue, pattern);

        if (value === '' || isValid) {
            let formattedInput = value;
            switch(name) {
                case "amount":
                    setAmount(formattedInput)
                    break;
                case "tracking_code":
                    setTracking(formattedInput)
                    break;
                case "carrier":
                    setCarrier(formattedInput)
                    break;
                case "reference":
                    setReference(formattedInput)
                    break;
                default:

            }
        }
    };
    const handleInputChange = (e) =>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        const formatedData = {
            to_address: {id: formData.toAddress},
            from_address: {id: formData.fromAddress},
            tracking_code: formData.tracking_code,
            carrier: formData.carrier,
            amount: formData.amount,
            reference: formData.reference
        }
        console.log(formatedData)
        fetch('http://127.0.0.1:5000/api/v5/insurances', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formatedData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                console.log(data);
                    setIsLoading(false);
                    setButtonValue('Send Information');
                    const alertaS = document.getElementById('alertaS');
                    alertaS.classList.remove('hidden');
                    alertaS.classList.add('flex');
                setTimeout(() => {
                    alertaS.classList.add('hidden');
                    alertaS.classList.remove('flex');
                }, 3000);
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setIsLoading(false);
                setButtonValue('Send Information');
                const alertaE = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaE.classList.remove('hidden');
                alertaE.classList.add('flex');
                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaE.classList.add('hidden');
                    alertaE.classList.remove('flex')
                }, 3000);
            });

    }
    return(
        <>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Shipment address was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="w-fit flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p></span>
                    </div>
                </div>
            </nav>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-blue-50 rounded-xl border-t-8 border-blue-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Create a Insurance</h1>
                    <dt className="flex justify-center content-center">
                        <p className={"py-2 text-md text-gray-400 flex justify-center content-center"}>Here you can
                            create a
                            client insurance.</p>
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div className="flex justify-center items-center">
                                    <svg data-popover-target="chart-info" data-popover-placement="bottom"
                                         className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ms-1"
                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"/>
                                    </svg>
                                    <div data-popover="" id="chart-info" role="tooltip"
                                         className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                        <h1 className={"flex justify-center font-bold text-center mt-6 mb-2"}>Dictionary</h1>
                                        <div className="p-3 space-y-2">
                                            <h1 className="font-semibold text-gray-900 dark:text-white">To Address</h1>
                                            <p>The destination of the package to be insured.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">From
                                                Address</h1>
                                            <p>The origin of the package to be insured</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Tracking
                                                Code</h1>
                                            <p>The tracking code associated with the non-EasyPost-purchased package
                                                you'd like to insure.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Reference</h1>
                                            <p>Basically this is a references to insurance.</p>
                                        </div>
                                        <div data-popper-arrow=""></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dt>

                </dl>
            </div>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-16 ">
                <nav className="w-full px-2 sm:px-24">
                    <form onSubmit={handleSubmit}>
                        <nav
                            className={`w-full rounded-xl border-2 p-8 bg-white shadow-3xl ${isVisibleCard20 ? 'animated-card' : 'visible'}`}
                            ref={refCard20}>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="toAddress" onChange={handleInputChange}
                                           id="floating_toAddress"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_toAddress"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">To
                                        Address</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="fromAddress" onChange={handleInputChange}
                                           id="floating_fromAddress"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_fromAddress"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">From
                                        Address</label>
                                </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="tracking_code" value={tracking} onChange={(e) => {
                                    handleInputChange(e);
                                    handle(e);
                                }} id="floating_tracking_code"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_tracking_code"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Tracking
                                    Code</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="carrier" value={carrier} onChange={(e) => {
                                    handle(e);
                                    handleInputChange(e)
                                }} id="floating_carrier"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_carrier"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Carrier</label>
                            </div>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <span className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500">
                                        <AttachMoneyOutlinedIcon/>
                                        <i className="fas fa-dollar-sign text-gray-500"></i>
                                    </span>
                                    <input type="text" name="amount" value={amount} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }}
                                           id="floating_amount"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_amount"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount
                                        ($100.00)</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="reference" value={reference} onChange={(e) => {
                                        handle(e);
                                        handleInputChange(e)
                                    }} id="floating_reference"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_reference"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Reference</label>
                                </div>
                            </div>
                        </nav>
                        <div
                            className={`flex justify-center sm:mx-28 space-x-3 sm:space-x-26 py-8 ${isVisibleCard24 ? 'animated-card' : 'visible'}`}
                            ref={refCard24}>
                            <button type="submit" disabled={isLoading}
                                    className=" text-gray-200 bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-2/3 px-5 sm:px-24 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{isLoading ? (
                                <div role="status" className="">
                                    <svg
                                        aria-hidden="true"
                                        className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                buttonValue
                            )}
                            </button>
                        </div>
                    </form>
                </nav>
            </section>
        </>
    )
}
export default ShipInsuranceForm