import React, {useEffect, useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
import axios from "axios";

const Merchant = () => {

    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();
    const { ref: refCard22, isVisible: isVisibleCard22 } = useVisibility();
    const { ref: refCard23, isVisible: isVisibleCard23 } = useVisibility();
    const { ref: refCard24, isVisible: isVisibleCard24 } = useVisibility();

    const [company, setCName] = useState("");
    const [username, setUsername] = useState("");
    const [contact_first_name, setFName] = useState("");
    const [contact_last_name, setLName] = useState("");
    const [account_number, setAN] = useState("");
    const [routing_number, setRN] = useState("");
    const [contact_phone, setPhone] = useState("");
    const [timezones, setTimezones] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        fetch('http://localhost:5000/api/timezone_list')
            .then(response => response.json())
            .then(data => {
                setTimezones(data);
                setIsLoading(false);
            })
            .catch(error => console.error('Error fetching timezones:', error));
        axios.get('http://localhost:5000/api/locale_list')
            .then(response => {
                setLanguages(response.data);
            })
            .catch(error => console.error('Error fetching languages:', error));
        axios.get('http://localhost:5000/api/country_list')
            .then(response => {
                setCountries(response.data);
            })
            .catch(error => console.error('Error fetching languages:', error));

    }, []);

    const [buttonValue, setButtonValue] = useState('Send Information');
    const [isLoading, setIsLoading] = useState(false);
    const validation = (value, pattern) => {
        return pattern.test(value);
    };

    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;

        switch(name) {
            case "company":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "username":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "contact_last_name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "contact_first_name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "account_number":
                pattern = /^\d{1,28}$/;
                break;
            case "routing_number":
                pattern = /^\d{1,28}$/;
                break;
            // case "fee_plan":
            //     pattern = /^\d{1,28}$/;
            //     break;
            case "contact_phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;

            default:
                pattern = null;
        }

        const formattedValue = value.replace(/-/g, '');
        const isValid = pattern && validation(formattedValue, pattern);

        if (value === '' || isValid) {
            let formattedInput = value;
            if (name === "contact_phone") {
                formattedInput = formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "company":
                    setCName(formattedInput);
                    break;
                case "username":
                    setUsername(formattedInput);
                    break;
                case "contact_last_name":
                    setLName(formattedInput);
                    break;
                case "contact_first_name":
                    setFName(formattedInput);
                    break;
                case "account_number":
                    setAN(formattedInput);
                    break;
                case "routing_number":
                    setRN(formattedInput);
                    break;
                case "contact_phone":
                    setPhone(formattedInput);
                    break;
                default:
            }
        }
    };

    const [formData, setFormData] = useState({
        company: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postal: '',
        country: '',
        url: '',
        timezone_id: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_phone: '',
        contact_email: '',
        account_number: '',
        routing_number: '',
        username: '',
        locale:'',
        fee_plan:17158,
        features: {
            capture_higher_than_authed: false,
            tsys: {
                allow_payment_facilitator_fields: false
            }
        },
        user_profile_id: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formatedData = {
            ...formData,
            user_profile_id: storedUser.id
        };
        console.log(formatedData)
        fetch('http://127.0.0.1:5000/api/v1/getway_account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formatedData)
        })
            .then(response =>{
                if (response.ok){
                    return response.json();
                }else {
                    return response.json().then(error =>{
                        throw new Error(error.message || 'Server respond with an error')
                    })
                }
            })
            .then(data => {
                setIsLoading(false)
                setButtonValue('Send Information');
                const alertaSuccess = document.getElementById('alertaS');
                alertaSuccess.classList.remove('hidden');
                alertaSuccess.classList.add('flex');
                setTimeout(() => {
                    alertaSuccess.classList.add('hidden');
                    alertaSuccess.classList.remove('flex');
                }, 3000);
            })
            .catch((error) => {
                setIsLoading(false)
                setButtonValue('Send Information');
                const alertaError = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaError.classList.remove('hidden');
                alertaError.classList.add('flex');

                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaError.classList.add('hidden');
                    alertaError.classList.remove('flex');
                }, 3000);
            });
    };
    return (
        <>
            {/* SECTIONS AS GUIDES   */}
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-red-50 rounded-xl border-t-8 border-red-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Create a Merchant Account</h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can create a new merchant account</p>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Information sent successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="w-fit flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p></span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center px-4 py-12">
                <nav className="w-full">
                    <form onSubmit={handleSubmit}>
                        <nav
                            className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                            ref={refCard20}>
                            <dt><h1 className="text-3xl font-bold mb-4 p-4">Generic Information</h1></dt>
                            <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                                <div className="relative z-0 w-full mb-5 group ">
                                    <input type="text" name="company" value={company} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e);
                                    }} id="floating_company"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_company"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company
                                        name</label>
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="address_1" onChange={(e) => {
                                            handleInputChange(e);
                                        }} id="floating_address1"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_address1"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Street
                                            Address</label>
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="address_2" onChange={(e) => {
                                            handleInputChange(e);
                                        }} id="floating_address2"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_address2"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Complete
                                            Address</label>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-3 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="city" onChange={(e) => {
                                            handleInputChange(e);
                                        }} id="floating_city"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_city"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">City</label>
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="state" onChange={(e) => {
                                            handleInputChange(e);
                                        }} id="floating_state"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_state"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">State</label>
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="postal" onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                               id="floating_postal"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_postal"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Postal</label>
                                    </div>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <select
                                        id="underline_select_country"
                                        name="country"
                                        value={formData.country}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                    >
                                        <option value="" disabled>Country</option>
                                        {Object.entries(countries).map(([code, name]) => (
                                            <option key={code} value={code}>{name}</option>
                                        ))}

                                    </select>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="url" onChange={(e) => {
                                        handleInputChange(e);
                                    }} id="floating_url"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_url"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Url
                                        (Merchant's website)</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <select
                                        id="underline_select_timezone"
                                        name="timezone_id"
                                        value={formData.timezone_id}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                    >
                                        <option value="" disabled>Time Zone</option>
                                        {timezones.map((timezone, index) => (
                                            <option key={index} value={index}>{timezone}</option>
                                        ))}

                                    </select>

                                </div>
                            </div>
                        </nav>

                        <nav
                            className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard21 ? 'animated-left' : 'visible'}`}
                            ref={refCard21}>
                            <dt className={""}><h1 className="text-3xl font-bold mb-4 p-4">Company contact</h1></dt>
                            <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                                <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="contact_first_name" value={contact_first_name}
                                               onChange={(e) => {
                                                   handleInputChange(e);
                                                   handle(e);
                                               }} id="floating_fname"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_fname"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Firstname</label>
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <input type="text" name="contact_last_name" value={contact_last_name}
                                               onChange={(e) => {
                                                   handleInputChange(e);
                                                   handle(e);
                                               }} id="floating_Lname"
                                               className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                               placeholder=" " required/>
                                        <label htmlFor="floating_Lname"
                                               className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Lastname</label>
                                    </div>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="contact_phone" value={contact_phone} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e);
                                    }} id="floating_phone"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_phone"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contact
                                        Phone
                                        number (+1) 000-000-0000</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="email" name="contact_email" onChange={(e) => {
                                        handleInputChange(e);
                                    }} id="floating_email"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_email"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contact
                                        Email</label>
                                </div>
                            </div>
                        </nav>

                        <nav
                            className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard22 ? 'animated-right' : 'visible'}`}
                            ref={refCard22}>
                            <dt className={""}><h1 className="text-3xl font-bold mb-4 p-4">Banking Information</h1></dt>
                            <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="account_number" value={account_number}
                                           onChange={(e) => {
                                               handleInputChange(e);
                                               handle(e);
                                           }} id="floating_account_number"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_account_number"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Account
                                        number</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="routing_number" value={routing_number}
                                           onChange={(e) => {
                                               handleInputChange(e);
                                               handle(e);
                                           }} id="floating_routing_number"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_routing_routing_number"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Routing
                                        number</label>
                                </div>
                            </div>
                        </nav>

                        <nav
                            className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard23 ? 'animated-left' : 'visible'}`}
                            ref={refCard23}>
                            <dt className={""}><h1 className="text-3xl font-bold mb-4 p-4">Username</h1></dt>
                            <div className={"w-full bg-white shadow-3xl rounded-xl border-2 p-8 col-span-2"}>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="username" value={username} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e);
                                    }} id="floating_username"
                                           className="block py-2.5 px-0 w-full text-base text-gray-500 font-semibold bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_username"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <select id="underline_select_locale" name="locale" value={formData.locale}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                        <option value="" disabled>Locale</option>
                                        {Object.entries(languages).map(([code, name]) => (
                                            <option key={code} value={code}>{name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </nav>

                        <div
                            className={`flex justify-center sm:mx-28 space-x-3 sm:space-x-26 py-8 ${isVisibleCard24 ? 'animated-card' : 'visible'}`}
                            ref={refCard24}>
                            <button type="submit" disabled={isLoading}
                                    className=" text-gray-200 bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-2/3 px-5 sm:px-24 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{isLoading ? (
                                <div role="status" className="">
                                    <svg
                                        aria-hidden="true"
                                        className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                buttonValue
                            )}
                            </button>
                        </div>
                    </form>

                </nav>
            </section>
        </>
    )
}

export default Merchant;