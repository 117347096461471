import React, {useState} from 'react';
import DocsModal  from "./DocsModal";

const Documents = ({documentsMethod}) =>{
    const [selectedDocs, setSelectedDocs] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpenDetails = (docs) => {
        setSelectedDocs(docs)
        setOpen(true);
    }
    const handleCloseDetails = () => setOpen(false);
    const [openD, setOpenD] = useState(false);
    const handleOpenDelete = (docs) => {
        setSelectedDocs(docs)
        setOpenD(true);
    }
    const handleCloseDelete = () => setOpenD(false);
    return (
        <>
            <DocsModal
                handleOpenDetails={handleOpenDetails}
                handleOpenDelete={handleOpenDelete}
                handleCloseDetails={handleCloseDetails}
                handleCloseDelete={handleCloseDelete}
                selectedDoc={selectedDocs}
                open={open}
                openD={openD}
            />
            <div className="w-full flex flex-col justify-center items-center mb-12">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Documents about Bank Account</h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can manage the Bank Docs</p>
                </dl>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            WhiteLabel ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Issue ts.
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Address
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {documentsMethod.map(doc => (
                        <tr key={doc.wl_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {doc.wl_id}
                            </th>
                            <td className="px-6 py-4">
                                {doc.issue_ts}
                            </td>
                            <td className="px-6 py-4">
                                {doc.address}
                            </td>
                            <td className="px-6 py-4">
                                {doc.type_id}
                            </td>
                            <td className="px-6 py-4 space-x-2">
                                <button value={doc.wl_id} onClick={() => handleOpenDetails(doc)}
                                        className="border-2 border-blue-600 p-2 rounded-xl text-blue-600 hover:text-white hover:bg-blue-500">Details
                                </button>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Documents;