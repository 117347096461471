import React from 'react';
// import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const Contact = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();
    
    const [User_name, setUserName] = React.useState('');
    const [Phone_number, setPhoneNumber] = React.useState('');
    const handle = (event) => {
        const {name , value} = event.target;
        let pattern;
        switch (name) {
            case "username":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            default:
                pattern = null;
        }

        const isValid = pattern ? pattern.test(value): true

        if (value === '' || isValid) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "username":
                    setUserName(formattedInput)
                    break;
                case "phone":
                    setPhoneNumber(formattedInput);
                    break;
                default:
            }
        }

    }
    const [Buttonvalue, setButtonValue] = React.useState('send Email..');
    const [FormData, setFormData] = React.useState({
        email: '',
        phone: '',
        username: '',
    });

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData({
            ...FormData,
            [name]: value
        })
    };

    const handleSubmit = (event) =>{
        event.preventDefault();
        setButtonValue("Sending....");
        fetch('http://127.0.0.1:5000/api/v4/send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(FormData)
        })
            .then(response => {
                if (response.ok){
                    setButtonValue("Send Email.");
                    alert('Email sent successfully.!');
                }else{
                    throw new Error('Failed to send Email.');
                }
            })
            .catch((err) =>{
                setButtonValue('Send Email.');
                alert(err.message);
            })
    }
    return(
        <>
            <section
                className="bg-gradient-to-tl from-fcontact from-90% to-card2 to-10% w-full h-fit content-center py-24 sm:py-14">
                <div
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  justify-items-center content-center mt-2 sm:mt-24 mx-2 sm:mx-28">
                    <h1 className={"text-3xl font-bold py-12"}>Contact Details</h1>
                    <p className="text-md p-8"> MoneSure offers a secure, cloud-based check printing software designed for
                        the next generation of businesses. With our platform, you can effortlessly print checks and send
                        them via electronic or physical mail, all from a user-friendly interface. Say goodbye to
                        cumbersome check printing processes—our software allows you to print checks on any paper using
                        any printer right from your office desk. Get in touch with us today for a free and seamless
                        check printing experience.</p>
                </div>
                <div
                    className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-8  justify-items-center content-center mt-2 sm:mt-24 mx-2 sm:mx-28">
                    <nav className="bg-gradL text-center w-3/4 rounded-xl">
                        <div className="flex justify-center py-4">
                            <svg className="w-16 h-16 text-card" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
                            </svg>
                        </div>
                        <h1 className="text-center text-oscuro font-bold text-xl p-2">Phone</h1>
                        <p className="text-lg mb-4">000 646-4103</p>
                    </nav>
                    <nav className="bg-gradL text-center w-3/4 rounded-xl">
                        <div className="flex justify-center py-4">
                            <svg className="w-16 h-16 text-card" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z"/>
                                <path
                                    d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z"/>
                            </svg>
                        </div>
                        <h1 className="text-center text-oscuro font-bold text-xl p-2">Email</h1>
                        <p className="text-lg mb-4">info@MoneSure.com</p>
                    </nav>
                    <nav className="bg-gradL text-center w-3/4 rounded-xl">
                        <div className="flex justify-center py-4">
                            <svg className="w-16 h-16 text-card" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 viewBox="0 0 24 24">
                                <path fillRule="evenodd"
                                      d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                                      clipRule="evenodd"/>
                            </svg>

                        </div>
                        <h1 className="text-center text-oscuro font-bold text-xl p-2">Address</h1>
                        <p className="text-lg mb-4">1309 Coffeen Sheridan Wy. 82801</p>
                    </nav>
                </div>

                <nav
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  justify-items-center mt-2 sm:mt-24 mx-2 sm:mx-28">

                    <div className={`bg-gradient-to-t from-oscuro to-card2 p-8 mt-8 w-auto sm:w-4/5 h-fit rounded-3xl ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                        <form className="mt-12" onSubmit={handleSubmit}>
                            <h1 className="text-4xl text-white font-bold p-4">Contact Us</h1>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="email" name="email" id="floating_email"
                                       onChange={(e)=> {handleInputChange(e)}} pattern="[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-]([\.]?[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?" className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-800 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_email"
                                       className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email
                                    address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="username" value={User_name} onChange={(e)=>{handleInputChange(e); handle(e);}} id="floating_first_name"
                                       className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_first_name"
                                       className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                            </div>

                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="phone" value={Phone_number} onChange={(e)=>{handleInputChange(e); handle(e)}}
                                       id="floating_phone"
                                       className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_phone"
                                       className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                                    number (123-456-7890)</label>
                            </div>


                            <button type="submit"
                                className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{Buttonvalue}
                            </button>
                        </form>
                    </div>
                    <div className={` hidden lg:block  justify-center items-end ${isVisibleCard21 ? 'animated-down' : 'visible'}`} ref={refCard21}>
                        <img src="/manifest.jpg" alt=""
                             className=" h-4/5 rounded-tl-2xl rounded-br-3xl shadow"/>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default Contact;