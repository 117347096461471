import React, {useEffect, useRef, useState} from 'react';
import Sidebar from '../../Components/Dashboard/GenericInAll/sidebar';
import Navigation from '../../Components/Dashboard/GenericInAll/navigation';
import BankTable from '../../Components/Dashboard/checksTables/bankAccounts';

const ManageBanks = () => {
    const storedUserRef = useRef(JSON.parse(localStorage.getItem('user')));
    const [bankAccounts, setBankAccounts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const datas = {
                user_profile_id: storedUserRef.current.profile.user_profile_id
            };

            try {
                const response = await fetch('http://127.0.0.1:5000/api/v3/get_bankaccounts_BD', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(datas)
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                console.log(data);
                setBankAccounts(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (storedUserRef.current && storedUserRef.current.profile.user_profile_id) {
            fetchData();
        }

    }, []);
    return(
        <>
                <Sidebar/> <Navigation/>
                <section className="sm:flex">
                    <div className="flex-1">
                        <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                            {bankAccounts.length > 0 ? (
                                <BankTable bankAccounts={bankAccounts} />
                            ) : (
                                <p>No hay cuentas bancarias disponibles.</p>
                            )}
                        </div>
                    </div>
                </section>
        </>
    )
}
export default ManageBanks;