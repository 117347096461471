// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('isLoggedIn') === 'true'
    );
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(
        localStorage.getItem('isAdminLoggedIn') === 'true'
    );
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(
        localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).is_admin : false
    );
    const [shippingAccess, setShippingAccess] = useState(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            setUser(userData);
            setIsAdmin(userData.is_admin);

            const functions = JSON.parse(localStorage.getItem('functions'));
            if (functions && functions.shipping !== undefined) {
                return functions.shipping;
            }
        }
        return false; // Valor por defecto si no se encuentran datos en localStorage
    });

    const [merchantAccess, setMerchantAccess] = useState(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const functions = JSON.parse(localStorage.getItem('functions'));
            if (functions && functions.merchant !== undefined) {
                return functions.merchant;
            }
        }
        return false; // Valor por defecto si no se encuentran datos en localStorage
    });

    const [checkingAccess, setCheckingAccess] = useState(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const functions = JSON.parse(localStorage.getItem('functions'));
            if (functions && functions.checking !== undefined) {
                return functions.checking;
            }
        }
        return false; // Valor por defecto si no se encuentran datos en localStorage
    });

    const [totalAccess, setTotalAccess] = useState(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const functions = JSON.parse(localStorage.getItem('functions'));
            if (functions && functions.total !== undefined) {
                return functions.total;
            }
        }
        return false; // Valor por defecto si no se encuentran datos en localStorage
    });

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            setUser(userData);
            setIsAdmin(userData.is_admin);

            const functions = JSON.parse(localStorage.getItem('functions'));
            if (functions) {
                setShippingAccess(functions.shipping || false);
                setMerchantAccess(functions.merchant || false);
                setCheckingAccess(functions.checking || false);
                setTotalAccess(functions.total || false);
            }
        }
    }, []);

    const login = (token, userData) => {
        localStorage.setItem('access_token', token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('functions', JSON.stringify(userData.relations.functions));
        setIsLoggedIn(true);
        setUser(userData);
        setIsAdmin(userData.is_admin);

        // Establecer el acceso a las funciones después del inicio de sesión
        setShippingAccess(userData.relations.functions.shipping || false);
        setMerchantAccess(userData.relations.functions.merchant || false);
        setCheckingAccess(userData.relations.functions.checking || false);
        setTotalAccess(userData.relations.functions.total || false);
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.setItem('isAdminLoggedIn', 'false');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('functions');
        setIsLoggedIn(false);
        setUser(null);
        setIsAdmin(false);

        // Limpiar acceso a las funciones al cerrar sesión
        setShippingAccess(false);
        setMerchantAccess(false);
        setCheckingAccess(false);
        setTotalAccess(false);
    };

    const admin_login = (token, userData) => {
        localStorage.setItem('access_token', token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('isAdminLoggedIn', 'true');
        setIsAdminLoggedIn(true);
        setUser(userData);
    };
    const admin_logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.setItem('isAdminLoggedIn', 'false');
        setIsAdminLoggedIn(false);
        setUser(null);
    };



    return (
        <AuthContext.Provider value={{ isLoggedIn, isAdminLoggedIn, admin_login, admin_logout, login, logout, user, isAdmin,
            shippingAccess,
            merchantAccess,
            checkingAccess,
            totalAccess }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
