import React from 'react';
import useVisibility from "../../../JS/Lazylow";

const TrackForms =() =>{
    const { ref: refCards20, isVisible: isVisibleCard20 } = useVisibility();
    const [form, setForm] = React.useState({
        id: '',
    });
    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    }
    const Submits = (e) =>{
        e.preventDefault();
        console.log(form);
        fetch('http://127.0.0.1:5000/api/v3/track_payments', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || "Custom Info service is not available ");
                    });
                }
            })
            .then(data => {
                console.log(data)
                const alertaSuc = document.getElementById('alertaS');
                alertaSuc.classList.remove('hidden');
                alertaSuc.classList.add('flex');
                setTimeout(() => {
                    alertaSuc.classList.add('hidden');
                    alertaSuc.classList.remove('flex');
                }, 3000);
            })
            .catch(error => {
                console.error('An error occurred:', error);
                const alertaErrors = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaErrors.classList.remove('hidden');
                alertaErrors.classList.add('flex');
                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaErrors.classList.add('hidden');
                    alertaErrors.classList.remove('flex');
                }, 3000);
            });
    }
    return(
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Track Payment</h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can Track a payment</p>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Tracking was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p> </span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24" onSubmit={Submits}>
                    <nav
                        className={`w-full  rounded-xl border-2 p-8 bg-white shadow-3xl ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCards20}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="text" name="id" id="floating_payment_id" onChange={(e) => {
                                handleInputChange(e)
                            }}
                                   className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_payment_id"
                                   className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Payment
                                ID</label>
                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-8 group">
                        <button type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base w-full sm:w-2/3 px-16 py-2 text-center">Send
                            ID Payment
                        </button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default TrackForms;