import React from "react";
import useVisibility from "../JS/Lazylow";
const SlideMarket=()=>{
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();


    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return (
        <>
            <section className="bg-gradient-to-br from-gradR from-5% to-white to-100% w-full lg:h-[650px] md:h-[650px] sm:h-fit content-center py-12 sm:py-1">
                <nav className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center h-fit mx-2 lg:mx-28 md:mx-8 sm:mx-8">
                    <div className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden flex justify-center content-center ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                        <img src="/top2.jpg" alt="default" className="object-cover rounded-3xl h-96"/>
                    </div>
                    <div className={`lg:p-24 md:p-14 sm:p-2  text-center sm:text-start  ${isVisibleCard21 ? 'animated-left' : 'visible'}`} ref={refCard21}>
                        <h1 className="font-bold text-3xl sm:text-5xl pr-1 sm:pr-10"><span
                            className="gradient-text">Mone</span><span className="gradient-text-green">Sure</span>-Clean House</h1>
                        <p className="text-sm sm:text-lg py-8">Our 'Clean House' pillar focuses on thoroughly evaluating and optimizing your
                            business systems. Through detailed interviews and comprehensive reports, we identify and
                            address any issues to ensure your business operates smoothly.</p>
                        <a href={"#seccion-destine1"}>
                            <button className="bg-primaryV rounded-full p-2"><p
                                className="font-semibold text-white mx-4">Get Started</p></button>
                        </a>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default SlideMarket;