import React from 'react';
import {Link} from "react-router-dom";

const Pricing = () => {
    return(
        <>
            <section className="bg-gradient-to-tl from-gradR from-70% to-gradL to-100% h-fit content-center p-8 grid justify-center">
                <h1 className="font-bold text-3xl sm:text-5xl text-center"><span className="text-oscuro">Our</span> Pricing</h1>
                <p className="text-center p-2 text-xl">Your convenience is our utmost priority, pick up the plan that works best for your business. Don’t worry; you can update your plan anytime.</p>
                <nav
                    className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2  gap-4 w-full py-12">
                    <div
                        className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700  hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                        <h5 className="mb-4 text-4xl font-bold text-oscuro">Personal</h5>
                        <div className=" text-gray-900 dark:text-white">
                            <span className="text-3xl font-semibold">$</span>
                            <span className="text-5xl font-extrabold tracking-tight">Free</span>
                            <span className="block ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">No credit card required</span>
                        </div>
                        <ul  className="space-y-5 my-7">
                            <li className="flex items-center">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">5 transactions per month</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Up to $500.00 per transaction</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Access to Add Center</span>
                            </li>
                            <li className="flex ">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">Access to Loan Center</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3"> Basic shipping and insurance</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4  text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span className="text-base font-normal leading-tight text-gray-500 ms-3">On demand pickup / delivery concierge</span>
                            </li>

                        </ul>
                        <Link to="" className="flex justify-center">
                            <button type="button"
                                    className="text-white bg-gradient-to-l from-oscuro to-card2 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-full text-sm px-3 py-3 flex justify-center text-center ">
                                <p className="mx-8">Choose plan</p>
                            </button>
                        </Link>
                    </div>
                    <div
                        className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700  hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                        <h5 className="mb-4 text-4xl font-bold text-oscuro">Basic</h5>
                        <div className=" text-gray-900 dark:text-white">
                            <span className="text-3xl font-semibold">$</span>
                            <span className="text-5xl font-extrabold tracking-tight">49</span>
                            <span
                                className=" ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/ Per Month</span>
                        </div>
                        <ul  className="space-y-5 my-7">
                            <li className="flex items-center">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> Benefits from Personal Plan</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> 2 merchant accounts low cost and chargeback guarantee processing</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> Invoice guarantee program</span>
                            </li>
                            <li className="flex ">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3"> Free non-guaranteed echeck and digital check processing</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3"> Access to certified high value ECOMM shipping (safest ECOMM shipping program)</span>
                            </li>
                        </ul>
                        <Link to="" className="flex justify-center">
                            <button type="button"
                                    className="text-white bg-gradient-to-l from-oscuro to-card2 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-full text-sm px-3 py-3 flex justify-center text-center ">
                                <p className="mx-8">Choose plan</p>
                            </button>
                        </Link>
                    </div>
                    <div
                        className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700  hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                        <h5 className="mb-4 text-4xl font-bold text-oscuro">Pro</h5>
                        <div className=" text-gray-900 dark:text-white">
                            <span className="text-3xl font-semibold">$</span>
                            <span className="text-5xl font-extrabold tracking-tight">99</span>
                            <span
                                className=" ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/ Per Month</span>
                        </div>
                        <ul  className="space-y-5 my-7">
                            <li className="flex items-center">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> Benefits from Personal Plan</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> All risk guarantees</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">  Buy and sell financing at preferred rates</span>
                            </li>
                            <li className="flex ">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">  Multi shipper access and reduced insurance rates from multi carriers</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">  Client credit monitoring</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">   Transaction Risk monitor</span>
                            </li>
                        </ul>
                        <Link to="" className="flex justify-center">
                            <button type="button"
                                    className="text-white bg-gradient-to-l from-oscuro to-card2 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-full text-sm px-3 py-3 flex justify-center text-center ">
                                <p className="mx-8">Choose plan</p>
                            </button>
                        </Link>
                    </div>
                    <div
                        className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700  hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                        <h5 className="mb-4 text-4xl font-bold text-oscuro">Enterprise</h5>
                        <div className=" text-gray-900 dark:text-white">
                            <span className="text-3xl font-semibold">$</span>
                            <span className="text-5xl font-extrabold tracking-tight">199</span>
                            <span
                                className=" ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/ Per Month</span>
                        </div>
                        <ul  className="space-y-5 my-7">
                            <li className="flex items-center">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> Bank AccountNo Preset Limit UserNo</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"> Preset Limit ACH/Direct Deposit</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">  Domestic & International Wire Cloud</span>
                            </li>
                            <li className="flex ">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">   Bank & Fund Transfers Custom</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">   Physical & Virtual Cards Check Bill & Invoice Receive Payment</span>
                            </li>
                            <li className="flex">
                                <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span
                                    className="text-base font-normal leading-tight text-gray-500 ms-3">    Payment Link Deposit Slip Third Party Integration Access to Addons API Txn Fee</span>
                            </li>
                        </ul>
                        <Link to="" className="flex justify-center">
                            <button type="button"
                                    className="text-white bg-gradient-to-l from-oscuro to-card2 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-full text-sm px-3 py-3 flex justify-center text-center ">
                                <p className="mx-8">Choose plan</p>
                            </button>
                        </Link>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default Pricing;