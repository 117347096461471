import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from "../../../context/AuthContext";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const SideBar = document.getElementById('default-sidebar');
        SideBar.setAttribute('aria-expanded', isOpen.toString());
    }, [isOpen]);
    const {admin_logout} = useAuth();
    const handleLogout = () =>{
        admin_logout()
    }
    return(
        <>
            <section className="sm:flex">`
                <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" onClick={toggleSidebar}
                        aria-controls="default-sidebar" type="button"
                        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd"
                              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <aside id="default-sidebar"
                       className={`fixed top-0 left-0 z-40 w-72 h-screen transition-transform ${
                           isOpen ? '' : '-translate-x-full sm:translate-x-0'
                       }`}
                       aria-label="Sidebar">
                    <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 ">
                        <Link to="/profile" className="flex items-center space-x-2 rtl:space-x-reverse">
                            <img src="/MoneSureLogoG.png" className="w-44" alt=" Logo"/>
                        </Link>
                        <ul className="space-y-4 font-medium p-4">
                            <li>
                                <List
                                    sx={{width: '100%', maxWidth: 360}}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <Link to="/administration/dashboard"><ListItemButton>
                                        <ListItemIcon>
                                            <GroupAddOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Clients"/>
                                    </ListItemButton></Link>
                                    <Link to="/administration/clients_users"><ListItemButton>
                                        <ListItemIcon>
                                            <Diversity3OutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Users per Client"/>
                                    </ListItemButton></Link>
                                    <Link to="/administration/manage/functions"><ListItemButton>
                                        <ListItemIcon>
                                            <LocalActivityRoundedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Functions"/>
                                    </ListItemButton></Link>

                                </List>
                            </li>
                        </ul>
                        <div className="flex justify-center fixed bottom-0  left-0 z-40 w-72 rounded-tr-3xls bg-red-500 cursor-pointer" onClick={handleLogout}>
                            <button className="flex font-bold text-sm text-white  p-2">
                                <svg className="w-4 h-5 font-bold text-white dark:text-white mr-4" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3" d="M6 18 17.94 6M18 18 6.06 6"/>
                                </svg>
                                Sign Out
                            </button>
                        </div>
                    </div>
                </aside>
            </section>

        </>
    )
}

export default SideBar;