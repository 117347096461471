import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useVisibility from "../../JS/Lazylow";

const CarrouseltestimonailMerchant = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true, // Habilitar el arrastre
        swipeToSlide: true, // Permitir cambiar de diapositiva al deslizar
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: true,
    };
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    return(
        <>
            <section className="content-center w-3/4 ">
            <div className="grid justify-items-center">
                <h1 className="font-bold text-5xl p-8 mb-8"><span className="text-card">Our</span> Testimonials</h1>
                </div>
            <nav className={`w-fit sm:w-full cursor-grab ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                <Slider {...settings}>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                    <div className="border-2 border-gray-500 p-4 ">
                        <p className="text-sm mb-4 text-center">MoneSure secure transaction process is top-notch and I
                            never have to worry about my financial information being compromised. Highly
                            recommended!</p>
                        <div className="grid justify-items-center py-4">
                            <img className="w-10 h-10 rounded-full" src="/testimonial-avatar.png" alt="Rounded avatar"/>
                            <h1 className="text-card font-bold">Willy Sam</h1>
                            <p className="text-xs text-blue-500 py-2">Co-Founder of a growing Small Business</p>
                        </div>
                    </div>
                </Slider>
            </nav>
            </section>
        </>
    );
}

export default CarrouseltestimonailMerchant;