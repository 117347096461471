import React, {useEffect, useState} from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import {Link, useLocation} from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import {useAuth} from "../../../context/AuthContext";



const Navigation = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const { user } = useAuth();
    useEffect(() => {
        switch (location.pathname) {
            case '/administration/dashboard':
                setValue(0);
                break;
            case '/administration/clients_users':
                setValue(1);
                break;
            case '/administration/manage/functions':
                setValue(2);
                break;
            default:

        }
    }, [location]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {admin_logout} = useAuth();
    const handleLogout = () =>{
        admin_logout()
    }
    return(
        <>
            <div className={"lg:pl-80 lg:pr-10 md:pl-72 py-2"}>
        <nav className="w-full justify-center hidden lg:flex text-center mb-12">
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                style={{ backgroundColor: '#F9FAFB' , borderRadius: '18px', height: '70px'}}
            >
                <BottomNavigationAction label="Manage Clients" icon={<HomeIcon />} component={Link} to="/administration/dashboard" />
                <BottomNavigationAction label="Users By Clients" icon={<DashboardIcon />} component={Link} to="/administration/clients_users"/>
                <BottomNavigationAction label="Functions on Parents" icon={ <LocalActivityRoundedIcon/>} component={Link} to="/administration/manage/functions"/>
s            </BottomNavigation>
            {user ? (
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 38, height: 38, bgcolor: '#0E49B5' }}>M</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >

                    <MenuItem onClick={handleClose}>
                        <HowToRegIcon>
                            <Settings fontSize="small"/>
                        </HowToRegIcon>
                        <h1 className="font-semibold text-base">{user.username}</h1>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <p className="text-xs text-center text-gray-300">{user.email}</p>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small"/>
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </React.Fragment>
            ) : (
                <p>No user logged in</p>
            )}
        </nav>
            </div>
        </>
    )
}

export default Navigation;