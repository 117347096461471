import React, {useEffect, useRef, useState} from 'react';
import SideBar from '../../Components/Dashboard/GenericInAll/sidebar'
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import Payments from "../../Components/Dashboard/checksTables/paymets";

const PaymentsPage = () =>{
    const storedUserRef = useRef(JSON.parse(localStorage.getItem('user')));
    const [paymentsMethod, setPaymentsMethod] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const datas = {
                user_profile_id: storedUserRef.current.id
            };

            try {
                const response = await fetch('http://127.0.0.1:5000/api/v3/get_payments_bd', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(datas)
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                console.log(data);
                setPaymentsMethod(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (storedUserRef.current && storedUserRef.current.id) {
            fetchData();
        }

    }, []);
    return(
        <>
            <SideBar/><Navigation/>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <Payments payments={paymentsMethod}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymentsPage;