import React from 'react';
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const Business_services = () =>{
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();


    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return (
        <>
            <section className="bg-gradient-to-br from-gradR from-5% to-white to-100% w-full lg:h-[650px] md:h-[650px]  py-12 sm:py-1">
                <nav className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center h-fit  lg:mx-32 md:mx-8 sm:mx-8 ">
                    <div className={`hidden lg:block  md:hidden sm:hidden ${isVisibleCard20 ? 'animated-up' : 'visible'}`} ref={refCard20}>
                        <img src="/image-33.png" alt="default" className="h-[555px] mt-0"/>
                    </div>
                    <div className={`p-2 sm:p-24 text-center sm:text-start ${isVisibleCard21 ? 'animated-down' : 'visible'}`} ref={refCard21}>
                        <h1 className="font-bold text-3xl sm:text-5xl pr-1 sm:pr-10"><span
                            className="gradient-text">Mone</span><span
                            className="gradient-text-green">Sure</span> Business Services</h1>
                        <p className="text-sm sm:text-lg py-4">MoneSure emerges as a premier provider of financial technology (Fintech) solutions, catering specifically to medium to high-value merchants with expertise in sales, shipping, and risk management.</p>
                        <p className="text-sm sm:text-lg pb-4">Built upon three decades of experience serving the luxury goods market, MoneSure has honed its capabilities in payment processing, risk management, logistics, and marketing. Our journey to excellence is marked by the integration of proprietary, state-of-the-art technologies, fostering seamless collaboration with numerous marketplace, payment, and shipping platforms</p>
                        <p className="text-sm sm:text-lg  pb-8">Join us at MoneSure as we continue to innovate and empower merchants to thrive in an ever-evolving marketplace.</p>
                        <Link to="/contact"><button className="bg-primaryV rounded-full p-2"><p className="font-semibold text-white mx-8 text-sm">CONTACT US</p></button></Link>
                    </div>
                </nav>
            </section>
        </>
    );
}
export default Business_services;