import React from 'react';
import SideBar from '../../Components/Dashboard/GenericInAll/sidebar'
import Navigation from '../../Components/Dashboard/GenericInAll/navigation'
import AddPaymentForm from '../../Components/Register/forms/addPayments_Forms'

const AddPayment = () => {
    return(
        <>
            <SideBar /><Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                        <AddPaymentForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddPayment;