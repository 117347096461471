import React from 'react';
import Menu from '../Components/navbar/menu'
import Details from '../Components/Contact/details'
import Footer from '../Components/footer/footer'

const Contact = () => {
    return(
        <>
            <Menu />
            <Details />
            <Footer />
        </>
    );
}
export default Contact;