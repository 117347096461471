import React from 'react';
import ShipmentOptions from '../../Register/forms/shipmentsOptions'

const ShipmentOPT = () => {
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <ShipmentOptions/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShipmentOPT;