import React, { useState} from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import HomeIcon from "@mui/icons-material/Home";


function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const sendPassword = async () => {
        if (password !== confirmPassword) {
            throw new Error("Password must match");
        }

        const response = await axios.post('http://127.0.0.1:5000/api/v6/recovery', {
            token,
            password,
        });
        setTimeout(() => {
            navigate('/login');
        }, 2000);
        return response.data;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        toast.promise(
            sendPassword(),
            {
                loading: 'Sending request...',
                success: (data) => {
                    return <b>{data.message}</b>;


                },
                error: (error) => <b>{error.message}</b>,
            }
        );

        setPassword('');
        setConfirmPassword('');
    };
    return (
        <>
            <section className={"fullscreen-bg-reset h-screen bg-gray-900 flex items-center justify-center"}>
                <div className="fixed top-12 right-8 flex justify-center">
                    <Link to={"/"} className="flex justify-center content-center"><HomeIcon
                        sx={{width: 31, height: 31,}}
                    /></Link>
                </div>
                <div className="fixed left-12 top-12 flex justify-center items-center">
                    <Link to="/"> <img src="/MoneSureLogoG.png" alt="" className="w-fit h-12"/></Link>
                </div>
                <div
                    className="p-4 flex-col h-fit justify-center content-center rounded-tr-3xl rounded-br-3xl rounded-bl-3xl border border-gray-200 shadow-lg">
                    <h2 className={"font-bold text-gray-500 text-4xl w-full text-center mb-4"}>Reset Password</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div className="mb-6">
                                <label htmlFor="password"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input type="password" id="password" value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       placeholder="•••••••••" required/>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="confirm_password"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm
                                    password</label>
                                <input type="password" id="confirm_password" value={confirmPassword}
                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       placeholder="•••••••••" required/>
                            </div>
                        </div>
                        <div className={"w-full flex justify-center space-x-8"}>
                            <button type="submit"
                                    className="flex items-center bg-blue-500 text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-2 hover:translate-x-3"
                            >
                                Send
                                <svg
                                    className="w-5 h-5"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                    ></path>
                                </svg>
                            </button>

                            <Link to="/login">
                                <button
                                    className="flex items-center bg-blue-500 text-white gap-1 px-4 py-2 cursor-pointer font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-2 hover:translate-x-3"
                                > Cancel
                                </button>
                            </Link>
                        </div>
                    </form>
                    <Toaster/>
                </div>
            </section>
        </>

    );
}

export default ResetPassword;