import React, {useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import dayjs from "dayjs";
// import {Link} from "react-scroll";

const AddDocumentsForms = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const [wl_id, setwl_id] = useState("");
    const [issue_ts, setissue_ts] = useState("");
    const [recipient, setFirst_recipient] = useState("");
    const [address, setAddress] = useState("");
    const [return_address, setReturnAddress] = useState("");
    const [file, setFile] = useState("");
    const [ref, setRef] = useState("");
    const [cover, setCover] = useState("");
    const [color, setColor] = useState("");
    const [duplex, setDuplex] = useState("");
    const [template_id, setTemplateId] = useState("");
    const [payment_address2, setAddress2] = useState("");
    const [city, setCity] = useState("")
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [selectedDate] = useState(null);
    const [type_id, setTypeID] = useState("");
    const [formData, setFormData] = useState({
        wl_id: '',
        issue_ts: '',
        recipient: '',
        address: '',
        return_address: '',
        file: '',
        ref: '',
        cover: '',
        color: '',
        duplex: '',
        template_id:'',
        address2:'',
        city: '',
        state: '',
        zip: '',
        date: '',
        type_id: '',
    });
    const validation = (value, pattern) => {
        return pattern.test(value);
    };

    const handle = (e) =>{
        e.preventDefault()
        const { name, value } = e.target;
        let pattern;

        switch(name) {
            case "wl_id":
            case "issue_ts":
            case "recipient":
            case "address":
            case "return_address":
            case "file":
            case "ref":
            case "cover":
            case "color":
            case "duplex":
            case "template_id":
            case "city":
            case "address2":
            case "state":
            case "type_id":
                pattern = /^[a-zA-Z0-9_\s]+$/;
                break;
            case "zip":
                pattern = /^[0-9\s]+$/;
                break;
            default:
                pattern = null;
        }
        const formatValue = value.replace(/-/g, '');
        const isValid = pattern && validation(formatValue, pattern);

        if (value === '' || isValid) {
            let formattedInput = value;
            switch(name) {
                case "wl_id":
                    setwl_id(formattedInput)
                    break;
                case "issue_ts":
                    setissue_ts(formattedInput)
                    break;
                case "recipient":
                    setFirst_recipient(formattedInput)
                    break
                case "address":
                    setAddress(formattedInput)
                    break
                case "return_address":
                    setReturnAddress(formattedInput)
                    break
                case "file":
                    setFile(formattedInput)
                    break
                case "ref":
                    setRef(formattedInput)
                    break
                case "cover":
                    setCover(formattedInput)
                    break
                case "color":
                    setColor(formattedInput)
                    break
                case "duplex":
                    setDuplex(formattedInput)
                    break
                case "template_id":
                    setTemplateId(formattedInput)
                    break
                case "city":
                    setCity(formattedInput)
                    break
                case "state":
                    setState(formattedInput)
                    break
                case "zip":
                    setZip(formattedInput)
                    break
                case "address2":
                    setAddress2(formattedInput)
                    break
                case "type_id":
                    setTypeID(formattedInput)
                    break
                default:

            }
        }
    };
    const onChangeInput= (e)=>{
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    }
    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date
        });
    };
    const handleSubmit = (e) =>{
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const Data = {
            ...formData,
            date: dayjs(formData.date).format('YYYY-MM-DD'),
            user_profile_id: storedUser.id
        };
        fetch('http://127.0.0.1:5000/api/v3/add_docs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(Data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || "Custom Info service is not available");
                    });
                }
            })
            .then(data => {
                console.log(data);
                alert('success')
            })
            .catch(error => {
                console.error('An error occurred:', error);
                alert(error)
            });
        console.log(Data);
        // /api/v3/add_docs
    }
    return(
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Add a Document</h1>
                    <dt className="flex justify-center content-center">
                        <p className={"py-2 text-md text-gray-400 flex justify-center content-center"}>Here you can
                            Add Documents</p>
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div className="flex justify-center items-center">
                                    <svg data-popover-target="chart-info" data-popover-placement="bottom"
                                         className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ms-1"
                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"/>
                                    </svg>
                                    <div data-popover="" id="chart-info" role="tooltip"
                                         className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                        <h1 className={"flex justify-center font-bold text-center mt-6 mb-2"}>Dictionary</h1>
                                        <div className="p-3 space-y-2">
                                            <h1 className="font-semibold text-gray-900 dark:text-white">wl_id</h1>
                                            <p>ID of the whitelabel to use.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Issue_ts</h1>
                                            <p>Date to send the statement on.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">File</h1>
                                            <p>Name of the pdf for this statement.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">template_id</h1>
                                            <p>Template to use for mail merge style statements.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Type_id</h1>
                                            <p>Type of document, Can be the numeric id or short_name of any defined document types.</p>
                                        </div>
                                        <div data-popper-arrow=""></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dt>
                </dl>
            </div>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24" onSubmit={handleSubmit}>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Generic Documents Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="wl_id" id="floating_wl_id" value={wl_id} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_wl_id"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Wl_id</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="issue_ts" id="floating_issue_ts" value={issue_ts} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_issue_ts"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Issue_ts</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="recipient" id="recipient" value={recipient} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_recipient"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Recipient Name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="address" id="floating_address" value={address} onChange={(e) =>{onChangeInput(e);handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_address"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="return_address" id="floating_return_address" value={return_address} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_return_address"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Return
                                    Address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="file" id="floating_file" value={file} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_file"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">File Name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="ref" id="floating_ref" value={ref} onChange={(e) =>{onChangeInput(e); handle(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_ref"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Internal
                                    Reference</label>
                            </div>
                        </div>
                    </nav>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Generic Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="cover" id="floating_cover" value={cover} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_cover"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Cover</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="color" id="floating_color" value={color} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_color"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Color</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="duplex" id="floating_duplex" value={duplex} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_duplex"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Duplex</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="template_id" id="floating_template_id" value={template_id}
                                       onChange={(e) => {
                                           onChangeInput(e);
                                           handle(e)
                                       }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_template_id"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Template
                                    ID</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="address2" id="floating_address2" value={payment_address2}
                                       onChange={(e) => {
                                           onChangeInput(e);
                                           handle(e)
                                       }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_address2"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Complete
                                    Address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="city" id="floating_city" value={city} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }} maxLength={2}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_city"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">City</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="state" id="floating_state" value={state} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_state"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">State</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="zip" id="floating_zip" value={zip} onChange={(e) => {
                                    onChangeInput(e);
                                    handle(e)
                                }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_zip"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Zip</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <div className="relative z-40 w-full mb-5 group">
                                    <div className="relative max-w-3xl flex flex-col">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Set Date"
                                                name='send_date'
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                className="w-full rounded-3xl border-2"
                                                PopperProps={{
                                                    className: "custom-popper"
                                                }}
                                                inputProps={{
                                                    className: "custom-input"
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="type_id" id="floating_type_id" value={type_id}
                                       onChange={(e) => {
                                           onChangeInput(e);
                                           handle(e)
                                       }}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_type_id"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Type
                                    of Document</label>
                            </div>
                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-12 group">
                        <button type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base w-full sm:w-2/3 px-16 py-2 text-center">Send
                            Information
                        </button>
                    </div>
                </form>
            </section>

        </>
    )
}

export default AddDocumentsForms;
