import React, {useState} from 'react';
import {ResponseModal, ResponseParcelModal,ResponseTrackModal} from "./modals/responseModal";
const ActivitiesSection = ({activities,parcels, trackers}) => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const activitiesCount = activities.length;
    const parcelCount = parcels.length;
    const tackerCount = trackers.length
    const handleResponse = (id) =>{
            const formData = {
                addresses_id: id
            }
            console.log(formData)
            fetch('http://127.0.0.1:5000/api/get_activity_responsebyID', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 404) {
                        throw new Error('No data to fetch')
                    } else {
                        return response.json().then(error => {
                            throw new Error(error.message || 'Server responded with an error.');
                        })
                    }
                })
                .then(data =>{
                    console.log(data)
                    if (data) {
                        handleOpenDetails(data)
                    }else{
                        alert("No data to fetch")
                    }
                })
                .catch(error => {
                    alert(error.message || 'Server responded with an error.');
                    console.log(error);
                })


        }
    const handleParcelResponse = (idParcel) =>{
        const dataform= {
            parcel_id: idParcel,
        }
        console.log(dataform)
        fetch('http://127.0.0.1:5000/api/get_activity_parcelsbyID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataform)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('No data to fetch')
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    })
                }
            })
            .then(data =>{
                console.log(data)
                if (data) {
                    handleOpen(data)
                }else{
                    alert("No data to fetch")
                }
            })
            .catch(error => {
                alert(error.message || 'Server responded with an error.');
                console.log(error);
            })
    }

    const handleTrackResponse = (idTrack) =>{
        const dataform= {
            tracker_id: idTrack,
        }
        console.log(dataform)
        fetch('http://127.0.0.1:5000/api/get_tracker_responseID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataform)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('No data to fetch')
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    })
                }
            })
            .then(data =>{
                console.log(data)
                if (data) {
                    handleOpenT(data)
                }else{
                    alert("No data to fetch")
                }
            })
            .catch(error => {
                alert(error.message || 'Server responded with an error.');
                console.log(error);
            })
    }

    const [selectedResponse, setSelectedResponse] = useState(null);
    const [selectedResponseParcel, setSelectedResponseParcel] = useState(null);
    const [openP, setOpenP] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedResponseTracker, setSelectedResponseTracker] = useState(null);
    const [openT, setOpenT] = useState(false);
    const handleOpen = (data) => {
        setSelectedResponseParcel(data)
        setOpenP(true);
    }
    const handleOpenDetails = (data) => {
        setSelectedResponse(data)
        setOpen(true);
    }
    const handleCloseDetails = () => setOpen(false);
    const handleClose = () => setOpenP(false);
    const handleOpenT = (data) => {
        setSelectedResponseTracker(data)
        setOpenT(true);
    }
    const handleCloseT = () => setOpenT(false);
    return(
        <>
           <ResponseModal
               handleOpenDetails={handleOpenDetails}
               handleCloseDetails={handleCloseDetails}
               selectedResponse={selectedResponse}
               open={open}
           />
            <ResponseParcelModal
                handleOpen={handleOpen}
                handleClose={handleClose}
                selectedResponseParcel={selectedResponseParcel}
                openP={openP}
            />
            <ResponseTrackModal
                handleOpenT={handleOpenT}
                handleCloseT={handleCloseT}
                selectedResponseTracker={selectedResponseTracker}
                openT={openT}
            />
            <nav
                className={"grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center content-center p-8 gap-8"}>
                <dt className={"text-lg bg-gray-100 rounded-2xl"}>
                    <div>
                        <nav className="flex justify-around content-center p-4 space-x-4 ">
                            <span id={"count"}
                                  className="bg-gray-300 rounded-full font-bold p-4 flex items-center justify-center content-center w-4 h-4">{activitiesCount}</span>
                            <h1 className={"font-bold text-center text-xl text-gray-700 content-center"}>Shipment
                                Address</h1>
                            <div className={"w-fit bg-gray-200  rounded-2xl content-center"}><p
                                className={"font-bold text-gray-600 test-md p-2"}>ID: {storedUser.profile.user_profile_id}</p></div>
                        </nav>
                        <div className={"overscroll-contain mb-8"}>
                            {activities && activities.map(activity => (
                                <nav key={activity.id} className="flex justify-center content-center p-4 w-full">
                                    <div className={"grid grid-cols-1  bg-white rounded-2xl w-full"}>
                                        <dt className={"p-2 space-y-2 "}>
                                            <dl className={"w-auto h-fit flex justify-center"}><img src="/ship.png"
                                                                                                    alt="logo"
                                                                                                    className={"w-16 h-auto"}/>
                                            </dl>
                                            <h1 className={"text-lg font-bold flex justify-center"}>Request <dl
                                                className={"px-2 text-md font-medium text-gray-500"}>{activity.id}</dl>
                                            </h1>
                                            <h1 className={"text-sm sm:text-lg font-bold flex justify-center"}>{activity.company}</h1>
                                            <p className={"text-md font-medium text-gray-500 flex justify-center"}>{activity.street1}, {activity.street2}</p>
                                            <div className={"font-medium flex text-md justify-center"}>Location: <p
                                                className={"font-thin px-2 text-md"}>{activity.city}, {activity.country}</p>
                                            </div>
                                            <div className={"font-medium flex text-md justify-center"}>Phone: <p
                                                className={"font-thin px-2 text-md"}>{activity.phone}</p>
                                            </div>
                                        </dt>
                                        <dt className={"flex justify-center"}>
                                            <button value={activity.id}
                                                    onClick={() => {
                                                        handleResponse(activity.id);
                                                    }}
                                                    className={"gradient-card w-full text-white text-base p-2 rounded-br-2xl rounded-bl-2xl hover:bg-primary"}>Response
                                            </button>
                                        </dt>
                                    </div>

                                </nav>
                            ))}
                        </div>
                    </div>
                </dt>
                <dt className={"text-lg bg-gray-100 rounded-2xl"}>
                    <div>
                        <nav className="flex justify-around content-center p-4 space-x-4 ">
                            <span id={"count"}
                                  className="bg-gray-300 rounded-full font-bold p-4 flex items-center justify-center content-center w-4 h-4">{parcelCount}</span>
                            <h1 className={"font-bold text-center text-xl text-gray-700 content-center"}>Parcels</h1>
                            <div className={"w-fit bg-gray-200  rounded-2xl content-center"}><p
                                className={"font-bold text-gray-600 test-md p-2"}>ID: {storedUser.profile.user_profile_id}</p></div>
                        </nav>
                        <div className={"overscroll-contain mb-8"}>
                            {parcels && parcels.map(parcel => (
                                <nav key={parcel.id} className="flex justify-center content-center p-4 w-full">
                                    <div className={"grid grid-cols-1  bg-white rounded-2xl w-full"}>
                                        <dt className={"p-2 space-y-2 "}>
                                            <dl className={"w-auto h-fit flex justify-center"}><img src="/parcels.png"
                                                                                                    alt="logo"
                                                                                                    className={"w-16 h-auto"}/>
                                            </dl>
                                            <h1 className={"text-lg font-bold flex justify-center"}>Request <dl
                                                className={"px-2 text-md font-medium text-gray-500"}>{parcel.id}</dl>
                                            </h1>
                                            <h1 className={"flex justify-center font-bold "}>Values</h1>
                                            <p className={"text-md font-medium text-gray-500 flex justify-center"}><span
                                                className={"font-bold text-gray-900 pr-2"}>L:</span> {parcel.length},
                                                <span
                                                    className={"font-bold text-gray-900 pr-2 pl-4"}> Wth: </span> {parcel.width}
                                            </p>
                                            <p className={"text-md font-medium text-gray-500 flex justify-center"}><span
                                                className={"font-bold text-gray-900 pr-2"}>H: </span>{parcel.height}, <span
                                                className={"font-bold text-gray-900 pr-2 pl-4"}>Wht:</span> {parcel.weight}
                                            </p>
                                        </dt>
                                        <dt className={"flex justify-center"}>
                                            <button value={parcel.id}
                                                    onClick={() => {
                                                        handleParcelResponse(parcel.id);
                                                    }}
                                                    className={"gradient-card-green w-full text-white text-base p-2 rounded-br-2xl rounded-bl-2xl hover:bg-card"}>Response
                                            </button>
                                        </dt>
                                    </div>

                                </nav>
                            ))}
                        </div>
                    </div>
                </dt>
                <dt className={"text-lg bg-gray-100 rounded-2xl"}>
                    <div>
                        <nav className="flex justify-around content-center p-4 space-x-4 ">
                            <span id={"count"}
                                  className="bg-gray-300 rounded-full font-bold p-4 flex items-center justify-center content-center w-4 h-4">{tackerCount}</span>
                            <h1 className={"font-bold text-center text-xl text-gray-700 content-center"}>Trackers</h1>
                            <div className={"w-fit bg-gray-200  rounded-2xl content-center"}><p
                                className={"font-bold text-gray-600 test-md p-2"}>ID: {storedUser.profile.user_profile_id}</p></div>
                        </nav>
                        <div className={"overscroll-contain mb-8"}>
                            {trackers && trackers.map(track => (
                                <nav key={track.id} className="flex justify-center content-center p-4 w-full">
                                    <div className={"grid grid-cols-1  bg-white rounded-2xl w-full"}>
                                        <dt className={"p-2 space-y-2 "}>
                                            <dl className={"w-auto h-fit flex justify-center"}><img src="/tracker.png"
                                                                                                    alt="logo"
                                                                                                    className={"w-auto h-24"}/>
                                            </dl>
                                            <h1 className={"text-lg font-bold flex justify-center"}>Request <dl
                                                className={"px-2 text-md font-medium text-gray-500"}>{track.id}</dl>
                                            </h1>
                                            <h1 className={"flex justify-center font-bold "}>Values</h1>
                                            <p className={"text-md font-medium text-gray-500 flex justify-center"}><span
                                                className={"font-bold text-gray-900 pr-2"}>Tracker Code:</span> {track.tracking_code},
                                                <span
                                                    className={"font-bold text-gray-900 pr-2 pl-4"}> Carrier: </span> {track.carrier}
                                            </p>

                                        </dt>
                                        <dt className={"flex justify-center"}>
                                            <button value={track.id}
                                                    onClick={() => {
                                                        handleTrackResponse(track.id);
                                                    }}
                                                    className={"bg-gray-800 w-full text-white text-base p-2 rounded-br-2xl rounded-bl-2xl hover:bg-primary"}>Response
                                            </button>
                                        </dt>
                                    </div>

                                </nav>
                            ))}
                        </div>
                    </div>
                </dt>
            </nav>
        </>
    )
}

export default ActivitiesSection;