import React, {useEffect} from "react";
import {Link} from "react-router-dom";

const Menu = () => {
    useEffect(() => {
        const toggleButton = document.querySelector('[data-collapse-toggle="navbar-sticky"]');
        const toggleDrop = document.querySelector('[data-dropdown-toggle="dropdownNavbar"]');

        const menu = document.getElementById('navbar-sticky');
        const dropdown = document.getElementById('dropdownNavbar');

        if (toggleButton && menu) {
            toggleButton.addEventListener('click', () => {
                const isMenuHidden = menu.classList.contains('hidden');

                if (isMenuHidden) {
                    menu.classList.remove('hidden');
                    menu.setAttribute('aria-expanded', 'true');
                } else {
                    menu.classList.add('hidden');
                    menu.setAttribute('aria-expanded', 'false');
                }
            });
        }
        if (toggleDrop && dropdown) {
            toggleDrop.addEventListener('click', () => {
                const isMenuHidden = dropdown.classList.contains('hidden');

                if (isMenuHidden) {
                    dropdown.classList.remove('hidden');
                    dropdown.setAttribute('aria-expanded', 'true');
                } else {
                    dropdown.classList.add('hidden');
                    dropdown.setAttribute('aria-expanded', 'false');
                }
            });
        }

    }, []);
    return(
        <>
            <section >
                <nav className="bg-white fixed w-full z-20 top-0 start-0 animated-card shadow-xl">
                    <div className=" flex flex-wrap items-center justify-around p-4">
                        <Link to="/" className="flex items-center space-x-2 rtl:space-x-reverse">
                            <img src="/MoneSureLogoG.png" className="h-24 hidden lg:block" alt=" Logo"/>
                            <img src="/MoneSureLogo.png" className="h-16 block sm:hidden" alt=" Logo"/>
                        </Link>
                        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                            <nav>
                                <Link to="/login"><button type="button" className=" hover:text-white hover:bg-primaryV focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mx-4">Log In</button></Link>
                                <Link to="/register"><button type="button"  className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">Sign Up</button></Link>
                            </nav>
                            <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">V
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                </svg>
                            </button>
                        </div>
                        <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <Link to="/"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 "
                                          aria-current="page">Home</Link>
                                </li>
                                <li>
                                    <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar"
                                            className="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">Company <svg
                                        className="w-2.5 h-2.5 ms-2.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2" d="m1 1 4 4 4-4"/>
                                    </svg></button>
                                    {/*// <!-- Dropdown menu -->*/}
                                    <div id="dropdownNavbar"
                                         className="z-40 hidden absolute font-normal bg-white rounded-lg shadow-3xl border-gray-500 w-96 p-2">
                                        <ul className="py-2 text-sm text-gray-700 grid grid-cols-1 gap-4"
                                            aria-labelledby="dropdownLargeButton">
                                            <li>
                                                <Link to={"/merchant/section"}
                                                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                    <span className={"font-bold text-md"}><span
                                                        className={"font-bold text-teal-800 mr-2"}>&#8226;</span>Merchant</span>
                                                    <p className={"w-full text-wrap p-2"}>This section offers essential
                                                        services for businesses.</p></Link>
                                            </li>
                                            <li>
                                                <Link to={"/shipping/section"}
                                                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                    <span className={"font-bold text-md"}><span
                                                        className={"font-bold text-teal-800 mr-2"}>&#8226;</span>Shipping</span>
                                                    <p className={"w-full text-wrap p-2"}>Our shipping sections
                                                        specializes in flexible shipping options.</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/digital/section"}
                                                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                    <span className={"font-bold text-md"}><span
                                                        className={"font-bold text-teal-800 mr-2"}>&#8226;</span>Digital Payments</span>
                                                    <p className={"w-full text-wrap p-2"}>Our cheque section facilitates
                                                        the management and processing of cheque payments. </p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/selle_sure"}
                                                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                    <span className={"font-bold text-md"}><span
                                                        className={"font-bold text-teal-800 mr-2"}>&#8226;</span>SelleSure</span>
                                                    <p className={"w-full text-wrap p-2"}>This section offers essential
                                                        services for sales operations</p></Link>
                                            </li>
                                        </ul>

                                    </div>
                                </li>
                                <li>
                                    <Link to="/about"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">About</Link>
                                </li>
                                <li>
                                    <Link to="/service"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">Services</Link>
                                </li>
                                <li>
                                    <Link to="/pricing"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">Pricing</Link>
                                </li>
                                <li>
                                    <Link to="/contact"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">Contact</Link>
                                </li>
                                <li>
                                    <Link to="/marketing"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">Marketing</Link>
                                </li>
                                <li>
                                    <Link to="/guides"
                                          className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 ">ToolBox</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default Menu;
