import React from 'react';
import Menu from "../Components/navbar/menu";
import MarketingTop from "../Marketing/marketing_top";
import SlideMarket from "../Marketing/marketing_slidehome";
import MarketingSlideV from "../Marketing/marketing_slidev"
import MarketingProviding from "../Marketing/marketing_provide";
import MarketingServices from "../Marketing/marketing_services"
import MarketingAnnounced from "../Marketing/marketing_announced";
import MarketingStarted from "../Marketing/marketing_started"
import Footer from "../Components/footer/footer";

const Marketing = () => {
    return(
        <>
            <Menu/>
            <MarketingTop/>
            <SlideMarket/>
            <MarketingProviding/>
            <MarketingSlideV/>
            <MarketingServices/>
            <MarketingAnnounced/>
            <MarketingStarted />
            <Footer/>
        </>
    )
}

export default Marketing;