import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar"
import NavBar from "../../Components/Dashboard/GenericInAll/navigation"
import AddDocument from "../../Components/Register/forms/AddDocumentsForms";

const AddDocuments = () => {

    return(
        <>

            <SideBar /><NavBar />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <AddDocument  />
                    </div>
                </div>
            </section>
        </>
        )
}

export default AddDocuments;