import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import Customs from "../../Components/Dashboard/FormsProfile/Customs";
const CustomsInfo = () => {
    return(
        <>
                <SideBar />
                <Navigation />
                <Customs/>
        </>
    )
}

export default CustomsInfo;