import {useEffect, useState} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {Link} from "react-scroll";
import { useLocation } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const ManageSellesure = () => {
    const location = useLocation();

    useEffect(() => {
        // Verifica si estamos en la ruta deseada
        if (location.pathname !== '/selle_sure') {
            return; // No hacer nada si la ruta no coincide
        }

        // Asegúrate de que el efecto se ejecute solo una vez por componente
        let panels = gsap.utils.toArray(".panel"),
            observer = ScrollTrigger.normalizeScroll(true),
            scrollTween;

        document.addEventListener("touchstart", e => {
            if (scrollTween) {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        }, { capture: true, passive: false });

        function goToSection(i) {
            const viewportHeight = window.innerHeight;
            scrollTween = gsap.to(window, {
                scrollTo: { y: i * viewportHeight, autoKill: false },
                onStart: () => {
                    observer.disable();
                    observer.enable();
                },
                duration: 1,
                onComplete: () => scrollTween = null,
                overwrite: true
            });
        }

        panels.forEach((panel, i) => {
            ScrollTrigger.create({
                trigger: panel,
                start: "top bottom",
                end: "+=199%",
                onToggle: self => self.isActive && !scrollTween && goToSection(i)
            });
        });

        ScrollTrigger.create({
            start: 0,
            end: "max",
            snap: 1 / (panels.length - 1)
        });

        // Limpieza
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            document.removeEventListener("touchstart", () => {});
        };
    }, [location.pathname])
    const [activeTab, setActiveTab] = useState('stats');

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <div>
                <section className="description panel h-screen top-0 fullscreen-selles">
                    <div className="flex flex-col justify-center items-center text-center content-center h-full">
                        <h1 className={"p-4 rounded-3xl font-bold text-6xl mb-4 bg-white w-fit"}><span
                            className={"gradient-text"}>Selle</span><span
                            className={"gradient-text-green"}>Sure</span></h1>
                        <h1 className={"text-white text-2xl"}>Excelling Your Selling</h1>
                        <div className="mt-8 w-full flex flex-col justify-center text-center items-center content-center">
                            <div className={"w-full mt-4 flex justify-center text-center items-center content-center"}>
                                <svg className="w-8 h-8  animate-bounce bg-blue-700 rounded-full text-white" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="M12 19V5m0 14-4-4m4 4 4-4"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="panel h-screen top-0 fullscreen-selles2 p-12  ">
                    <nav className="flex flex-col justify-center items-center text-center content-center h-full ">
                        <h1 className={"font-bold text-5xl mb-16 text-white bg-primary/70 p-4 rounded-2xl "}>Why Choose <span className={"text-green-400"}>SelleSure?</span></h1>
                        <div className={"w-full grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center items-center content-center "}>
                            <div
                                className="max-w-sm p-6 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 bg-white border border-gray-200 rounded-lg shadow d">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">Targeting
                                    the
                                    Main Markets and Beyond</h5>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Effective Strategies:</span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Reach the top markets
                                        and the remaining 50% with tailored, effective strategies.
                                    </p>
                                </nav>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Comprehensive Coverage:</span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Ensure your products are seen by a wider audience across various platforms.
                                    </p>
                                </nav>
                                <Link to={'/contact'}
                                      className="inline-flex font-medium items-center text-blue-600 hover:underline">
                                    Contact Us
                                    <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                                    </svg>
                                </Link>
                            </div>
                            <div
                                className="max-w-sm p-6 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 bg-white border border-gray-200 rounded-lg shadow  h-fit">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">Hosted
                                    Selling</h5>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Effortless Management: </span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Keep your product, and let us handle the selling process.
                                    </p>
                                </nav>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Expert Handling: </span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Benefit from our expertise in marketing and sales without lifting a finger.
                                    </p>
                                </nav>
                                <Link to={'/contact'}
                                      className="inline-flex font-medium items-center text-blue-600 hover:underline">
                                    Contact Us
                                    <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                                    </svg>
                                </Link>
                            </div>
                            <div
                                className="max-w-sm p-6 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 bg-white border border-gray-200 rounded-lg shadow  h-fit">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">Selling, Not
                                    Just Showing</h5>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Optimized Networks: </span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Use our networks designed specifically to sell products, moving beyond
                                        traditional sales channels.
                                    </p>
                                </nav>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Enhanced Visibility: </span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Increase your product’s visibility and attractiveness to potential buyers.
                                    </p>
                                </nav>
                                <Link to={'/contact'}
                                      className="inline-flex font-medium items-center text-blue-600 hover:underline">
                                    Contact Us
                                    <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                                    </svg>
                                </Link>
                            </div>
                            <div
                                className="max-w-sm p-6 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 bg-white border border-gray-200 rounded-lg shadow  h-fit">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 ">Affordable and Secure</h5>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Cost-Effective Solutions: </span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Enjoy high-quality services without breaking the bank
                                    </p>
                                </nav>
                                <nav className={"flex flex-col justify-center text-start items-start content-center"}>
                                    <dt className={"flex justify-center text-center items-center content-center"}>
                                        <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className={"font-bold px-3"}>Security Assured:</span>
                                    </dt>
                                    <p className={"ml-6"}>
                                        Protect your transactions and customer data with our robust security measures.
                                    </p>
                                </nav>
                                <Link to={'/contact'}
                                      className="inline-flex font-medium items-center text-blue-600 hover:underline">
                                    Contact Us
                                    <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </section>
                <section className="panel fullscreen-selles4 h-screen top-0 ">
                    <nav className={"flex flex-col justify-center items-center text-center content-center h-full p-8 "}>
                        <h1 className={"font-bold text-5xl mb-16 text-white bg-primary/70 p-4 rounded-2xl "}>Additional<span className={"text-green-400"}>Benefits</span></h1>
                        <div
                            className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">Select tab</label>
                                <select id="tabs" onChange={(e) => handleTabChange(e.target.value)}
                                        className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm rounded-t-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="stats">Sales and Marketing Strategies</option>
                                    <option value="about">Operational and Support Services</option>
                                    <option value="faq">Growth and Networking Opportunities</option>
                                </select>
                            </div>
                            <ul className="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex dark:divide-gray-600 dark:text-gray-400 rtl:divide-x-reverse"
                                id="fullWidthTab" data-tabs-toggle="#fullWidthTabContent">
                                <li className="w-full">
                                    <button
                                        type="button"
                                        className={`inline-block w-full p-4 rounded-ss-lg ${activeTab === 'stats' ? 'bg-gray-100' : 'bg-gray-50'} hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600`}
                                        onClick={() => handleTabChange('stats')}
                                    >
                                        Sales and Marketing Strategies
                                    </button>
                                </li>
                                <li className="w-full">
                                    <button
                                        type="button"
                                        className={`inline-block w-full p-4 ${activeTab === 'about' ? 'bg-gray-100' : 'bg-gray-50'} hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600`}
                                        onClick={() => handleTabChange('about')}
                                    >
                                        Operational and Support Services
                                    </button>
                                </li>
                                <li className="w-full">
                                    <button
                                        type="button"
                                        className={`inline-block w-full p-4 rounded-se-lg ${activeTab === 'faq' ? 'bg-gray-100' : 'bg-gray-50'} hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600`}
                                        onClick={() => handleTabChange('faq')}
                                    >
                                        Growth and Networking Opportunities
                                    </button>
                                </li>
                            </ul>
                            <div id="fullWidthTabContent" className="border-t border-gray-200 dark:border-gray-600">
                                <div className={`p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 ${activeTab === 'stats' ? 'block' : 'hidden'}`} id="stats"
                                     role="tabpanel" aria-labelledby="stats-tab">
                                    <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-3 dark:text-white sm:p-8">
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Customizable Plans</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Tailored Solutions:</span>
                                                </dt>
                                                <p className={"text-start"}>Choose from a range of plans that fit your
                                                    specific needs and budget.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Scalable Options:</span>
                                                </dt>
                                                <p className={"text-start"}>Easily scale your sales efforts as your
                                                    business grows.</p>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Data-Driven Insights</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Analytics & Reporting:</span>
                                                </dt>
                                                <p className={"text-start"}>Gain valuable insights from detailed
                                                    analytics and reports.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Informed Decisions:</span>
                                                </dt>
                                                <p className={"text-start"}>Make informed decisions with real-time data
                                                    on your sales performance.</p>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Market Adaptability</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Trend Integration:</span>
                                                </dt>
                                                <p className={"text-start"}>Stay ahead of the market trends with our
                                                    adaptive selling techniques.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Dynamic Approaches: </span>
                                                </dt>
                                                <p className={"text-start"}>Utilize dynamic approaches to meet changing
                                                    market demands.</p>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Targeted Marketing</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Precision Targeting:</span>
                                                </dt>
                                                <p className={"text-start"}> Reach your ideal customers with targeted
                                                    marketing efforts.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Higher Conversion Rates: </span>
                                                </dt>
                                                <p className={"text-start"}>Increase sales by focusing on the right
                                                    audience.</p>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Location-Based Selling</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Geo-Targeting:</span>
                                                </dt>
                                                <p className={"text-start"}> Sell products more effectively with
                                                    location-based selling strategies.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Localized Campaigns:</span>
                                                </dt>
                                                <p className={"text-start"}>Run campaigns tailored to specific
                                                    geographic areas for better results.</p>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-xl font-extrabold">Unified Channels for Sell Assurance</dt>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-start items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Connected Channels:</span>
                                                </dt>
                                                <p className={"text-start"}> Integrate multiple sales channels for a seamless selling experience.</p>
                                            </dd>
                                            <dd className="text-gray-500 dark:text-gray-400 ">
                                                <dt className={"flex justify-start text-center items-center content-center"}>
                                                    <svg
                                                        className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20">
                                                        <path
                                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className={"font-bold px-3"}>Consistent Performance:</span>
                                                </dt>
                                                <p className={"text-start"}> Ensure a consistent and reliable sales process across all platforms.</p>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className={`p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 ${activeTab === 'about' ? 'block' : 'hidden'}`} id="about"
                                     role="tabpanel" aria-labelledby="about-tab">
                                    <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">Operational and Support Services</h2>
                                    {/*// <!-- List -->*/}
                                    <ul role="list"
                                        className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-start text-start  space-y-4 text-gray-500 dark:text-gray-400">
                                        <li className=" ">
                                            <div className={"flex space-x-2 rtl:space-x-reverse items-center"}>
                                                <svg
                                                    className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="leading-tight">Dedicated Support</span>

                                            </div>
                                            <div className={"flex ml-8 mt-2"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span className={"mr-2 font-bold"}>Customer Support:</span> Access
                                                    our dedicated support team whenever you need assistance.</p>
                                            </div>
                                            <div className={"flex ml-8"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span className={"mr-2 font-bold"}>Expert Advice</span>Receive expert
                                                    advice to optimize your selling strategies.</p>
                                            </div>
                                        </li>
                                        <li className=" ">
                                            <div className={"flex space-x-2 rtl:space-x-reverse items-center"}>
                                                <svg
                                                    className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="leading-tight">Get Rid of Old Inventory</span>

                                            </div>
                                            <div className={"flex ml-8 mt-2"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span
                                                    className={"mr-2 font-bold"}>Diverse Sales Channels:</span> Utilize
                                                    different sales channels to clear out old inventory effectively.</p>
                                            </div>
                                            <div className={"flex ml-8"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span className={"mr-2 font-bold"}>Increased Turnover:</span>Free up
                                                    space and capital for new stock by moving out old inventory.</p>
                                            </div>
                                        </li>
                                        <li className=" ">
                                            <div className={"flex space-x-2 rtl:space-x-reverse items-center"}>
                                                <svg
                                                    className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="leading-tight">Buy or Rent Sales Leads</span>

                                            </div>
                                            <div className={"flex ml-8 mt-2"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span
                                                    className={"mr-2 font-bold"}>Lead Acquisition: </span> Buy or rent
                                                    high-quality sales leads to boost your sales pipeline.</p>
                                            </div>
                                            <div className={"flex ml-8"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span className={"mr-2 font-bold"}>Quick Start:</span>Jumpstart your
                                                    sales efforts with readily available leads.</p>
                                            </div>
                                        </li>
                                        <li className=" ">
                                            <div className={"flex space-x-2 rtl:space-x-reverse items-center"}>
                                                <svg
                                                    className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="leading-tight">Human and Technical Interaction</span>

                                            </div>
                                            <div className={"flex ml-8 mt-2"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span
                                                    className={"mr-2 font-bold"}>Personal Touch:</span> Provide human interaction to build trust and enhance customer relationships.</p>
                                            </div>
                                            <div className={"flex ml-8"}>
                                                <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                <p><span className={"mr-2 font-bold"}>Technical Support:</span>Utilize advanced technical tools to streamline the sales process.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 ${activeTab === 'faq' ? 'block' : 'hidden'}`} id="faq"
                                     role="tabpanel"
                                     aria-labelledby="faq-tab">
                                    <div id="accordion-flush" data-accordion="collapse"
                                         data-active-classes="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                         data-inactive-classes="text-gray-500 dark:text-gray-400">
                                        <h2 id="accordion-flush-heading-1">
                                            <button type="button"
                                                    className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                                                    data-accordion-target="#accordion-flush-body-1" aria-expanded="true"
                                                    aria-controls="accordion-flush-body-1">
                                                <span>Partnership Opportunities</span>
                                                <svg data-accordion-icon="" className="w-3 h-3 rotate-180 shrink-0"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                                                </svg>
                                            </button>
                                        </h2>
                                        <div id="accordion-flush-body-1" className="hidden"
                                             aria-labelledby="accordion-flush-heading-1">
                                            <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                                                <div className={"flex ml-8 mt-2"}>
                                                    <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                    <p><span
                                                        className={"mr-2 font-bold"}>Collaborative Growth: </span> Grow your business through strategic partnerships facilitated by us.</p>
                                                </div>
                                                <div className={"flex ml-8"}>
                                                    <span className={"font-bold text-teal-800 mr-4"}>&#8226;</span>
                                                    <p><span className={"mr-2 font-bold"}>Networking Events:</span>Participate in exclusive networking events and expand your reach</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </section>
            </div>
        </>
    );
}


export default ManageSellesure;