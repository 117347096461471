import React from 'react';
import ShippingAddressForm from "../../Register/forms/shipAddress_forms";

const ToAddress = () => {
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pr-10 md:pl-72 py-2">
                        <ShippingAddressForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ToAddress