import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/Admin/sidebarAdmin"
import Navigation from "../../Components/Dashboard/GenericInAll/Admin/navigationAdmin"
import AdminDashboards from "../../Components/Dashboard/AdminComponents/dashboardAdmin";

const AdminPage = () => {
    return (
        <>
            <SideBar /><Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-72  md:pl-72 py-2">
                        <AdminDashboards />
                    </div>
                </div>
            </section>
        </>
    )
}


export default AdminPage;