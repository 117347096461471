import React from 'react';
import SideBar from '../../Components/Dashboard/GenericInAll/sidebar';
import NavigationBar from '../../Components/Dashboard/GenericInAll/navigation';
import Activity from "../../Components/Dashboard/checksTables/activity";

const ActivityPage = () => {
    return(
        <>
        <SideBar/><NavigationBar/>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <Activity />
                    </div>
                </div>
            </section>
        </>
    )
}
export default ActivityPage;