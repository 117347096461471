import React from 'react';
import Sidebar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation"
import ToAddress from "../../Components/Dashboard/FormsProfile/toaddress"
const ShippingAddress = () => {
    return(
        <>
                <Sidebar /><Navigation />
                <div>
                    <ToAddress />
                </div>
        </>
    )
}

export default ShippingAddress;