import React from 'react';
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const RiskVerify = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();


    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return(
        <>
            <nav className="bg-gradient-to-tl from-white from-20% to-gradL to-98%  w-full h-fit  text-center sm:text-start">
                <div
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2 lg:mx-44 md:mx-8 sm:mx-8 lg:py-18 md:py-4 sm:py-4 ">
                    <nav className={`p-8 content-center ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                        <h1 className="text-3xl text-center sm:text-start font-semibold p-6"><span
                            className="gradient-text">Mone</span><span className="gradient-text-green">Sure</span> Risk
                            Verify</h1>
                        <ul>
                            <li className="flex content-center p-2">
                                <svg className="w-5 h-5 text-card" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     viewBox="0 0 24 24">
                                    <path fillRule="evenodd"
                                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                          clipRule="evenodd"/>
                                </svg>
                                <p className="mx-2 text-lg ">With our all-risk option, you can have fully guaranteed
                                    transactions.</p>
                            </li>
                            <li className="p-2">
                                <div className="flex content-center">
                                    <svg className="w-6 h-6 text-card" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <p className="mx-2 text-lg ">Our partnership with Companeze.com and other shipping
                                        companies helps you cover against basic loss.</p>
                                </div>
                            </li>
                            <li className="p-2">
                                <div className="flex content-center">
                                    <svg className="w-5 h-5 text-card" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <p className="mx-2 text-lg ">Via our selective risk analysis business payments are
                                        guaranteed.</p>
                                </div>
                            </li>
                            <li className="p-2">
                                <div className="flex content-center">
                                    <svg className="w-6 h-6 text-card" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <p className="mx-2 text-lg ">Security team at MoneSure can help manually validate your transactions at a minimal cost. Eligibility requirements apply.</p>
                                </div>
                            </li>
                        </ul>
                        <nav className="mt-4 justify-items-center content-center ">
                            <Link to="/contact">
                                <button className="bg-primaryV rounded-full p-2"><p
                                    className="font-semibold text-white mx-6 text-sm">CONTACT US</p></button>
                            </Link>
                        </nav>
                    </nav>
                    <nav
                        className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden content-center ${isVisibleCard21 ? 'animated-up' : 'visible'}`}
                        ref={refCard21}>
                        <img src="/risk.png" alt="" className="object-cover h-96"/>
                    </nav>

                </div>
            </nav>
        </>
    );
}
export default RiskVerify;