import React from 'react';

const ThreeMerchant =()=>{
    return(
    <>
        <section className="bg-gradR w-full grid justify-center py-12">
            <div className="grid justify-center mt-16 mb-8">
                <h1 className="font-semibold text-2xl sm:text-3xl text-center"><span className="text-card">Three</span> Steps To Protection</h1>
                <img src="/threeimg.png" alt="" className="w-full" />
            </div>
            <nav className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  justify-items-center gap-20">
                <div className="w-56 bg-gradient-to-t from-oscuro to-card2 rounded-3xl">
                    <div className="grid justify-center p-4">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="M12 12v4m0 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V8m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                        </svg>
                    </div>
                    <h1 className="text-center font-black text-base p-4 text-white">Connect your Bank or Gateway</h1>
                    <h1 className="text-center font-black text-3xl mb-2 text-white">1</h1>
                </div>
                <div className="w-56 bg-gradient-to-b from-oscuro to-card2 rounded-3xl">
                    <div className="grid justify-center p-4">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                  d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                        </svg>
                    </div>
                    <h1 className="text-center font-black text-base p-4 text-white">Select your services</h1>
                    <h1 className="text-center font-black text-3xl mb-2 text-white">2</h1>
                </div>
                <div className="w-56 bg-gradient-to-t from-oscuro to-card2 rounded-3xl">
                    <div className="grid justify-center p-4">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                        </svg>
                    </div>
                    <h1 className="text-center font-black text-base p-4 text-white">Receive Financial Protection</h1>
                    <h1 className="text-center font-black text-3xl mb-2 text-white">3</h1>
                </div>
            </nav>
        </section>
    </>
    );
}

export default ThreeMerchant;