import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar"
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import CustomsItems from "../../Components/Dashboard/FormsProfile/CustomsItems"

const CustomI = () =>{
    return(
        <>
            <SideBar /><Navigation/>
                <CustomsItems />
        </>
    )
}
export default CustomI;