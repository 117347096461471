import React from 'react';
import CustomsInfoForms from "../../Register/forms/Customs_Forms";
const Customs = () => {
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                        <CustomsInfoForms />
                    </div>
                </div>
            </section>
        </>
    )
}
export default Customs;