import React from 'react';
import CheckFormUser from "../../Register/forms/cheking_formUser";

const Checking = () => {
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <CheckFormUser/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Checking