import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import { AnimatePresence } from 'framer-motion';
import Home from "./Pages/home";
import About from "./Pages/about";
import Contact from "./Pages/contact";
import Services from "./Pages/services";
import Pricing from "./Pages/pricing";
import Policy from "./Pages/policy";
import NotFound from "./Pages/404";
import Register from "./Pages/registeruser";
import Login from "./Pages/loginpage";
import VideoGuides from "./Pages/videoGuides";
import Marketing from "./Pages/Marketing";
import ScrollTop from "./JS/scrolltop";
import Recovery from "./Pages/recovery"
import ResetPassword from "./Pages/resetPass";
import LoginPage from "./Pages/Admin/PageLogin";
import {
    ProtectedRoute,
    MerchantRoute,
    ShippingRoute,
    AddChecks,
    ShipAddressRoute,
    ShipInsurancesRoute,
    TracKerRoute,
    ParcelsRoute,
    DocumentsRoute,
    ShipWoptionsRoute,
    BatchesRoute,
    CustomsInfoRoute,
    CustomsItemsRoute,
    EndShipperRoute,
    AddPaymentRoute,
    PaymentsRoute,
    TrackerPaymentsRoute,
    UpdatePaymentRoute,
    AddDocumentsRoute,
    MetadataCarrierRoute,
    BankRoute,
    ActivityRoute,
    DashboardRoute,
    SettingsRoute,
    AdminPageRoute,
    UsersAdminsPage, ManageFunctionPage
} from "./Pages/Profiles/protectedroute";
import Merchant from "./Pages/merchant";
import Sellesure from "./Pages/sellesure";
import Shipping from "./Pages/shipping"
import DigitalPayments from "./Pages/digitalpayments";

const App = () => {
    return (

        <Router>
                <ScrollTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/merchant/section" element={<Merchant />} />
                <Route path="/shipping/section" element={<Shipping />} />
                <Route path="/digital/section" element={<DigitalPayments />} />
                <Route path="/selle_sure" element={<Sellesure />} />
                <Route path="/about" element={<About />} />
                <Route path="/service" element={<Services />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/marketing" element={<Marketing />} />
                <Route path="/recovery_pass" element={<Recovery />} />
                <Route path="/ResetPassword/:token" element={<ResetPassword />} />
                <Route path="/profile" element={<ProtectedRoute />} />
                <Route path="/profile/Merchant_Account" element={<MerchantRoute />} />
                <Route path="/profile/easyposts" element={<ShippingRoute />}/>
                <Route path="/profile/checkssuing" element={<AddChecks />} />
                <Route path="/profile/shipping_address" element={<ShipAddressRoute />} />
                <Route path="/profile/shipping_insurances" element={<ShipInsurancesRoute />} />
                <Route path="/profile/trackers" element={<TracKerRoute />} />
                <Route path="/profile/parcels" element={<ParcelsRoute />} />
                <Route path="/profile/shipment_options" element={<ShipWoptionsRoute />} />
                <Route path="/profile/batch" element={<BatchesRoute />} />
                <Route path="/profile/custom" element={<CustomsInfoRoute />} />
                <Route path="/profile/customsitem" element={<CustomsItemsRoute />} />
                <Route path="/profile/endshipper" element={<EndShipperRoute />} />
                <Route path="/profile/banksaccounts" element={<BankRoute />} />
                <Route path="/profile/add_payments" element={<AddPaymentRoute />} />
                <Route path="/profile/payments" element={<PaymentsRoute />} />
                <Route path="/profile/trackPayments" element={<TrackerPaymentsRoute />} />
                <Route path="/profile/updatePayments" element={<UpdatePaymentRoute />} />
                <Route path="/profile/documents" element={<DocumentsRoute />}/>
                <Route path="/profile/addDocuments" element={<AddDocumentsRoute />} />
                <Route path="/profile/carrierMetadata" element={<MetadataCarrierRoute />} />
                <Route path="/profile/activities" element={<ActivityRoute />} />
                <Route path={"/profile/dash"} element={<DashboardRoute />} />
                <Route path={"/profile/settings"} element={<SettingsRoute />} />
                <Route path={"/administration"} element={<LoginPage />} />
                <Route path={"/administration/dashboard"} element={<AdminPageRoute />} />
                <Route path={"/administration/clients_users"} element={<UsersAdminsPage />} />
                <Route path={"/administration/manage/functions"} element={<ManageFunctionPage />} />
                <Route path="/guides" element={<VideoGuides />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>


    );
}

export default App