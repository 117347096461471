import React, {useEffect, useState} from 'react';
import toast, {Toaster} from "react-hot-toast";
import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";

const SettingsProfile = () => {
    const [userData, setUserData] = useState(null);
    const [parent, setParent] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [FormtoChange, setForms] = useState({
        name: '',
        lastname: '',
        phone: '',
        email: '',
        bio: '',
        user_id: ''
    });
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        const formsdata = {
            parent_id: storedUser.parents_id,
        };

        fetch('http://127.0.0.1:5000/api/get_parents_byID', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formsdata)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }
                return response.json();
            })
            .then(data => {
                const parentName = data[1]; // Assuming data[1] contains parent name
                setParent({ name: parentName });
            })
            .catch(error => {
                console.error('Error fetching parents:', error);
                toast.error('Error fetching parents: ' + error.message);
            });
    }, []);
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const formsdata = {
        user_id: storedUser.id,
    };
    const getUser = () => {
        fetch('http://127.0.0.1:5000/api/v6/user_profile', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formsdata)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }
                return response.json();
            })
            .then(data => {
                setUserData(data);
                console.log(data); // Verify data received in console
            })
            .catch(error => {
                console.error('Error fetching user profile:', error);
                toast.error('Error fetching user profile: ' + error.message);
            });
    }

    useEffect(() => {
        getUser()
    }, []);
    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForms({
            ...FormtoChange,
            [name]: value
        });
    };

    const submitInfo = (e) => {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formatedChange = {
            ...FormtoChange,
            user_id: storedUser.id,
        };

        // Return the promise returned by fetch
        return fetch('http://127.0.0.1:5000/api/v6/user_update', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formatedChange)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }
                return response.json();
            })
            .then(data => {
                toast.success('User updated Successfully')
                getUser()
                return data; // Ensure to return data for chaining promises
            })
            .catch(error => {
                console.error('Error updating user profile:', error);
                toast.error('Something was wrong')
                throw error; // Throw the error again to propagate it
            });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        submitInfo(e)
            setIsEditing(false);

    };

    if (!userData) {
        return <div className={"w-full flex justify-center items-center"} role="status">
            <svg aria-hidden="true"
                 className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"/>
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>;
    }

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <section className="w-full p-6 sm:flex sm:justify-center">
                <div className="bg-white border border-gray-200 rounded-lg h-fit w-full sm:w-3/5">
                    <div className="flex items-center justify-center relative">


                        <dt className="translate-y-1/2 absolute bottom-0 left-0 right-0 w-12 mx-auto">
                            <div className="hidden sm:block">
                                <Avatar sx={{width: 56, height: 56, bgcolor: '#0E49B5'}}><span
                                    className={"text-3xl"}>M</span></Avatar>
                            </div>
                            <div className="sm:hidden">
                                <Avatar sx={{width: 44, height: 44, bgcolor: '#0E49B5'}}><span
                                    className={""}>M</span></Avatar>
                            </div>
                        </dt>
                    </div>
                    <div className="w-fit p-4 cursor-pointer" >
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" onClick={handleEditClick}
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
                        </svg>
                    </div>
                    <dt className={"p-4 mt-8 grid lg:grid-cols-2 sm:grid-cols-1 justify-items-center"}>
                        <div className={"flex flex-col justify-center content-center"}>
                            <h2 className="text-2xl font-bold">{userData.user.name} {userData.user.lastname}</h2>
                            <dt className={"text-sm flex space-x-2"}>
                                <p className={"text-gray-500"}>{userData.user.status ? 'Active' : 'Inactive'} In</p>
                                <p className={"text-gray-500"}> {parent ? parent.name : ""}</p>
                            </dt>
                        </div>
                        <div className={"flex flex-col justify-center content-center"}>
                            <dt className={"p-4"}>
                                <p className={"text-xs font-bold "}>Email:</p>
                                <h2 className={"text-gray-400 text-sm"}>{userData.user.email}</h2>
                                <p className={"text-xs font-bold "}>Phone:</p>
                                <h2 className={"text-gray-400 text-sm"}>{userData.user.phone}</h2>
                            </dt>
                        </div>
                    </dt>
                    <div
                        className="p-4 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center sm:justify-items-center">
                        <dt className={"col-span-2"}>
                            <p className="text-gray-600">{userData.user.profile.bio}</p>
                        </dt>

                    </div>
                    {isEditing ? (
                        <div className="mt-4 ">
                            <form className={"p-6"} onSubmit={handleSaveChanges}>
                                <div className="grid gap-6 mb-6 md:grid-cols-2">
                                    <div>
                                        <label htmlFor="first_name"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                                            name</label>
                                        <input type="text" id="first_name" name={"name"} onChange={(e) =>{handleInputChange(e)}}
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="Name" required/>
                                    </div>
                                    <div>
                                        <label htmlFor="last_name"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                                            name</label>
                                        <input type="text" id="last_name" name={"lastname"} onChange={(e) =>{handleInputChange(e)}}
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="Last Name" required/>
                                    </div>

                                </div>
                                <div className="mb-6">
                                    <label htmlFor="phone"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone
                                        number</label>
                                    <input type="tel" id="phone" name={"phone"} onChange={(e) =>{handleInputChange(e)}}
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required/>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="email"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email
                                        address</label>
                                    <input type="email" id="email" name={"email"} onChange={(e) =>{handleInputChange(e)}}
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="john.doe@company.com" required/>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="bio"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Biography: </label>
                                    <textarea rows="4" id="bio" name={"bio"} onChange={(e) =>{handleInputChange(e)}}
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="A short Bio" required/>
                                </div>
                                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Save Changes
                                </button>
                            </form>
                        </div>
                    ) : (
                        <div className="mt-4">
                            <button className=" text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                                    onClick={handleEditClick}></button>
                        </div>
                    )}
                    <Link to={"/profile"}>
                        <div className="mt-4 underline text-blue-500 ">
                            Go profile dashboard
                        </div>
                    </Link>
                </div>

            </section>
        </>
    )
}

export default SettingsProfile;