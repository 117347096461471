import React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import useVisibility from "../../JS/Lazylow";

const Guidesinvideo = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();
    const videoData = [
        { url:'https://vimeo.com/866056606', title: 'Level III Advantage_Unbranded' },
        {url:'https://vimeo.com/829129011',title:'AuthVia TXT2PAY Demo'},
        {url:'https://vimeo.com/528459281',title:'Setting Up Apple Pay for Collect.js and Collect Checkout'},
        {url:'https://vimeo.com/412755269',title:'Collect.js Tutorial'},

        {url:'https://vimeo.com/405869616',title:'Choosing Between Collect.js and Collect Checkout'},
        {url:'https://vimeo.com/405868655',title:'Collect Checkout Tutorial'},
        {url:'https://vimeo.com/325469801',title:'Order Templates'},
        {url:'https://vimeo.com/212594880',title:'QuickBooks SyncPay'},

        {url:'https://vimeo.com/202093415',title:'QuickClick'},
        {url:'https://vimeo.com/200903640',title:'Authorize and Capture Transactions'},
        {url:'https://vimeo.com/200903633',title:'Failed Sale'},
        {url:'https://vimeo.com/200903626',title:'Run a Sale Transaction'},

        {url:'https://vimeo.com/200903616',title:'Void or Refund Transactions'},
        {url:'https://vimeo.com/200899778',title:'Account Information Settings'},
        {url:'https://vimeo.com/200899769',title:'Address Verification Settings'},
        {url:'https://vimeo.com/200899763',title:'API Configuration Settings'},

        {url:'https://vimeo.com/200899756',title:'Apple Pay Settings'},
        {url:'https://vimeo.com/200899749',title:'Card ID Verification Settings'},
        {url:'https://vimeo.com/200899736',title:'IP Restriction Settings'},
        {url:'https://vimeo.com/200899727',title:'License Manager Settings'},

        {url:'https://vimeo.com/200899724',title:'Transaction Routing Settings'},
        {url:'https://vimeo.com/200899718',title:'Look And Feel Settings'},
        {url:'https://vimeo.com/200899710',title:'Merchant Defined Fields Settings'},
        {url:'https://vimeo.com/200899706',title:'Report Configuration Settings'},

        {url:'https://vimeo.com/200899701',title:'Security Keys Settings'},
        {url:'https://vimeo.com/200899689',title:'Settlement Schedule Settings'},
        {url:'https://vimeo.com/200899674',title:'Surcharging Settings'},
        {url:'https://vimeo.com/200899666',title:'Test Mode Settings'},

        {url:'https://vimeo.com/200899660',title:'User Account Settings'},
        {url:'https://vimeo.com/200899653',title:'Currency and Country Settings'},
        {url:'https://vimeo.com/200898472',title:'iProcess Android'},
        {url:'https://vimeo.com/200898465',title:'iProcess iOS'},

        {url:'https://vimeo.com/200898455',title:'Fraud Prevention'},
        {url:'https://vimeo.com/200898448',title:'Product Manager'},
        {url:'https://vimeo.com/200898426',title:'Recurring Billing'},
        {url:'https://vimeo.com/200898419',title:'Reports'},

        {url:'https://vimeo.com/200898407',title:'SwIPe Software'},
        {url:'https://vimeo.com/200734397',title:'Invoicing'},
        {url:'https://vimeo.com/200734295',title:'Integration Portal'},
        {url:'https://vimeo.com/200734227',title:'Customer Vault'},
        {url:'https://vimeo.com/200730618',title:'Batch Upload'},
        {url:'https://vimeo.com/200722194',title:'Advanced Transactions Routing'},
    ];
    return(
        <>
            <section className="w-full h-max py-28 sm:py-2  px-28">
                <div
                    className="grid  lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 justify-items-center sm:gap-2 gap-8 lg:py-44 md:py-44 sm:py-24" style={{alignItems: 'start'}}>
                    <nav className={`rounded-3xl w-fit p-4 border-solid border-2 border-card  ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                        <h1 className="text-2xl font-bold text-center p-4 divide-y divide-solid px-12">Merchant Services
                            Manuals</h1>
                        <Box sx={{minHeight: 200, flexGrow: 1, maxWidth: 400}}>
                            <SimpleTreeView multiSelect>
                                <TreeItem itemId="grid" label="Video Manuals">
                                    {videoData.map((video,index)=>(
                                    <Link key={index} to={video.url} target="_blank" rel="noopener noreferrer"><TreeItem itemId={`video-item-${index}`} label={video.title}/></Link>
                                    ))}
                                </TreeItem>
                                <TreeItem itemId="pickers" label="Documents Manuals"
                                          sx={{borderTop: '1px solid #2A8D43'}}>
                                    <TreeItem itemId="merchant-item" label="Document 1"/>
                                    <TreeItem itemId="merchant-items" label="Document 2"/>
                                </TreeItem>

                            </SimpleTreeView>
                        </Box>
                    </nav>

                    <nav className={`rounded-3xl w-fit p-4 border-solid border-2 border-card  ${isVisibleCard21 ? 'animated-left' : 'visible'}`} ref={refCard21}>
                        <h1 className="text-2xl font-bold text-center p-4 divide-y divide-solid px-12">Shipping Services
                            Manuals</h1>
                        <Box sx={{minHeight: 200, flexGrow: 1, maxWidth: 400}}>
                            <SimpleTreeView multiSelect>
                                <TreeItem itemId="grid" label="Video Manuals">
                                    <TreeItem itemId="grid-video1" label="Video 1"/>
                                    <TreeItem itemId="grid-video2" label="Video 2"/>
                                    <TreeItem itemId="grid-video3" label="Video 3"/>
                                </TreeItem>
                                <TreeItem itemId="pickers" label="Documents Manuals"
                                          sx={{borderTop: '1px solid #2A8D43'}}>
                                    <TreeItem itemId="doc-items" label="Document 1"/>
                                    <TreeItem itemId="docs-item" label="Document 2"/>
                                </TreeItem>
                            </SimpleTreeView>
                        </Box>
                    </nav>
                </div>
            </section>
        </>
    )
}

export default Guidesinvideo;

