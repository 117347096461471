import  React from 'react'
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import Insurances from "../../Components/Dashboard/FormsProfile/insurances";

const ShipInsurances=()=>{
    return(
        <>
            <SideBar/><Navigation/>
            <div className="">
                <Insurances />
            </div>
        </>
    )
}

export default ShipInsurances;