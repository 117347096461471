import React from 'react';
import {useNavigate} from 'react-router-dom';
import LoginAdmin from '../../Components/Register/login/Admin/LoginAdminis';

const LoginPage = () => {
    const navigate = useNavigate();
    const handleLogin = () => {
        setTimeout(() =>{
            navigate('/administration/dashboard')
        },[2000])
    };

    return(
        <>
            <LoginAdmin onlogin={handleLogin}/>
        </>
    )
}

export default LoginPage