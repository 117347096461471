import React from 'react';
import PaymentModal from "./PaymentModal";

const Payments = ({payments}) => {
    const [selectedPayment, setSelectedPayment] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpenDetails = (payment) => {
        setSelectedPayment(payment)
        setOpen(true);
    }
    const handleCloseDetails = () => setOpen(false);
    const [openD, setOpenD] = React.useState(false);
    const handleOpenDelete = (payment) => {
        setSelectedPayment(payment)
        setOpenD(true);
    }
    const handleCloseDelete = () => setOpenD(false);

    const handlePaymentsSubmit = (payment_id) =>{
        const Dataform= {
            payment_id: payment_id,
        }
        console.log(Dataform)
        fetch('http://127.0.0.1:5000/api/v3/get_payments_byID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(Dataform)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('No data to fetch')
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    })
                }
            })
            .then(data =>{
                console.log(data)
                if (data) {
                    handleOpenDetails(data)
                }else{
                    alert("No data to fetch")
                }
            })
            .catch(error => {
                alert(error.message || 'Server responded with an error.');
                console.log(error);
            })
    }
    return(
        <>
            <PaymentModal
                handleOpenDetails={handleOpenDetails}
                handleOpenDelete={handleOpenDelete}
                handleCloseDetails={handleCloseDetails}
                handleCloseDelete={handleCloseDelete}
                selectedPayment={selectedPayment}
                open={open}
                openD={openD}
            />
            <div className="w-full flex flex-col justify-center items-center mb-12">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Bank payments </h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can manage the bank payments</p>
                </dl>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            First Name Payee
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Phone Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Payment address
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date to Sent
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map(payment => (
                        <tr key={payment.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {payment.payee}
                            </th>
                            <td className="px-6 py-4">
                                {payment.payee_first}
                            </td>
                            <td className="px-6 py-4">
                                {payment.payee_last}
                            </td>
                            <td className="px-6 py-4">
                                {payment.address}
                            </td>
                            <td className="px-6 py-4 space-x-2">
                                <button value={payment.id} onClick={() => handlePaymentsSubmit(payment.id)}
                                        className="border-2 border-blue-600 p-2 rounded-xl text-blue-600 hover:text-white hover:bg-blue-500">Response
                                </button>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Payments;