import React from 'react';

const Providing = () => {
    return(
        <>
        <section className="bg-gradR w-full h-fit sm:h-auto content-center">
            <nav className="grid justify-items-center h-fit text-center py-8">
                <h1 className="font-bold text-3xl sm:text-5xl"><span className="text-primaryV font-semibold ">One Platform</span> Providing Endless Solutions</h1>
            </nav>
            <nav className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center py-12 mx-12 sm:mx-56">
                <div className="mb-8 sm:mb-2 bg-white h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                    <div className="text-center grid justify-items-center py-8">
                        <svg className="w-12 h-12 text-primaryV dark:text-white text-center" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8">Business & Consumer Payments</h1>
                    <p className="font-semibold p-10 text-md">Pay and get paid using a variety of payment methods to increase security and reduce loss.</p>
                </div>
                <div className="mb-8 sm:mb-2 bg-gradient-to-t from-card to-card2 h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-700 ease-in-out  hover:scale-110">
                    <div className="text-center grid justify-items-center py-8">
                        <svg className="w-12 h-12 text-white text-center" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8 text-white">Business Payments &
                        Financing</h1>
                    <p className="font-semibold p-10 text-md text-white">Our solutions are designed to help small and medium-sized
                        businesses streamline their financial operations and access the capital they need to grow.</p>
                </div>
                <div className="mb-8 sm:mb-2 bg-white h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-700 ease-in-out  hover:scale-110">
                    <div className="text-center grid justify-items-center py-8">
                        <svg className="w-12 h-12 text-primaryV dark:text-white text-center" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                        </svg>

                    </div>
                    <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8">Universal Gateway Integration</h1>
                    <p className="font-semibold p-10 text-md">Keep your current technology without changing integration or equipment, you can add our technology easily.</p>
                </div>
            </nav>
        </section>
        </>
    );
}

export default Providing;