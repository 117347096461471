import React from 'react';
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import TrackForm from "../../Components/Register/forms/trackForms";
const TrackerPayment = () => {
    return(
        <>

                <SideBar /><Navigation />
                    <section className="sm:flex">
                        <div className="flex-1">
                            <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                                <TrackForm />
                            </div>
                        </div>
                    </section>
        </>
    )
}

export default TrackerPayment;