import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import toast, {Toaster} from "react-hot-toast";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    '@media (min-width: 998px)': {
        width: '50%',
    },
};

const ModalAdmin = ({ open, handleCloseDetails, selectedResponse }) => {
    const validation = (value, pattern) => {
        return pattern.test(value);
    };
    const [formData, setFormData] = useState({
        parent_id: '',
        name: '',
        location: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        if (selectedResponse) {
            setFormData({
                parent_id: selectedResponse.id,
                name: selectedResponse.name,
                location: selectedResponse.location,
                email: selectedResponse.email,
                phone: selectedResponse.phone_number,
            });
        }
    }, [selectedResponse]);

    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;
        switch (name) {
            case "name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "location":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            default:
                pattern = null;
        }
        const formatted = value.replace(/-/g, '');
        const isValidated = pattern && validation(formatted, pattern);
        if (value === '' || isValidated) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = formatted.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            setFormData({
                ...formData,
                [name]: formattedInput
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const submit = (e) => {
        e.preventDefault();
        const id = selectedResponse.id;
        const formattedData = {
            ...formData,
            parent_id: id,
        };
        console.log(formattedData);
        fetch('http://127.0.0.1:5000/api/v3/manageParentsUpd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('No data to fetch')
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    })
                }
            })
            .then(data =>{
                console.log(data)
                if (data) {
                    toast.success("User has been updated")
                }else{
                    toast("Data is empty")
                }
            })
            .catch(error => {
                toast.error(error.message || 'Server responded with an error.');
                console.log(error);
            })
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCloseDetails}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {selectedResponse && (
                            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex justify-end px-4 pt-4">
                                    <button
                                        id="dropdownButton"
                                        onClick={handleCloseDetails}
                                        className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                        type="button"
                                    >
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6 18 17.94 6M18 18 6.06 6"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex flex-col items-center pb-10 space-y-2">
                                    <img
                                        className="w-auto h-32 mb-3 hidden sm:block"
                                        src="/modales.png"
                                        alt="Avatars"
                                    />
                                    <h1 className={"font-bold text-xl"}>Update Client:</h1>
                                    <h1 className={"text-md "}>{selectedResponse.name}</h1>
                                    <form className={"w-full p-8"} onSubmit={submit}>
                                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="phone"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Phone number
                                                </label>
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="123-45-678"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className={"mb-6"}>
                                            <label
                                                htmlFor="location"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Location
                                            </label>
                                            <input
                                                type="text"
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={(e) => {
                                                    handle(e);
                                                    handleInputChange(e)
                                                }}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    required
                                                />

                                        </div>
                                        <div className="mb-6">
                                            <label
                                                htmlFor="email"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="john.doe@company.com"
                                                required
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

const CreateModalAdmin = ({openCreate,close}) =>{
    const [phone, setPhone] = useState("");
    const [name, setFirst] = useState("");
    const [location, setLocation] = useState("");
    const [accessMerch] = useState(false);
    const [accessShipping] = useState(false);
    const [accessDigital] = useState(false);
    const [accessTotal] = useState(false);
    const handle = (event) => {
        const {name, value} = event.target;
        let pattern;
        let sqlInjectionPattern;
        switch (name) {
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            case "name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "location":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            default:
                pattern = null;
        }

        sqlInjectionPattern = /(;|\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|TRUNCATE|EXEC|UNION|FROM|WHERE|AND|OR)\b|[-'";\\])/i;

        const isValid = pattern ? pattern.test(value) : true;
        const isSafeFromInjection = !sqlInjectionPattern.test(value);

        if ((value === '' || isValid) && isSafeFromInjection) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "phone":
                    setPhone(formattedInput);
                    break;
                case "name":
                    setFirst(formattedInput);
                    break;
                case "location":
                    setLocation(formattedInput);
                    break;
                default:
            }
        }
    }
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        email: '',
        phone: '',
        merchant_func: false,
        shipping_func: false,
        checking_func: false,
        total_func: false

    });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const handleSendParent = async (e) =>{
        e.preventDefault();
        console.log(formData);
        fetch('http://127.0.0.1:5000/api/v6/registro/parents', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error( Error.message || 'Server responded with an error.');
                }
            })
            .then(data =>{
                toast.success("Success! Client created successfully");
            })
            .catch(error => {
                console.error(error);
                toast.error('Could not save')
            });
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openCreate}
                onClose={close}
                closeAfterTransition
            >
                <Fade in={openCreate}>
                    <Box sx={style}>
                        <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-end px-4 pt-4">
                                <button
                                    id="dropdownButton"
                                    onClick={close}
                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                    type="button"
                                >
                                    <span className="sr-only">Open dropdown</span>
                                    <svg
                                        className="w-6 h-6 text-gray-800 dark:text-white"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                            d="M6 18 17.94 6M18 18 6.06 6"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-col items-center pb-10 space-y-2">
                                <h1 className={"font-semibold text-3xl py-4"}>Add new User</h1>
                                <form className="w-full px-8" onSubmit={handleSendParent}>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <label htmlFor="floating_email"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email
                                            address</label>
                                        <input type="email" name="email" onChange={(e) => {
                                            handleInputChange(e)
                                        }} id="floating_email"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="doe@gmial.com" required/>

                                    </div>
                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full mb-5 group">
                                            <label htmlFor="floating_first_name"
                                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company name</label>
                                            <input type="text" name="name" value={name} onChange={(e) => {
                                                handleInputChange(e)
                                                handle(e);
                                            }} id="floating_first_name"
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                   placeholder="Johnathan" required/>

                                        </div>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <label htmlFor="floating_last_name"
                                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location</label>
                                            <input type="text" name="location" value={location} onChange={(e) => {
                                                handleInputChange(e)
                                                handle(e);
                                            }} id="floating_last_name"
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                   placeholder="157 Av Street" required/>

                                        </div>
                                    </div>
                                    <div className="relative z-0 w-full mb-5 group">
                                        <label htmlFor="floating_phone"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone
                                            number</label>
                                        <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone"
                                               value={phone}
                                               onChange={(e) => {
                                                   handleInputChange(e)
                                                   handle(e);
                                               }}
                                               id="floating_phone"
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               placeholder="123-456-7890" required/>

                                    </div>
                                    <div className={"relative z-0 w-full mb-5 group" }>
                                        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">Client will have access to:</h3>
                                        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input id="vue-checkbox-list" type="checkbox" name={"merchant_func"}
                                                           value={accessMerch} onChange={handleInputChange}
                                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                    <label htmlFor="vue-checkbox-list"
                                                           className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Merchant
                                                        Function</label>
                                                </div>
                                            </li>
                                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input id="react-checkbox-list" type="checkbox"
                                                           name={"shipping_func"} value={accessShipping}
                                                           onChange={handleInputChange}
                                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                    <label htmlFor="react-checkbox-list"
                                                           className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Shipping
                                                        Function</label>
                                                </div>
                                            </li>
                                            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input id="angular-checkbox-list" type="checkbox"
                                                           name={"checking_func"} value={accessDigital}
                                                           onChange={handleInputChange}
                                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                    <label htmlFor="angular-checkbox-list"
                                                           className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Digital
                                                        Payments</label>
                                                </div>
                                            </li>
                                            <li className="w-full dark:border-gray-600">
                                                <div className="flex items-center ps-3">
                                                    <input id="laravel-checkbox-list" type="checkbox" name={"total_func"} value={accessTotal} onChange={handleInputChange}
                                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                    <label htmlFor="laravel-checkbox-list"
                                                           className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Total Access</label>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                    <nav className="text-center space-x-0 sm:space-x-9 py-4">
                                        <button type="submit"
                                                className="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create
                                        </button>
                                        <button type="reset" onClick={close}
                                                className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                                        </button>
                                    </nav>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Toaster position="top-center" reverseOrder={false}/>
        </>
    );
}

const UpdateModal = ({openUpd, handleCloseUpd, selected}) => {
    const [, setPhone] = useState("");
    const [, setFirst] = useState("");
    const [, setLast] = useState("");
    const [isAdmin] = useState(false);
    const handle = (event) => {
        event.preventDefault()
        const {name, value} = event.target;
        let pattern;
        let sqlInjectionPattern;

        switch (name) {
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            case "name":
            case "last_name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            default:
                pattern = null;
        }

        sqlInjectionPattern = /(;|\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|TRUNCATE|EXEC|UNION|FROM|WHERE|AND|OR)\b|[-'";\\])/i;

        const isValid = pattern ? pattern.test(value) : true;
        const isSafeFromInjection = !sqlInjectionPattern.test(value);

        if ((value === '' || isValid) && isSafeFromInjection) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch (name) {
                case "phone":
                    setPhone(formattedInput);
                    break;
                case "name":
                    setFirst(formattedInput);
                    break;
                case "last_name":
                    setLast(formattedInput);
                    break;
                default:
            }
        }
    }
    const [formData, setFormData] = useState({
        user_id: '',
        name: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
        is_admin: false,
    });
    useEffect(() => {
        if (selected) {
            setFormData({
                user_id: selected.id || '',
                name: selected.name || '',
                lastname: selected.lastname || '',
                email: selected.email || '',
                phone: selected.phone_number || '',
                is_admin: selected.is_admin || false
            });
        }
    }, [selected]);
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const sendUser = async (e) =>{
        e.preventDefault();
        console.log(formData);
        fetch('http://127.0.0.1:5000/api/v3/usersUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    return response.json().then(error => {
                        throw new Error(error.error || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                console.log(data)
                toast.success( 'User update successfully')
            })
            .catch((error) => {
                console.error(error)
                toast.error('An error was occurred')
            });
    }


    return (
        <> <Toaster position="top-center" reverseOrder={false} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openUpd}
                onClose={handleCloseUpd}
                closeAfterTransition
            >
                <Fade in={openUpd}>
                    <Box sx={style}>
                        {selected && (
                            <div className="w-full bg-white h-fit border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex justify-end px-4 pt-4">
                                    <button
                                        id="dropdownButton"
                                        onClick={handleCloseUpd}
                                        className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                        type="button"
                                    >
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6 18 17.94 6M18 18 6.06 6"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex flex-col items-center pb-10 ">
                                    <img
                                        className="w-auto h-32 mb-3 hidden sm:block"
                                        src="/modales.png"
                                        alt="Avatars"
                                    />
                                    <h1 className={"font-bold text-xl"}>Update Client:</h1>
                                    <h1 className={"text-md"}>{selected.name}</h1>
                                    <form className={"w-full p-8"} onSubmit={sendUser}>
                                        <div className="grid gap-6 md:grid-cols-2">
                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    required
                                                />
                                            </div>
                                            <div className={"mb-6"}>
                                                <label
                                                    htmlFor="location"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="location"
                                                    name="lastname"
                                                    value={formData.lastname}
                                                    onChange={(e) => {
                                                        handle(e);
                                                        handleInputChange(e)
                                                    }}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    required
                                                />

                                            </div>
                                        </div>
                                        <div className={"mb-6"}>
                                            <label
                                                htmlFor="phone"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Phone number
                                            </label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={(e) => {
                                                    handle(e);
                                                    handleInputChange(e)
                                                }}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="123-453-6782" maxLength="12" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                required
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <label
                                                htmlFor="email"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Email address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="john.doe@company.com"
                                                required
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <label
                                                htmlFor="21_password"
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                id="21_password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                minLength={8}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="*********"
                                                required
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <div
                                                className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                                <input id="bordered-checkbox-1" type="checkbox" value={isAdmin}
                                                       onChange={handleInputChange}
                                                       name="is_admin"
                                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="bordered-checkbox-1"
                                                       className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Admin
                                                    account?</label>
                                            </div>
                                        </div>
                                            <button
                                            type="submit"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

const CreateModal = ({openC,handleCloseCreate, Parents, fetchData}) => {
    const [phone, setPhone] = useState("");
    const [first_name, setFirst] = useState("");
    const [last_name, setLast] = useState("");
    const handle = (event) => {
        const {name, value} = event.target;
        let pattern;
        let sqlInjectionPattern;
        switch (name) {
            case "phone_number":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            case "name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "lastname":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            default:
                pattern = null;
        }

        sqlInjectionPattern = /(;|\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|TRUNCATE|EXEC|UNION|FROM|WHERE|AND|OR)\b|[-'";\\])/i;

        const isValid = pattern ? pattern.test(value) : true;
        const isSafeFromInjection = !sqlInjectionPattern.test(value);

        if ((value === '' || isValid) && isSafeFromInjection) {
            let formattedInput = value;
            if (name === "phone_number") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "phone_number":
                    setPhone(formattedInput);
                    break;
                case "name":
                    setFirst(formattedInput);
                    break;
                case "lastname":
                    setLast(formattedInput);
                    break;
                default:
            }
        }
    }
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        email: '',
        phone_number: '',
        password: '',
        password_repeat: '',
        parents_id: ''
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const [parents, setParents] = useState([])
    useEffect(() => {
        if (Parents === undefined || Parents === null) {
            setParents([]);
        } else {
            setParents(Parents);
        }
    }, [Parents]);
    const sendUser = async (e) =>{
        e.preventDefault();
        console.log(formData);
        if (formData.password !== formData.password_repeat) {
            toast.error('Please check passwords, are different');
            return;
        }
        fetch('http://127.0.0.1:5000/api/v6/registro/admin/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error( Error.message || 'Server responded with an error.');
                }
            })
            .then(data =>{
                toast.success("Save user Account successfully!", data.message);
                fetchData()
            })
            .catch(error => {
                console.error(error);
                toast.error('Could not save')
            });
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openC}
                onClose={handleCloseCreate}
                closeAfterTransition
            >
                <Fade in={openC}>
                    <Box sx={style}>
                            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex justify-end px-4 pt-4">
                                    <button
                                        id="dropdownButton"
                                        onClick={handleCloseCreate}
                                        className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                        type="button"
                                    >
                                        <span className="sr-only">Open dropdown</span>
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6 18 17.94 6M18 18 6.06 6"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex flex-col items-center pb-10 space-y-2">
                                    <h1 className={"font-semibold text-3xl py-4"}>Add new User</h1>
                                    <form className="w-full px-8" onSubmit={sendUser}>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <label htmlFor="floating_email"
                                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email
                                                address</label>
                                            <input type="email" name="email" onChange={(e) => {
                                                handleInputChange(e)
                                            }} id="floating_email"
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                   placeholder="doe@gmial.com" required/>

                                        </div>
                                        <div className="grid md:grid-cols-2 md:gap-6">
                                            <div className="relative z-0 w-full mb-5 group">
                                                <label htmlFor="floating_first_name"
                                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                                                    name</label>
                                                <input type="text" name="name" value={first_name} onChange={(e) => {
                                                    handleInputChange(e)
                                                    handle(e);
                                                }} id="floating_first_name"
                                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="Johnathan" required/>

                                            </div>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <label htmlFor="floating_last_name"
                                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                                                    name</label>
                                                <input type="text" name="lastname" value={last_name} onChange={(e) => {
                                                    handleInputChange(e)
                                                    handle(e);
                                                }} id="floating_last_name"
                                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="Pearson" required/>

                                            </div>
                                        </div>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <label htmlFor="floating_phone"
                                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone
                                                number</label>
                                            <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone_number"
                                                   value={phone}
                                                   onChange={(e) => {
                                                       handleInputChange(e)
                                                       handle(e);
                                                   }}
                                                   id="floating_phone"
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                   placeholder="123-456-7890" required/>

                                        </div>
                                        <div>
                                            <div className="mb-6">
                                                <label
                                                    htmlFor="21_password"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    id="21_password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    minLength={8}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="*********"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-6">
                                                <label
                                                    htmlFor="21_password_repeat"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    id="21_password_repeat"
                                                    name="password_repeat"
                                                    value={formData.password_repeat}
                                                    onChange={handleInputChange}
                                                    minLength={8}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="*********"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="relative z-0 w-full mb-5 group">
                                            <select
                                                id="underline_select_parent"
                                                name="parents_id"
                                                value={formData.parents_id}
                                                onChange={(e) => {
                                                    handleInputChange(e);
                                                }}
                                                className="block py-2.5 px-0 w-full text-base text-gray-800 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                            >
                                                <option value="0">Company</option>
                                                {Object.entries(parents).map(([code, name]) => (
                                                    <option key={code} value={code}
                                                            className={"text-gray-900"}>{name}</option>
                                                ))}

                                            </select>

                                        </div>
                                        <nav className="text-center space-x-0 sm:space-x-9 py-4">
                                            <button type="submit"
                                                    className="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create
                                            </button>
                                            <button type="reset" onClick={handleCloseCreate}
                                                    className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                                            </button>
                                        </nav>
                                    </form>
                                </div>
                            </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export {ModalAdmin, CreateModal, UpdateModal, CreateModalAdmin};
