import React from 'react';
import Modal from 'react-modal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Link} from "react-router-dom";

Modal.setAppElement('#root');
const CookieModal = ({modalIsOpen, closeModal}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '9999',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: '9998',
                    }
                }}
                contentLabel="Cookie Policy Modal"
            >
                <div className="relative bg-white rounded-lg dark:bg-gray-700 w-full mx-auto">
                    {/*// <!-- Modal header -->*/}
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <div className="h-14 px-8"><img src="/MoneSureLogo.png" alt="" className="object-cover h-full"/></div>
                        <h3 className="text-3xl gradient-text-green font-bold text-gray-900 dark:text-white">
                            Cookies Policy
                        </h3>
                        <button type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/*<!-- Modal body -->*/}
                    <div className="max-w-7xl mx-auto max-h-dvh">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{width: '33%', flexShrink: 0}}>
                                    <span className="font-bold text-lg">Introduction</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p className="font-semibold px-8 "> Welcome to Monesure ("we," "our," "us"). This
                                        Cookies Policy explains how we use cookies and similar technologies to recognize
                                        you when you visit our website ("Site"). It explains what these technologies
                                        are, why we use them, and your rights to control our use of them.
                                        What Are Cookies?
                                    </p>
                                    <p className="px-8 text-sm">
                                        Cookies are small data files that are placed on your computer or mobile device
                                        when you visit a website. Cookies are widely used by website owners to make
                                        their websites work or to work more efficiently, as well as to provide reporting
                                        information.
                                        Why Do We Use Cookies?
                                    </p>
                                    <p className="px-8 text-sm">
                                        We use cookies for several reasons. Some cookies are required for technical
                                        reasons in order for our Site to operate, and we refer to these as "essential"
                                        or "strictly necessary" cookies. Other cookies enable us to track and target the
                                        interests of our users to enhance the experience on our Site. Third parties
                                        serve cookies through our Site for advertising, analytics, and other purposes.
                                        This is described in more detail below.
                                        Types of Cookies We Use
                                    </p>
                                    <p className="px-8 text-sm">
                                        Essential Cookies: These cookies are strictly necessary to provide you with
                                        services available through our Site and to use some of its features, such as
                                        access to secure areas.
                                    </p>
                                    <p className=" px-8 text-sm">
                                        Performance and Functionality Cookies: These cookies are used to enhance the
                                        performance and functionality of our Site but are non-essential to its use.
                                        However, without these cookies, certain functionality may become unavailable.
                                    </p>
                                    <p className=" px-8 text-sm">
                                        Analytics and Customization Cookies: These cookies collect information that is
                                        used either in aggregate form to help us understand how our Site is being used
                                        or how effective our marketing campaigns are, or to help us customize our Site
                                        for you.
                                    </p>
                                    <p className=" px-8 text-sm">
                                        Advertising Cookies: These cookies are used to make advertising messages more
                                        relevant to you. They perform functions like preventing the same ad from
                                        continuously reappearing, ensuring that ads are properly displayed, and in some
                                        cases, selecting advertisements that are based on your interests.
                                    </p>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography sx={{width: '80%', flexShrink: 0}}><span className="font-bold text-lg">How Can I Control Cookies?</span></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p className="font-semibold p-2 px-8"> You have the right to decide whether to
                                        accept or reject cookies. You can exercise your cookie preferences by clicking
                                        on the appropriate opt-out links provided in the cookies banner.

                                    </p>
                                    <p className="p-2 px-8">
                                        Browser Controls: You can set or amend your web browser controls to accept or
                                        refuse cookies. If you choose to reject cookies, you may still use our Site,
                                        though your access to some functionality and areas of our Site may be
                                        restricted. As the means by which you can refuse cookies through your web
                                        browser controls vary from browser to browser, you should visit your browser's
                                        help menu for more information.

                                    </p>
                                    <p className="p-2 px-8">
                                        Opt-Out Mechanisms: You can opt out of interest-based advertising served by
                                        participating ad servers through the Digital Advertising Alliance's opt-out page.
                                    </p>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography sx={{width: '80%', flexShrink: 0}}>
                                    <span className="font-bold text-lg">Changes to This Cookies Policy</span>
                                </Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p className="font-semibold p-2 px-8">
                                        We may update this Cookies Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please re-visit this Cookies Policy regularly to stay informed about our use of cookies and related technologies.
                                    </p>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography sx={{width: '80%', flexShrink: 0}}>
                                    <span className="font-bold text-lg">More Information</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p className=" p-2 px-8">
                                        If you have any questions about our use of cookies or other technologies,
                                        please email us at info@MoneSure.com or by post to:
                                       <Link to="/" className="underline">Monesure</Link>
                                    </p>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    {/*!-- Modal footer -->*/}
                    <div
                        className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 w-full">
                        <button onClick={closeModal} type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ">I
                            accept
                        </button>
                        <button onClick={closeModal} type="button"
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CookieModal;