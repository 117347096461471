import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import UpdatePaymentForm from "../../Components/Register/forms/updatePaymentForm";
const UpdatePayment = () => {
    return(
        <>

            <SideBar /><Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <UpdatePaymentForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default UpdatePayment;