import React, {useState} from 'react';
import {useAuth} from "../../context/AuthContext";
import {Link} from 'react-router-dom';
import HomeIcon from "@mui/icons-material/Home";
import toast, { Toaster } from 'react-hot-toast';


const Login = ({onlogin}) =>{
    const { login } = useAuth();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const LoggedIn = () => {
        return fetch('http://127.0.0.1:5000/api/v6/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok){
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.error || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                if (data.access_token) {
                    login(data.access_token, data.user);
                    console.log(data)
                    onlogin();
                } else {
                    throw new Error(data.message || 'Failed to log in');
                }
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Failed to log in');
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await toast.promise(
                LoggedIn(),
                {
                    loading: 'Logging in...',
                    success: <b>Logged in successfully!</b>,
                    error: <b>Failed to log in.</b>,
                }
            );
        } catch (error) {
            console.error('Error:', error);
            toast.error('Verify your credentials.');
        }
    };
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <section className={"w-full h-screen flex justify-center"}>
                <nav className="w-full sm:w-1/4 gradient-card p-4 flex-col justify-center content-center">
                    <div className="fixed top-12 left-8 flex justify-center">
                        <Link to={"/"} className="flex justify-center content-center"><HomeIcon
                            sx={{color: '#ffffff', width: 31, height: 31,}}
                        /><span className="text-white text-2xl">Home</span></Link>
                    </div>
                    <h1 className="font-bold text-white text-4xl sm:text-4xl w-full text-center p-8">Hi, Welcome
                        Back!!</h1>
                    <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
                        <div className="mb-5">
                            <label htmlFor="email"
                                   className="block mb-2 text-lg font-medium text-white dark:text-gray-800">Email
                                Address</label>
                            <input type="email" id="email_login" name="email" onChange={(e) => {
                                handleInputChange(e)
                            }}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                   placeholder="name@example.com" required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password"
                                   className="block mb-2 text-lg font-medium text-white  dark:text-gray-800">Password</label>
                            <input type="password" id="password" name="password" onChange={(e) => {
                                handleInputChange(e)
                            }}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                   required placeholder="*********"/>
                        </div>
                        <div className="text-center p-2 space-x-0 sm:space-x-4">
                            <button type="submit"
                                    className="text-white mb-4 bg-card2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Log In
                            </button>
                            <button type="reset"
                                    className="text-white bg-gray-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                            </button>
                        </div>
                    </form>
                    <div className='flex flex-col mt-8'>
                        <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                            <p className="text-sm font-semibold text-white lg:text-center md:text-center sm:text-center px-2">New User?</p><Link to="/register">
                            <button className="w-full text-start text-green-300 font-bold underline text-sm">Create an account
                            </button>
                        </Link>
                        </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                                <p className="text-sm font-semibold text-white lg:text-end md:text-center sm:text-center px-2">Forgot your password?</p><Link
                                to="/recovery_pass">
                                <button className="w-full text-start text-green-300 font-bold underline text-sm">Reset Password
                                </button>
                            </Link>
                            </div>
                        </div>
                </nav>
                <nav className="w-3/4 hidden sm:flex p-4">
                    <div className="w-full grid grid-cols-1 justify-items-center content-center">
                        <nav className="w-1/2">
                            <dotlottie-player
                                src="https://lottie.host/9213cebc-a62c-4d69-b446-48411cc61ee4/2IAzQrVkKw.json"
                                background="transparent" speed="1" loop=""
                                autoplay=""></dotlottie-player>
                        </nav>

                    </div>
                    <div className="fixed right-12 top-12 flex justify-center items-center">
                        <Link to="/"> <img src="/MoneSureLogoG.png" alt="" className="w-fit h-12"/></Link>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Login;