import React, {useEffect, useState} from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/Admin/sidebarAdmin";
import Navigation from "../../Components/Dashboard/GenericInAll/Admin/navigationAdmin";
import FunctionsParents from "../../Components/Dashboard/AdminComponents/functions_parents";
import axios from "axios";

const ManageFunction = () => {
    const [relation, setRelation] = useState([]);
    useEffect(() => {
        FetchData();
    }, []);

    const FetchData = async () => {
        try {
            const response = await axios.get('http://127.0.0.1:5000/api/v6/relations/function_parents');
            const jsonData = JSON.parse(JSON.stringify(response.data));
            setRelation(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return(
        <>
            <SideBar /><Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 md:pl-72 py-2">
                        <FunctionsParents Relation={relation}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ManageFunction