import React from 'react';
import {useNavigate} from 'react-router-dom';
import Login from '../Components/Register/login/Login';

const LoginPage = () => {
    const navigate = useNavigate();
    const handleLogin = () => {
        setTimeout(() =>{
            navigate('/profile')
        },[2000])
    };

    return(
        <>
            <Login onlogin={handleLogin}/>
        </>
    )
}

export default LoginPage