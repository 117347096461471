import React from 'react';
import {Link} from "react-router-dom";

const Start =()=>{
    return(
        <>
            <div className="fullscreen-bg relative h-96">
                <div className="relative z-10 p-24 grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center content-centers mx-2 sm:mx-8-0">
                    <nav className="text-white ml-2 sm:ml-28">
                        <h1 className="text-5xl font-bold text-white ">LET'S <span className="text-card2">START</span> NOW</h1>
                        <p className="font-semibold">At MoneSure, our cloud-based platform offers next-generation solutions that reduce risk, increase sales, and enhance credibility for our members worldwide. From fraud protection to loss mitigation, we’ve got you covered. Join us and unlock the potential of your business with MoneSure.</p>
                    </nav>
                    <Link to="/contact" className={"content-center"}><button className="bg-card2 rounded-full py-2"><p className={"text-white font-semibold mx-8"}>GET STARTED</p></button></Link>
                </div>
                <div className="absolute inset-0 bg-gradient-to-br from-black from-70% to-card2 opacity-75 z-0"></div>
            </div>

        </>
    )
}

export default Start;