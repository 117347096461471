import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from "../../context/AuthContext";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import BorderOuterRoundedIcon from '@mui/icons-material/BorderOuterRounded';

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const SideBar = document.getElementById('default-sidebar');
        SideBar.setAttribute('aria-expanded', isOpen.toString());
    }, [isOpen]);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false)
    const handleOpen = () => {
        setOpen2(!open2);
    }
    const handleClick = () => {
        setOpen(!open);
    };
    const {logout, isAdmin, shippingAccess,
        merchantAccess,
        checkingAccess,
        totalAccess} = useAuth();
    const handleLogout = () =>{
        logout()
    }
    return(
        <>
            <section className="sm:flex">`
                <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" onClick={toggleSidebar}
                        aria-controls="default-sidebar" type="button"
                        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd"
                              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <aside id="default-sidebar"
                       className={`fixed top-0 left-0 z-40 w-72 h-screen transition-transform ${
                           isOpen ? '' : '-translate-x-full sm:translate-x-0'
                       }`}
                       aria-label="Sidebar">
                    <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 ">
                        <Link to="/profile" className="flex items-center space-x-2 rtl:space-x-reverse">
                            <img src="/MoneSureLogoG.png" className="w-44" alt=" Logo"/>
                        </Link>
                        <ul className="space-y-4 font-medium p-4">
                            <li>
                                    <List
                                        sx={{width: '100%', maxWidth: 360}}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <Link to="/profile">
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <HomeIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Home"/>
                                            </ListItemButton>
                                        </Link>
                                        {merchantAccess && (
                                        <Link to="/profile/Merchant_Account">
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <AppRegistrationIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Merchant"/>
                                            </ListItemButton>
                                        </Link>
                                            )}
                                        {totalAccess && (
                                        <Link to="/profile/activities">
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <LocalActivityRoundedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Activity"/>
                                            </ListItemButton>
                                        </Link>
                                        )}
                                        {shippingAccess && (
                                        <ListItemButton onClick={handleClick}>
                                            <ListItemIcon>
                                                <DashboardIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Shipping"/>
                                            {open ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItemButton>
                                        )}
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {shippingAccess && (
                                                    <Link to="/profile/easyposts">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Shipments"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/shipping_address">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Address"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/shipping_insurances">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Insurances"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/trackers">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Tracker"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/parcels">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Parcels"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/batch">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Batches"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/carrierMetadata">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Carrier MetaData"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/custom">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Custom Information"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/customsitem">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Custom Items"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {shippingAccess && (
                                                    <Link to="/profile/endshipper">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="EndShipper"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                            </List>
                                        </Collapse>
                                        {checkingAccess && (
                                        <ListItemButton onClick={handleOpen}>
                                            <ListItemIcon>
                                                <AttachMoneyIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Digital Payments"/>
                                            {open2 ? <ExpandLess/> : <ExpandMore/>}
                                        </ListItemButton>
                                        )}
                                        <Collapse in={open2} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {checkingAccess && (
                                                    <Link to="/profile/banksaccounts">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <BackupTableIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Bank Accounts"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/checkssuing">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Digital Payments"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {merchantAccess && (
                                                    <Link to="/profile/payments">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <BackupTableIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Payments"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/add_payments">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Add Payment"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/trackPayments">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Track Payment"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/updatePayments">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Update Payment"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/documents">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <BackupTableIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Documents"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                                {checkingAccess && (
                                                    <Link to="/profile/addDocuments">
                                                        <ListItemButton sx={{pl: 4}}>
                                                            <ListItemIcon>
                                                                <AddOutlinedIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Add Documents"/>
                                                        </ListItemButton>
                                                    </Link>
                                                )}
                                            </List>
                                        </Collapse>
                                        {isAdmin && (
                                            <Link to="/profile/dash" style={{textDecoration: 'none'}}>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <BorderOuterRoundedIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Dashboard"/>
                                                </ListItemButton>
                                            </Link>
                                        )}
                                    </List>
                            </li>
                        </ul>
                        <div
                            className="flex justify-center fixed bottom-0  left-0 z-40 w-72 rounded-tr-3xls bg-red-500 cursor-pointer"
                            onClick={handleLogout}>
                            <button className="flex font-bold text-sm text-white  p-2">
                                <svg className="w-4 h-5 font-bold text-white dark:text-white mr-4" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="3" d="M6 18 17.94 6M18 18 6.06 6"/>
                                </svg>
                                Sign Out
                            </button>
                        </div>
                    </div>
                </aside>
            </section>

        </>
    )
}

export default SideBar;