import React from 'react';
import Menu from "../Components/navbar/menu";
import Footer from "../Components/footer/footer";
import SliderTop from "../Components/about/slider_top";
import Business from "../Components/about/business_services";
import Start from "../Components/about/background_start";
const About =()=>{

    return(
        <>
            <Menu />
            <SliderTop />
            <Business/>
            <Start />
            <Footer />
        </>
    );
}

export default About;