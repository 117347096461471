import React from 'react';

const MarketingProviding = () => {
    return(
        <>
            <section className="bg-gradR w-full h-fit sm:h-auto content-center">
                <nav className="grid justify-items-center h-fit text-center py-8">
                    <h1 className="font-bold text-3xl sm:text-5xl"><span className="text-primaryV font-bold ">One Platform</span> Providing Solutions</h1>
                </nav>
                <nav className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center py-12 mx-12 sm:mx-56">
                    <div className="mb-8 sm:mb-2 bg-white h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-500 ease-in-out  hover:scale-110">
                        <div className="text-center grid justify-items-center py-8">
                            <svg className="w-16 h-16 text-primaryV dark:text-white text-center" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M12 15v5m-3 0h6M4 11h16M5 15h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1Z"/>
                            </svg>

                        </div>
                        <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8">System Evaluation</h1>
                        <p className="font-semibold p-10 text-md">We conduct in-depth interviews and assessments to
                            understand your
                            current systems and identify areas for improvement</p>
                    </div>
                    <div className="mb-8 sm:mb-2 bg-gradient-to-t from-card to-card2 h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-700 ease-in-out  hover:scale-110">
                        <div className="text-center grid justify-items-center py-8">
                            <svg className="w-12 h-12 text-white text-center" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                                      d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8 text-white">Local Reviews
                            and Search Optimization</h1>
                        <p className="font-semibold p-10 text-md text-white">"Utilizing advanced tools, we enhance your
                            local reviews
                            and search visibility, ensuring your business stands out.</p>
                    </div>
                    <div className="mb-8 sm:mb-2 bg-white h-fit w-auto sm:w-96 text-center rounded-3xl hover:scale-1 transition duration-700 ease-in-out  hover:scale-110">
                        <div className="text-center grid justify-items-center py-8">
                            <svg className="w-12 h-12 text-primaryV dark:text-white text-center" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                        </div>
                        <h1 className="font-bold text-xl sm:text-2xl p-1 sm:p-2 mx-1 sm:mx-8">Issue Identification and
                            Maintenance</h1>
                        <p className="font-semibold p-10 text-md">Our team fixes identified issues and provides ongoing
                            maintenance to keep your systems running efficiently</p>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default MarketingProviding;