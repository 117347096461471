import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import NavigationBar from "../../Components/Dashboard/GenericInAll/navigation";
import Parcel from '../../Components/Dashboard/FormsProfile/parcels'
const Parcels = () => {
    return(
        <>
            <SideBar /><NavigationBar />
            <div>
                <Parcel />
            </div>
        </>
    )
}

export default Parcels;