import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import EndShipper from "../../Components/Dashboard/FormsProfile/endShipper"
const EndShipperPage = () =>{
    return(
        <>
            <SideBar /><Navigation/>
            <EndShipper />
        </>
    )
}

export default EndShipperPage;