import React from 'react';
import TreackerForm from '../../Register/forms/TrackerForms'

const Tracker = () => {
    return (
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <TreackerForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tracker;