import React from 'react';
import {Link} from 'react-router-dom';
const ServicesMerchant = () => {
    return(
        <>

            <section className="bg-gradient-to-l from-gradR to-gradL w-full h-fit content-center grid justify-items-center">
                <h1 className="font-semibold text-4xl mt-8 sm:mt-16"><span
                    className="text-primaryV font-semibold">More </span><span className="gradient-text">Mone</span><span
                    className="gradient-text-green">Sure</span> Solution</h1>
                <p className="text-lg font-semibold p-4">Some Of The Key Features Of Our Platform Include</p>
                <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  justify-items-center">
                    <nav className="border-2 border-gray-300 w-auto bg-white sm:w-96 cursor-pointer transition-colors duration-700 ease-in-out hover:text-white hover:bg-gradient-to-t hover:from-oscuro hover:to-card2">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">Invoicing</h1>
                        <p className="text-sm p-6 text-start">Send invoices in seconds via text, email, social apps in
                            one click. 80% of payers prefer this fast secure payment methods. With integrations with 200
                            processors.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                      d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 5h6m-6 4h6M10 3v4h4V3h-4Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 transition-colors duration-700 ease-in-out hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">MoneSure Embedded Finance</h1>
                        <p className="text-sm p-6 text-start">As an approved member you can buy and sell using our
                            capital,
                            sell on credit without risk and get paid same day and receive working capital from a variety
                            of sources and programs.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">MoneSure Embedded Integrations</h1>
                        <p className="text-sm p-6 text-start">With MoneSure you can take advantage of thousands
                            integrations
                            to do just about anything.</p>
                        <div className="p-4">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                            </svg>

                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">MoneSure Same Day On-Demand
                            Delivery</h1>
                        <p className="text-sm p-6 text-start">If it is possible, you can use our logistics managers to
                            arrange same day delivery and secure delivery anywhere.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M4.37 7.657c2.063.528 2.396 2.806 3.202 3.87 1.07 1.413 2.075 1.228 3.192 2.644 1.805 2.289 1.312 5.705 1.312 6.705M20 15h-1a4 4 0 0 0-4 4v1M8.587 3.992c0 .822.112 1.886 1.515 2.58 1.402.693 2.918.351 2.918 2.334 0 .276 0 2.008 1.972 2.008 2.026.031 2.026-1.678 2.026-2.008 0-.65.527-.9 1.177-.9H20M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">MoneSure ECOMMS Secure Advantage</h1>
                        <p className="text-sm p-6 text-start">For transactions that demand ultra secure delivery you can
                            have our fraud prevention department customize a delivery plan for your. </p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1"
                                      d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"/>
                            </svg>

                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">MoneSure Ad Manager</h1>
                        <p className="text-sm p-6 text-start">Increase your sales by increasing your marketing
                            channels.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                            </svg>
                        </div>
                    </nav>
                </div>
                <Link to="/service" className="flex justify-center py-8">
                    <p className="font-bold text-lg">View All Services</p>
                    <button className="bg-primaryV rounded-full mx-4">
                        <svg className="w-8 h-8 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="m7 16 4-4-4-4m6 8 4-4-4-4"/>
                        </svg>
                    </button>
                </Link>
            </section>
        </>
    );
}

export default ServicesMerchant;