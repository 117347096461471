import React, {useEffect, useRef, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: 'auto',
};


const NumberToWords = (number) => {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convertToWords = (num) => {
        if (num < 10) return ones[num];
        if (num >= 10 && num < 20) return teens[num - 10];
        if (num >= 20 && num < 100) return tens[Math.floor(num / 10)] + ' ' + ones[num % 10];
        if (num >= 100 && num < 1000) return ones[Math.floor(num / 100)] + ' Hundred ' + convertToWords(num % 100);
        if (num >= 1000 && num < 1000000) return convertToWords(Math.floor(num / 1000)) + ' Thousand ' + convertToWords(num % 1000);
        if (num >= 1000000 && num < 1000000000) return convertToWords(Math.floor(num / 1000000)) + ' Million ' + convertToWords(num % 1000000);
        if (num >= 1000000000) return convertToWords(Math.floor(num / 1000000000)) + ' Billion ' + convertToWords(num % 1000000000);
    };

    if (number === 0) return 'Zero';
    if (number < 0) return 'Negative ' + convertToWords(Math.abs(number));

    const numArr = number.toString().split('.');
    const dollars = parseInt(numArr[0], 10);
    const cents = parseInt(numArr[1], 10) || 0;

    let words = convertToWords(dollars) + ' Dollars';
    if (cents > 0) {
        words += ' and ' + (cents < 10 ? '0' + cents : cents) + '/100';
    } else {
        words += ' and 00/100';
    }

    return words;
};
const CreateModal = ({openM, handleCloseModal, selectAccountChecks }) => {
    const [fechaActual, setFechaActual] = useState('');
    const [amountInWords, setAmountInWords] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [amount, setAmount] = useState(0);
    const [inputSelect, setInputSelect] = useState('');
    useEffect(() => {
        if (selectAccountChecks) {
            const interval = setInterval(() => {
                const now = new Date();
                const formattedDate = `${now.toLocaleDateString()}`;
                setFechaActual(formattedDate);
            }, 1000);

            // Calculate amountInWords only if selectAccount.sec_sig_threshold is defined
            if (selectAccountChecks.sec_sig_threshold !== undefined) {
                const words = NumberToWords(selectAccountChecks.sec_sig_threshold);
                setAmountInWords(words);
            }

            return () => {
                clearInterval(interval);
            };
        }
    }, [selectAccountChecks]);
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }; 
    const handleInputChangeSelect = (e) => {
        setInputSelect(e.target.value);
    };
    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            setAmount(value);
        }
    };

    const handlePrintPDF = async () => {
        const input = document.getElementById('content-to-print');
        const canvas = await html2canvas(input);

        // Ajustes para el PDF
        const pdf = new jsPDF('p', 'pt', 'letter');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const ratio = canvas.height / canvas.width;

        let width = pageWidth * 0.85;
        let height = width * ratio;

        if (height > pageHeight * 0.85) {
            height = pageHeight * 0.85;
            width = height / ratio;
        }

        const x = (pageWidth - width) / 2;
        const y = 30;

        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', x, y, width, height);

        pdf.save('cheque.pdf');
    };
    const signatureRef = useRef();

    const clearSignature = () => {
        signatureRef.current.clear();
    };

    return(
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openM}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={openM}>
                        <Box sx={style}>
                            {selectAccountChecks && (
                                <>
                                    <div
                                        className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex justify-between px-4 pt-2">
                                            <button id="dropdownButton" onClick={handleCloseModal}
                                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                                    type="button">
                                                <span className="sr-only">Open dropdown</span>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1"
                                                          d="M6 18 17.94 6M18 18 6.06 6"/>
                                                </svg>
                                            </button>
                                            <button className="action_has has_saved" aria-label="save" type="button" onClick={handlePrintPDF}>
                                                <svg
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    strokeLinejoin="round"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="m19,21H5c-1.1,0-2-.9-2-2V5c0-1.1.9-2,2-2h11l5,5v11c0,1.1-.9,2-2,2Z"
                                                        strokeLinejoin="round"
                                                        strokeLinecap="round"
                                                        data-path="box"
                                                    ></path>
                                                    <path
                                                        d="M7 3L7 8L15 8"
                                                        strokeLinejoin="round"
                                                        strokeLinecap="round"
                                                        data-path="line-top"
                                                    ></path>
                                                    <path
                                                        d="M17 20L17 13L7 13L7 20"
                                                        strokeLinejoin="round"
                                                        strokeLinecap="round"
                                                        data-path="line-bottom"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <section
                                            className={"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 w-full py-8 "}>
                                            <form className={"w-full sm:w-auto p-4 sm:col-span-1"}>
                                                <div className="relative">
                                                    <label htmlFor="floating_name"
                                                           className="block font-medium text-gray-900 dark:text-white w-fit bg-gray-200 rounded-t-lg">
                                                        <svg className="w-5 h-5 text-gray-800 dark:text-white"
                                                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                             width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" strokeWidth="2"
                                                                  d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                                        </svg>
                                                    </label>
                                                    <input type="text" id="floating_name" onChange={handleInputChange}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-b-lg rounded-tr-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                           placeholder="Payee Name" required/>
                                                </div>
                                                <div className="relative py-2 flex">
                                                    <nav>
                                                        <label htmlFor="floating_amount"
                                                               className="block font-medium text-gray-900 dark:text-white w-fit bg-gray-200 rounded-t-lg">
                                                            <svg className="w-5 h-5 text-gray-800 dark:text-white  "
                                                                 aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                <path stroke="currentColor" strokeLinecap="round"
                                                                      strokeLinejoin="round" strokeWidth="2"
                                                                      d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                                                            </svg>

                                                        </label>
                                                        <input type="text" id="floating_amount"
                                                               onChange={handleAmountChange}
                                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-b-lg rounded-tr-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                               placeholder="Amount" required/>
                                                    </nav>
                                                    <nav
                                                        className="flex justify-center items-center content-center px-2">
                                                        <select id="underline_selects"
                                                                onChange={handleInputChangeSelect}
                                                                className="block p-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                            <option value="NaN">Select</option>
                                                            <option value="USD">USD</option>
                                                            <option value="CAD">CAD</option>
                                                        </select>
                                                    </nav>
                                                </div>
                                            </form>

                                            <div className="flex flex-col items-center p-4 w-full col-span-3">
                                                <div id="content-to-print"
                                                     className="w-full h-80 border border-gray-500 rounded-md p-8 relative content-check ">
                                                    <nav className={"flex space-x-2"}>
                                                        <div className={"text-gray-500"}><img
                                                            src="/MoneSureLogo.png" alt="" className={"w-12 h-auto"}/>
                                                        </div>
                                                        <div className={"text-sm flex flex-col"}>
                                                            <h1>{selectAccountChecks.name}</h1>
                                                            <h1>{selectAccountChecks.descrip}</h1>
                                                        </div>
                                                    </nav>
                                                    <div className="mb-4 w-full mt-10 flex justify-between">
                                                        <h1 className={"text-lg text-center text-gray-400"}>Pay
                                                            to the order of: </h1>
                                                        <p className={"text-start"}>{inputValue}</p>
                                                        <div
                                                            className={"flex justify-center items-center space-x-4"}>
                                                            <div className={"flex"}>
                                                                <span
                                                                    className="font-medium text-base text-gray-500 mr-3">$ </span>
                                                                <h1>{formatNumberWithCommas(amount.toFixed(2))}</h1>
                                                            </div>
                                                            <p className={"text-start text-xs"}>{inputSelect}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"text-sm flex justify-between "}>
                                                        <p className={"text-base text-gray-400"}>{amountInWords}</p>
                                                    </div>
                                                    <div
                                                        className="text-center grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-16 w-full">
                                                        <dt className={"flex flex-col justify-center mt-16"}>
                                                            <p className={"border border-b-gray-400 border-t-white border-l-white "}></p>
                                                            <h1>Memo</h1>
                                                        </dt>
                                                        <dt className={"flex flex-col text-center justify-center items-center"}>
                                                            <SignatureCanvas
                                                                ref={signatureRef}
                                                                penColor="black"
                                                                canvasProps={{
                                                                    width: 340,
                                                                    height: 70,
                                                                    className: 'signature-canvas'
                                                                }}
                                                            />
                                                            <nav className={"w-full"}>
                                                                <p className={"border border-b-gray-400 w-full border-t-white border-l-white"}></p>
                                                                <h1>Signature</h1>
                                                            </nav>
                                                        </dt>
                                                    </div>
                                                    <div className={"w-full flex justify-center items-center mt-4"}>
                                                        <h1 className={"text-sm text-center text-gray-400"}>
                                                            Printed check background is different due to check stock
                                                            security measures.
                                                        </h1>
                                                    </div>

                                                    <div className="absolute top-10 right-10 text-gray-500">
                                                        <div className="flex justify-end items-end">
                                                            {selectAccountChecks.starting_check_num}
                                                        </div>
                                                        <span className="font-bold">Date:</span> {fechaActual}
                                                    </div>
                                                </div>
                                            </div>
                                            <dt className={"absolute bottom-10 left-10 flex justify-center space-x-2"}>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="2"
                                                          d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                                </svg>

                                                <button
                                                    onClick={clearSignature}>Clear Signature
                                                </button>
                                            </dt>

                                        </section>
                                    </div>

                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
export default CreateModal;

