import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import NavigationBar from "../../Components/Dashboard/GenericInAll/navigation";
import Ship from "../../Components/Dashboard/FormsProfile/ship"
const Shipping = () => {
    return(
        <>

            <SideBar /><NavigationBar />
            <div className="">
            <Ship />
            </div>

        </>
    );
}

export default Shipping;