import React from 'react';
import ShippingForms from "../../Register/forms/shipping_formuser";

const Main = () => {

    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                        <ShippingForms />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main