import React, {useEffect, useState} from 'react';
import useVisibility from "../../../JS/Lazylow";

const CarrierMetadata = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const [formData, setFormData] = useState({
        carriers: '',
        user_profile_id: ''
    });
    const [items, setItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [buttonValue, setButtonValue] = useState('Send Information');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const input = document.getElementById("floating_types");
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && inputValue.trim() !== '') {
                e.preventDefault();
                setItems([...items, inputValue.trim()]);
                setInputValue('');
            }
        };
        input.addEventListener("keydown", handleKeyDown);
        return () => {
            input.removeEventListener("keydown", handleKeyDown);
        };
    }, [inputValue, items]);

    const deleteLastItem = () => {
        if (items.length > 0) {
            const updatedItems = [...items];
            updatedItems.pop();
            setItems(updatedItems);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formDataToSend = {
            ...formData,
            types: items,
            user_profile_id: storedUser.id
        };
        console.log(formDataToSend)
        fetch('http://127.0.0.1:5000/api/v5/carrier_metadata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataToSend)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                console.log(data)
                setIsLoading(false)
                setButtonValue('Send Information');
                const alert = document.getElementById('alertaS');
                alert.classList.remove('hidden');
                alert.classList.add('flex');
                setTimeout(() => {
                    alert.classList.add('hidden');
                    alert.classList.remove('flex');
                }, 3000);
            })
            .catch((err) => {
                setIsLoading(false)
                setButtonValue('Send Information');
                const alertaErr = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaErr.classList.remove('hidden');
                alertaErr.classList.add('flex');

                text.innerText = 'An error occurred: ' + err.message;
                setTimeout(() => {
                    alertaErr.classList.add('hidden');
                    alertaErr.classList.remove('flex');
                }, 3000);
            })
    };

    return(
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-blue-50 rounded-xl border-t-8 border-blue-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Retrieve Carrier Metadata</h1>
                    <dt className="flex justify-center content-center">
                        <p className={"py-2 text-md text-gray-400 flex justify-center content-center"}>Here you can
                            see the Metadata</p>
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div className="flex justify-center items-center">
                                    <svg data-popover-target="chart-info" data-popover-placement="bottom"
                                         className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ms-1"
                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"/>
                                    </svg>
                                    <div data-popover="" id="chart-info" role="tooltip"
                                         className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                        <h1 className={"flex justify-center font-bold text-center mt-6 mb-2"}>Dictionary</h1>
                                        <div className="p-3 space-y-2">
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Carriers</h1>
                                            <p>Carriers you'd like to filter the response by.</p>
                                            <h1 className="font-semibold text-gray-900 dark:text-white">Types</h1>
                                            <p>Types you'd like to filter the response by. [service_levels,
                                                predefined_packages]</p>
                                        </div>
                                        <div data-popper-arrow=""></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dt>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Shipment address was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="w-fit flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p></span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24" onSubmit={handleSubmit}>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Carrier Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <label htmlFor="underline_select_carrier" className="sr-only">Carrier select</label>
                                <select id="underline_select_carrier" name="carriers" onChange={(e) => {
                                    handleChange(e)
                                }}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                    <option value="option1" disabled selected>Choose a carrier</option>
                                    <option value="usps">USPS</option>
                                    <option value="fedex">FEDEX</option>
                                    <option value="upss">UPS</option>
                                </select>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="types" id="floating_types"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " value={inputValue}
                                       onChange={(e) => {
                                           setInputValue(e.target.value);
                                           handleChange(e)
                                       }}/>
                                <label htmlFor="floating_types"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Types</label>
                                <div id="delete-button-container" className="flex justify-end items-end">
                                    <button type={"button"}
                                            className="text-gray-800 dark:bg-gray-700 dark:text-white p-4 rounded-md text-end"
                                            onClick={deleteLastItem}
                                            disabled={items.length === 0}>
                                        <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div id="types-list">
                                    <h1 className={"flex justify-center items-center font-bold text-lg mb-4"}>Types
                                        List</h1>
                                    <dt className="flex justify-center items-center text-lg space-x-2">
                                        {items.map((item, index) => (
                                            <div key={index} className="relatives flex  items-center">
                                                <div className="py-1 px-3 rounded bg-gray-100 text-gray-700">
                                                    {item}
                                                </div>
                                            </div>
                                        ))}
                                    </dt>

                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-12 group">
                        <button type="submit" disabled={isLoading}
                                className=" text-gray-200 bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-2/3 px-5 sm:px-24 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{isLoading ? (
                            <div role="status" className="">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            buttonValue
                        )}
                        </button>
                    </div>
                </form>
            </section>

        </>
    )
}
export default CarrierMetadata