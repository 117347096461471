import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import NavigationBar from "../../Components/Dashboard/GenericInAll/navigation";
import Checking from "../../Components/Dashboard/FormsProfile/chekings";
const Checks = () => {
    return(
        <>
            <SideBar/><NavigationBar/>
            <div>
                <Checking />
            </div>
        </>
    )
}

export default Checks;