import React from 'react';
import Menu from '../Components/navbar/menu'
import Footer from '../Components/footer/footer'
import Desc from '../Components/Pricing/desc'
import List from '../Components/Pricing/list_pricing'

const Pricing = () =>{
    return(
        <>
            <Menu />
            <Desc />
            <List />
            <Footer />
        </>
    );
}

export default Pricing;