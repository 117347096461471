import React, {useEffect, useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TextField} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";

const AddPayments = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const [country, setCountry] = useState("");
    const [selectedDate] = useState(null);
    const [selectedDats] = useState(null);
    const [formData, setFormData] = useState({
        payee: '',
        payee_first: '',
        payee_last: '',
        address: '',
        amount: '',
        currency: '',
        send_date: '',
        memo: '',
        reference: '',
        check_num: '',
        postage_id: '',
        funding_source: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        origin_country: '',
        stub_details_header: '',
        stub_details: '',
        check_date: '',
        mail_to: '',
        taxid: '',
        trans_id: '',
        phone: '',
        digital_check_pass: '',
        method_id: '',
        account_type: '',
        account_number: '',
        routing_num: '',
        ach_info_threshold: '',
        status: 1,
        user_profile_id: ''
    });

    const handleAmountChange = (e) => {
        const amountValue = parseFloat(e.target.value).toFixed(2);
        setFormData({
            ...formData,
            amount: amountValue,
        });
    };

    const HandleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const DataFrom = {
            ...formData,
            user_profile_id: storedUser.id,
            method_id: parseInt(formData.method_id),
            amount: parseFloat(formData.amount).toFixed(2), // Asegurar que el monto sea decimal con 2 decimales
            send_date: dayjs(formData.send_date).format('YYYY-MM-DD'), // Formato de fecha YYYY-MM-DD
            check_date: dayjs(formData.check_date).format('YYYY-MM-DD') // Formato de fecha YYYY-MM-DD
        };

        console.log(DataFrom); // Verificar en la consola que los datos sean correctos antes de enviarlos

        fetch('http://127.0.0.1:5000/api/v3/add_payments', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(DataFrom)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || "Custom Info service is not available");
                    });
                }
            })
            .then(data => {
                console.log(data);
                const alertaSuc = document.getElementById('alertaS');
                alertaSuc.classList.remove('hidden');
                alertaSuc.classList.add('flex');
                setTimeout(() => {
                    alertaSuc.classList.add('hidden');
                    alertaSuc.classList.remove('flex');
                }, 3000);
            })
            .catch(error => {
                console.error('An error occurred:', error);
                const alertaErrors = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaErrors.classList.remove('hidden');
                alertaErrors.classList.add('flex');
                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaErrors.classList.add('hidden');
                    alertaErrors.classList.remove('flex');
                }, 3000);
            });
    };

    useEffect(() => {
        axios.get('http://localhost:5000/api/country_list')
            .then(response => {
                setCountry(response.data);
            })
            .catch(error => console.error('Error fetching:', error));
    }, []);

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            send_date: date // Actualizar send_date directamente con la fecha seleccionada
        });
    };

    const handleDateChangeS = (date) => {
        setFormData({
            ...formData,
            check_date: date // Actualizar check_date directamente con la fecha seleccionada
        });
    };

    return(
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Add Payment </h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can add a Payment</p>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Tracking was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p> </span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24" onSubmit={HandleSubmit}>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Generic Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="payee" id="floating_payee"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_payee"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Payee
                                    Name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="payee_first" id="floating_payee_first"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_payee_first"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                                    Payee</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="payee_last" id="floating_payee_last"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_payee_last"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last
                                    Payee</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="address" id="floating_address"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_address"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        HandleInputChange(e);
                                        handleAmountChange(e);
                                    }}
                                    name="amount"
                                    id="floating_amount"
                                    className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    required
                                />
                                <label htmlFor="floating_amount"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select id="underline_currency" name="currency" onChange={(e) => {
                                    HandleInputChange(e)
                                }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                    <option value="" selected={true} disabled>Choose Currency</option>
                                    <option value="USD">USD</option>
                                    <option value="CAD">CAD</option>
                                </select>
                            </div>
                        </div>
                    </nav>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Payments References</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <div className="relative z-40 w-full mb-5 group">
                                    <div className="relative max-w-3xl flex flex-col">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Set Date"
                                                name='send_date'
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                className="w-full rounded-3xl border-2"
                                                PopperProps={{
                                                    className: "custom-popper"
                                                }}
                                                inputProps={{
                                                    className: "custom-input"
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="memo" id="floating_memo"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_,memo"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Memo</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="reference" id="floating_reference"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_reference"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Payment
                                    References</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="check_num" id="floating_check_num"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_check_num"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Check
                                    Number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="postage_id" id="floating_postage_id"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_postage_id"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Payment
                                    Postage Id</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="funding_source" id="floating_funding_source"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_funding_source"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Founding
                                    Source</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="address2" id="floating_address2"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_address2"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Second
                                    Address</label>
                            </div>
                        </div>
                    </nav>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Details Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="city" id="floating_city"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_city"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">City</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="state" id="floating_State"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_State"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">State</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="zip" id="floating_zip"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_zip"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Zip</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select
                                    id="underline_select_country"
                                    name="origin_country"
                                    value={formData.country}
                                    onChange={(e) => {
                                        HandleInputChange(e);
                                    }}
                                    className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                    <option value="">Origin Country</option>
                                    {Object.entries(country).map(([code, name]) => (
                                        <option key={code} value={code}>{name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="stub_details_header" id="floating_stub_details_header"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_stub_details_header"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Details
                                    Header</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="stub_details" id="floating_stub_details"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_stub_details"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Stub
                                    Details</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <div className="relative z-40 w-full mb-5 group">
                                    <div className="relative max-w-3xl flex flex-col">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Set Date"
                                                name='check_date'
                                                value={selectedDats}
                                                onChange={handleDateChangeS}
                                                className="w-full rounded-3xl border-2"
                                                PopperProps={{
                                                    className: "custom-popper"
                                                }}
                                                inputProps={{
                                                    className: "custom-input"
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                        ref={refCard20}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Generic Information</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="mail_to" id="floating_mail_to"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_mail_to"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Mail
                                    To</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="taxid" id="floating_taxid"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_taxid"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Tax
                                    Id</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="trans_id" id="floating_trans_id"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_trans_id"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Transaction
                                    ID</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="phone" id="floating_phone"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_phone"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Mobile
                                    Phone Number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="digital_check_pass" id="floating_digital_check_pass"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_digital_check_pass"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Digital
                                    Check Password</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select id="underline_method_id" name="method_id" onChange={(e) => {
                                    HandleInputChange(e)
                                }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                    <option value="" selected={true} disabled>Choose a method</option>
                                    <option value="1">ACH</option>
                                    <option value="2">Checks</option>
                                </select>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select id="underline_account_type" name="account_type" onChange={(e) => {
                                    HandleInputChange(e)
                                }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                    <option value="" selected={true} disabled>Choose an account type</option>
                                    <option value="Checking">Checking</option>
                                    <option value="Savings">Savings</option>
                                    <option value="Business">Business</option>
                                </select>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="account_number" id="floating_account_number"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_account_number"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Account
                                    Number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="routing_num" id="floating_routing_num"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_routing_num"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Routing
                                    Number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" onChange={(e) => {
                                    HandleInputChange(e)
                                }} name="ach_info_threshold" id="floating_ach_info_threshold"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_ach_info_threshold"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password
                                    to Specify ACH</label>

                                <select id="underline_ach_info_threshold" name="ach_info_threshold" onChange={(e) => {
                                    HandleInputChange(e)
                                }}
                                        className="block py-2.5 px-0 w-full text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                    <option value="" selected={true} disabled>Choose an ach threshold</option>
                                    <option value="3mo">3mo</option>
                                    <option value="always">Always</option>
                                    <option value="never">Never</option>
                                </select>
                            </div>

                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-12 group">
                        <button type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base w-full sm:w-2/3 px-16 py-2 text-center">Send
                            Information
                        </button>
                    </div>
                </form>
            </section>

        </>
    )
}

export default AddPayments;