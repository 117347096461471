import React from 'react';
import {Link} from "react-router-dom";

const Announced = () => {
    return(
        <>
            <section className="bg-gray-900">
                <div className="content-start p-14">
                    <h1 className="font-bold text-4xl text-white"><span className="text-primaryV">Coming</span> Soon
                    </h1>
                    <p className="text-white mb-8">
                        Stay tuned for updates on our upcoming features. Our payment network is designed to be reliable and secure, offering comprehensive solutions across various financial needs.
                        We provide seamless integration for merchants, allowing for efficient payment processing and protection against chargebacks. Our advanced risk management and compliance measures ensure the highest level of security for your transactions.
                        Whether you’re handling shipping logistics, managing merchant accounts, or processing digital payments, our platform supports real-time processing to facilitate quick and smooth transactions.
                        From connecting with major banks to setting up secure payment systems, we aim to streamline your financial operations and provide robust support for your business growth.
                    </p>s
                    <Link to="/contact">
                        <button className="bg-primaryV rounded-full"><p className="text-white font-semibold p-2">Get in Touch</p></button>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default Announced;

