import React from 'react';
import CustomsItemsF from '../../Register/forms/Customsitems'
const CustomsItems = () => {
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                    <CustomsItemsF/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CustomsItems;