import React from 'react';
// import {useState, useEffect} from 'react'
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import Prev from "../../Components/Dashboard/HomeProfile/preview"
import CardCharts from "../../Components/Dashboard/HomeProfile/cards_wth_charts";
// import LineChart from "../../Components/Dashboard/HomeProfile/linescharts";
import Minetable from "../../Components/Dashboard/HomeProfile/maintable";

// import ComponentCharts from "../../Components/Dashboard/compo_charts";

const Profile = () => {

    return(
        <>
                    <SideBar/>
                    <div className="">
                        <Navigation/>
                    </div>
                    <section className="lg:pl-96 lg:pr-10 md:pl-72 md:pr-10 py-2">
                        <Prev/>
                        <CardCharts/>
                        {/*<LineChart/>*/}
                        <Minetable/>
                    </section>
        </>
)
}

export default Profile