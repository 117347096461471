import React from 'react';
import Menu from '../Components/navbar/menu';
import Footer from '../Components/footer/footer';
import Guidesinvideo from '../Components/Guides/guides_in_video';

const VideoGuides = () => {

    return (
        <>
            <Menu />
            <Guidesinvideo />
            <Footer />
        </>
    );
};

export default VideoGuides;
