import React, {useEffect, useState} from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import axios from "axios";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
];
const DashboardS = () => {
    const [total, setTotal] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const [activePercentage, setActivePercentage] = useState(0);
    const [inactivePercentage, setInactivePercentage] = useState(0);
    useEffect(() => {
        const fetchUserCounters = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:5000/api/v6/users/counters');
                const { active, inactive } = response.data;
                const total = active + inactive;
                const activePercent = (active / total) * 100;
                const inactivePercent = (inactive / total) * 100;
                setActivePercentage(activePercent)
                setInactivePercentage(inactivePercent)
                animateCounters(active, inactive, total);
            } catch (error) {
                console.error('Error fetching user counters:', error);
            }

        };
        fetchUserCounters();
    }, []);
    const animateCounters = (activeTarget, inactiveTarget, totalTarget) => {
        let currentActive = 0;
        let currentInactive = 0;
        let currentTotal = 0;

        const increment = () => {
            if (currentActive < activeTarget) {
                setActiveCount(++currentActive);
            }
            if (currentInactive < inactiveTarget) {
                setInactiveCount(++currentInactive);
            }
            if (currentTotal < totalTarget) {
                setTotal(++currentTotal);
            }

            if (currentActive < activeTarget || currentInactive < inactiveTarget || currentTotal < totalTarget) {
                setTimeout(increment, 100);
            }
        };

        increment();
    };
    return(
        <>
            <section
                className="w-full h-fit grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center gap-8 py-12 px-4">
                <div className={"grid grid-cols-2 bg-gray-100 rounded-2xl p-4 content-center"}>
                    <div className={"flex-col justify-start text-start  content-center p-8"}>
                        <h1 className="font-bold text-base ">Total Users</h1>
                        <p className="flex justify-start py-2"><KeyboardDoubleArrowUpIcon/><span>100 %</span></p>
                        <p className="font-bold text-2xl">{total}</p>
                    </div>
                    <div className={"flex justify-end text-end"}>
                        <ChartContainer
                            width={150}
                            height={150}
                            series={[{data: uData, label: 'uv', type: 'bar'}]}
                            xAxis={[{scaleType: 'band', data: xLabels}]}
                        >
                            <BarPlot/>
                        </ChartContainer>
                    </div>
                </div>
                <div className={"grid grid-cols-2 bg-gray-100 rounded-2xl p-4 content-center"}>
                    <div className={"flex-col justify-start text-start  content-center p-8"}>
                        <h1 className="font-bold text-base ">Active Users</h1>
                        <p className="flex justify-start py-2"><KeyboardDoubleArrowUpIcon/><span>+{activePercentage.toFixed(1)}%</span></p>
                        <p className="font-bold text-2xl">{activeCount}</p>
                    </div>
                    <div className={"flex justify-end text-end"}>
                        <ChartContainer
                            width={150}
                            height={150}
                            series={[{data: uData, label: 'uv', type: 'bar'}]}
                            xAxis={[{scaleType: 'band', data: xLabels}]}
                            colors={['#d76969']}
                        >
                            <BarPlot/>
                        </ChartContainer>
                    </div>
                </div>
                <div className={"grid grid-cols-2 bg-gray-100 rounded-2xl p-4 content-center"}>
                    <div className={"flex-col justify-start text-start  content-center p-8"}>
                        <h1 className="font-bold text-base ">Inactive Users</h1>
                        <p className="flex justify-start py-2"><KeyboardDoubleArrowUpIcon/><span>-{inactivePercentage.toFixed(1)}%</span></p>
                        <p className="font-bold text-2xl">{inactiveCount}</p>
                    </div>
                    <div className={"flex justify-end text-end"}>
                        <ChartContainer
                            width={150}
                            height={150}
                            series={[{data: uData, label: 'uv', type: 'bar'}]}
                            xAxis={[{scaleType: 'band', data: xLabels}]}
                        >
                            <BarPlot/>
                        </ChartContainer>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardS;