import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Paper, IconButton,Collapse,Box,Typography} from '@mui/material';
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import {UpdateModal, CreateModal}from "./dashboardModal/updateModal";

const styles = {
    tableHeader: {
        backgroundColor: '#f0f0f0',
    },
};

const transformData = (userData) => {
    const {
        id,
        user_name,
        lastname,
        email,
        phone_number,
        created_at,
        company_name,
        status,
        activities,
    } = userData;

    // Crear objeto base con datos de usuario
    const baseData = createData(
        id,
        `${user_name} ${lastname}`,
        email,
        phone_number,
        created_at,
        company_name,
        status,
    );

    // Mapear actividades a historial
    const history = [
        {
            date: 'Addresses Sent',
            customerId: 'Sent',
            amount: activities.adddresses_sent,
        },
        {
            date: 'Address Response',
            customerId: 'Received',
            amount: activities.addresses_response,
        },
        {
            date: 'Parcels sent',
            customerId: 'Sent',
            amount: activities.parcels_sent,
        },
        {
            date: 'Parcel response',
            customerId: 'Received',
            amount: activities.parcels_response,
        },
        {
            date: 'Insurance Sent',
            customerId: 'Sent',
            amount: activities.insurance_sent,
        },
        {
            date: 'Insurance Response',
            customerId: 'Received',
            amount: activities.insurance_response,
        },
        {
            date: 'Shipment',
            customerId: 'Sent',
            amount: activities.shipment_sent,
        },
        {
            date: 'Shipment With Options',
            customerId: 'Received',
            amount: activities.shipment_options,
        }
    ];
    baseData.history = history;

    return baseData;
};

function createData(id, name, email, phone_number, created_at, company_name, status) {
    return {
        id,
        name,
        email,
        phone_number,
        created_at,
        company_name,
        status,
        history: [],
    };
}

function Row(props) {
    const { row, handleOpenDetails} = props;
    const [open, setOpen] = React.useState(false);
    const handleUpdateClick = () => {
        handleOpenDetails(row);
    };

    const handleChangeStatus = () => {
        const newStatus = !row.status;

        const formated = {
            user_id: row.id,
            new_status: newStatus,
        };

        fetch('http://127.0.0.1:5000/api/v3/manageUsersIN', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formated),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Server responded with an error.');
                }
            })
            .then(data => {
                console.log(data);
                props.onStatusChange(row.id, newStatus);
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Error to change status.');
            });
    };
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.phone_number}</TableCell>
                <TableCell align="right">{row.created_at}</TableCell>
                <TableCell align="right">{row.company_name}</TableCell>
                <TableCell align="right">
                    <button  onClick={handleUpdateClick} className={"underline text-blue-500"}>Update</button>
                </TableCell>
                <TableCell align="right">
                    <label className="relative inline-block h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-gray-900">
                        <input
                            className="peer sr-only"
                            id="AcceptConditions"
                            type="checkbox"
                            checked={row.status}
                            onChange={handleChangeStatus}
                        />
                        <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-8 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                    </label>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                <TableRow style={styles.tableHeader}>
                                        <TableCell align="center">Action</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Activity Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row" align="center">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell align="center">{historyRow.customerId}</TableCell>
                                            <TableCell align="center">{historyRow.amount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone_number: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        company_name: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                Section: PropTypes.string.isRequired,
                Status: PropTypes.string.isRequired,
                Count : PropTypes.number.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    handleOpenDetails: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
};

const Dashboards = () => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const [datas, setDatas] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [open, setOpen] = useState(false);
    const [openC, setOpenCreate] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const DataFroms = {
                parent_id: storedUser.parents_id,
                current_user: storedUser.id
            };
            console.log(DataFroms)
            try {
                const response = await fetch('http://127.0.0.1:5000/api/v3/manageUsers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(DataFroms)
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                console.log(data)
                setDatas(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [storedUser.id, storedUser.parents_id]);
    const handleOpenDetails = (data) => {
        setSelectedResponse(data);
        setOpen(true);
    };
    const handleOpenCreate = () =>{
        setOpenCreate(true);
    }
    const handleCloseDetails = () => setOpen(false);
    const handleStatusChange = (userId, newStatus) => {
        const updatedDatas = datas.map(data => {
            if (data.id === userId) {
                return { ...data, status: newStatus };
            }
            return data;
        });
        setDatas(updatedDatas);
    };
    const handleCloseCreate = () => setOpenCreate(false);

    return (
        <>
            <UpdateModal
                handleOpenDetails={handleOpenDetails}
                handleCloseDetails={handleCloseDetails}
                selectedResponse={selectedResponse}
                open={open}
            />
            <CreateModal
                handleOpenCreate = {handleOpenCreate}
                handleCloseCreate={handleCloseCreate}
                openCreate={openC}
            />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow style={styles.tableHeader}>
                            <TableCell/>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Phone Number</TableCell>
                            <TableCell align="center">Created At</TableCell>
                            <TableCell align="center">Company Name</TableCell>
                            <TableCell align="center">Update</TableCell>
                            <TableCell align="center">Set Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datas.map((data, index) => (
                            <Row key={index} row={transformData(data)} handleOpenDetails={handleOpenDetails}
                                 onStatusChange={handleStatusChange} align="center"/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div data-dial-init="" className="fixed end-6 bottom-6 group">
                <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default"
                        aria-expanded="false" onClick={handleOpenCreate}
                        className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                    <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 1v16M1 9h16"/>
                    </svg>
                    <span className="sr-only">Open actions menu</span>
                </button>
            </div>

        </>
    );
};

export default Dashboards;
