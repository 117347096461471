import React from 'react';

const Solutions = () => {
    return (
        <>
            <section className="bg-gradient-to-l from-gradR w-full h-fit py-12 sm:py-22 mt-28 content-center grid justify-items-center ">
                <h1 className="font-semibold text-4xl "><span
                    className="font-semibold">More</span> <span className="gradient-text">Mone</span><span
                    className="gradient-text-green">Sure</span> Solution</h1>
                <p className="text-lg font-semibold p-4">Some Of The Key Features Of Our Platform Include</p>
                <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  justify-items-center">
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Pre Authorized Chargeback Analytics
                            (Is it real?):</h1>
                        <p className="text-sm p-6 text-start">Our chargeback analytics service provides real-time insights to identify and mitigate risks, empowering merchants against fraud for secure payments.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Pre Authorization Chargeback
                            Management:</h1>
                        <p className="text-sm p-6 text-start">Our chargeback management solution proactively prevents and addresses losses, reducing chargeback rates and safeguarding revenue for business growth.</p>
                        <div className="p-4">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                            </svg>

                        </div>
                    </nav>


                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Guaranteed Payments against Chargeback:</h1>
                        <p className="text-sm p-6 text-start">Eliminate payment uncertainties with our guaranteed protection against chargebacks, ensuring timely reimbursements and covering fees for secure transactions and confident revenue management.
                        </p>
                        <div className="p-5">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1" d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Real-Time Bank to Bank Payments:</h1>
                        <p className="text-sm p-6 text-start">Experience instant bank-to-bank payments with our innovative platform. Say goodbye to delays—we enable fast, reliable transfers for businesses and individuals, from payroll to peer-to-peer transactions.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="1"
                                      d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"/>
                            </svg>

                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">All-Risk Guarantees:</h1>
                        <p className="text-sm p-6 text-start">
                            Our all-risk guarantees protect businesses from payment uncertainties, covering everything from chargebacks to fraud for a secure payment environment, allowing merchants to focus confidently on core operations.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                            </svg>
                        </div>
                    </nav>


                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Pay or Get Paid Your Way (More Options Coming):</h1>
                        <p className="text-sm p-6 text-start">Stay flexible with our platform's diverse payment options, covering credit cards, bank transfers, digital wallets, and more for easy and convenient transactions that evolve to meet your needs.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                      d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 5h6m-6 4h6M10 3v4h4V3h-4Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">ACH/RTP</h1>
                        <p className="text-sm p-6 text-start">Our platform supports ACH and RTP transactions, providing
                            fast and secure payment processing options at a low price.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Email Check</h1>
                        <p className="text-sm p-6 text-start">Send trackable checks with the click of a button.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M11 16v-5.5A3.5 3.5 0 0 0 7.5 7m3.5 9H4v-5.5A3.5 3.5 0 0 1 7.5 7m3.5 9v4M7.5 7H14m0 0V4h2.5M14 7v3m-3.5 6H20v-6a3 3 0 0 0-3-3m-2 9v4m-8-6.5h1"/>
                            </svg>
                        </div>
                    </nav>


                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Digital Check</h1>
                        <p className="text-sm p-6 text-start">For a more convenient and eco-friendly option, we enable
                            you to make payments via email and/or text.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">User/ Approver</h1>
                        <p className="text-sm p-6 text-start">Our platform includes user and approver functionality,
                            allowing businesses to set up multiple users and approvals for financial transactions.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">API</h1>
                        <p className="text-sm p-6 text-start">We offer API integration to allow businesses to seamlessly
                            integrate our platform with their existing systems</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023"/>
                            </svg>
                        </div>
                    </nav>

                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">eCommerce Guarantee</h1>
                        <p className="text-sm p-6 text-start">Whether you are processing your transaction or shipping
                            your items or goods . Our exclusive “ALL RISK” platform provides complete protection against
                            covered causes of loss.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Shipping</h1>
                        <p className="text-sm p-6 text-start">Our years of experience and management shipping risk ,
                            provides the only “ALL RISK” program of its kind. Our high Value management program assures
                            additional benefit affordably
                        </p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="m10.051 8.102-3.778.322-1.994 1.994a.94.94 0 0 0 .533 1.6l2.698.316m8.39 1.617-.322 3.78-1.994 1.994a.94.94 0 0 1-1.595-.533l-.4-2.652m8.166-11.174a1.366 1.366 0 0 0-1.12-1.12c-1.616-.279-4.906-.623-6.38.853-1.671 1.672-5.211 8.015-6.31 10.023a.932.932 0 0 0 .162 1.111l.828.835.833.832a.932.932 0 0 0 1.111.163c2.008-1.102 8.35-4.642 10.021-6.312 1.475-1.478 1.133-4.77.855-6.385Zm-2.961 3.722a1.88 1.88 0 1 1-3.76 0 1.88 1.88 0 0 1 3.76 0Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Echeck</h1>
                        <p className="text-sm p-6 text-start">Send and receive Echecks affordably while reducing risk.
                            At your discretion Our EZcheck benefit verifies and protects your electronic check
                            transactions.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                            </svg>
                        </div>
                    </nav>

                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Credit Guarantee</h1>
                        <p className="text-sm p-6 text-start">If you provide 30-60-90 business credit. We can guarantee
                            your transaction within minutes in over 80 countries on single invoices.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeWidth="1"
                                      d="M11.083 5.104c.35-.8 1.485-.8 1.834 0l1.752 4.022a1 1 0 0 0 .84.597l4.463.342c.9.069 1.255 1.2.556 1.771l-3.33 2.723a1 1 0 0 0-.337 1.016l1.03 4.119c.214.858-.71 1.552-1.474 1.106l-3.913-2.281a1 1 0 0 0-1.008 0L7.583 20.8c-.764.446-1.688-.248-1.474-1.106l1.03-4.119A1 1 0 0 0 6.8 14.56l-3.33-2.723c-.698-.571-.342-1.702.557-1.771l4.462-.342a1 1 0 0 0 .84-.597l1.753-4.022Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Merchant Accounts</h1>
                        <p className="text-sm p-6 text-start">At your request we provide instant merchant accounts
                            guaranteed against covered chargeback.
                        </p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Marketplace Texting</h1>
                        <p className="text-sm p-6 text-start">Whether you desire security confirmation via text ,
                            confirming identity or transactions.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
                            </svg>
                        </div>
                    </nav>

                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Global Payments</h1>
                        <p className="text-sm p-6 text-start">Whether you are buying or selling we provide representation and infrastructure in over 80 countries safely and securely
                        </p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M4.37 7.657c2.063.528 2.396 2.806 3.202 3.87 1.07 1.413 2.075 1.228 3.192 2.644 1.805 2.289 1.312 5.705 1.312 6.705M20 15h-1a4 4 0 0 0-4 4v1M8.587 3.992c0 .822.112 1.886 1.515 2.58 1.402.693 2.918.351 2.918 2.334 0 .276 0 2.008 1.972 2.008 2.026.031 2.026-1.678 2.026-2.008 0-.65.527-.9 1.177-.9H20M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-xl mt-8 mx-8 text-start">Global Investigations</h1>
                        <p className="text-sm p-6 text-start">As a subscriber of LinQex solutions , We can assist in providing Private investigative services in over 100 countries.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                    </nav>
                </div>

            </section>
        </>
    );
}

export default Solutions;