import React from 'react';
import {Link} from "react-router-dom";

const MarketingSlideV = () =>{
    return(
        <>
            <section className="bg-gradient-to-l from-oscuro from-40% to-card2 to-90% w-full h-fit content-center">
                <nav className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center px-16">
                    <div>
                        <img src="/top-3.png" alt="" className="h-full w-full" />
                    </div>
                    <div className="content-center mb-8">
                        <h1 className="text-white font-semibold text-4xl p-4">Marketing & Social Engagement: Amplify Your Presence</h1>
                        <p className="text-white text-lg p-4 mb-8">Our second pillar focuses on boosting your business's visibility and engagement
                            through strategic marketing and social media management. We create compelling content,
                            optimize SEO, and manage your online reputation.</p>
                        <Link to="/pricing"><button className="bg-blue-600 rounded-full"><p className="text-white font-semibold p-3 mx-6">Know more</p></button></Link>
                    </div>
                </nav>
            </section>
        </>
    );
}
export default MarketingSlideV;