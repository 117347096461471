import React from "react";
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const SliderTop = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();


    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return(
        <>
            <nav className="bg-gradient-to-tl from-white from-70% to-gradR to-98%  w-full lg:h-[850px] md:h-[650px] sm:h-auto mt-8 py-12 sm:py-2  text-center sm:text-start">
                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2 lg:mx-36 md:mx-8 sm:mx-8 lg:py-32 md:py-4 sm:py-4 ">
                    <nav className={`p-8 content-center ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                        <h1 className="text-3xl text-center sm:text-start font-semibold p-6"><span
                            className="text-card2 font-bold">Established</span> As Financial Technology (Fintech)</h1>
                        <p className="font-semibold text-sm sm:text-lg p-2">MoneSure is a pioneering force in the realm
                            of financial technology (Fintech), dedicated to delivering cutting-edge solutions tailored
                            to the needs of medium and high-value merchants. Our comprehensive suite of services
                            encompasses risk management, insurance, marketing, and logistics, designed to support
                            businesses at every stage of their growth journey.</p>
                        <p className="font-semibold text-sm sm:text-lg p-2">With a legacy spanning three decades,
                            MoneSure has consistently delivered innovative solutions and forged new pathways to success
                            for our clients. Collaborating closely with our partners, we are committed to shaping the
                            future of financial technology, driving growth and prosperity in an ever-evolving
                            marketplace.</p>
                        <p className="font-semibold text-sm sm:text-lg p-2">Discover how MoneSure can propel your
                            business forward and unlock new possibilities in the world of Fintech.</p>
                        <nav className="mt-4 justify-items-center content-center ">
                            <Link to="/contact">
                                <button className="bg-primaryV rounded-full p-2"><p
                                    className="font-semibold text-white mx-6 text-sm">CONTACT US</p></button>
                            </Link>
                        </nav>
                    </nav>
                    <nav className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden content-center ${isVisibleCard21 ? 'animated-up' : 'visible'}`} ref={refCard21}>
                        <img src="/topabout.png" alt="" className="object-cover  h-80"/>
                    </nav>

                </div>
            </nav>
        </>
    );
}

export default SliderTop;