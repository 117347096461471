import React from 'react';
import BatchesForm from '../../Register/forms/BatchesForms'

const Batches = () =>{
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <BatchesForm/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Batches;