import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import SettingsProfile from "../../Components/Dashboard/HomeProfile/settingsProfile"

const Settings = () => {

    return(
        <>
            <SideBar/><Navigation/>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <SettingsProfile/>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Settings;