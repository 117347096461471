import React from 'react';
import RegisterForms from '../../Register/forms/merchant_formUser'

const Main = () => {

    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                        <RegisterForms/>
                    </div>
                </div>
            </section>
        </>
)
}

export default Main