import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const useVisibility = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView && !isVisible) {
            setIsVisible(true);
        } else if (!inView && isVisible) {
            setIsVisible(false);
        }
    }, [inView, isVisible]);
    return { ref, isVisible };
};

export default useVisibility;


