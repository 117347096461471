import React from "react";

const Basic_cardsMerchant = () => {
    return (
        <>
            <section
                className="bg-gradient-to-bl from-oscuro to-card2 grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1  justify-items-center content-center p-8">
                <nav className="w-72 text-center p-8 border-2 border-white rounded-3xl">
                    <div className="flex justify-center">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="M12 15v5m-3 0h6M4 11h16M5 15h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1Z"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-2xl text-white mb-2">Buy and sell using our money</h1>
                </nav>
                <nav className="w-72 text-center p-8 border-2 border-white rounded-3xl">
                    <div className="flex justify-center">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                  d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-2xl text-white mb-2">Guaranteed Payments Up to $10,000</h1>
                </nav>
                <nav className="w-72 text-center p-8 border-2 border-white rounded-3xl">
                    <div className="flex justify-center">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-2xl text-white mb-2">On demand delivery,
                        protected transactions through shipping</h1>
                </nav>
                <nav className="w-72 text-center p-8 border-2 border-white rounded-3xl">
                    <div className="flex justify-center">
                        <svg className="w-20 h-20 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M18.122 17.645a7.185 7.185 0 0 1-2.656 2.495 7.06 7.06 0 0 1-3.52.853 6.617 6.617 0 0 1-3.306-.718 6.73 6.73 0 0 1-2.54-2.266c-2.672-4.57.287-8.846.887-9.668A4.448 4.448 0 0 0 8.07 6.31 4.49 4.49 0 0 0 7.997 4c1.284.965 6.43 3.258 5.525 10.631 1.496-1.136 2.7-3.046 2.846-6.216 1.43 1.061 3.985 5.462 1.754 9.23Z"/>
                        </svg>
                    </div>
                    <h1 className="font-bold text-2xl text-white mb-2">Increase your sales through our platform.</h1>
                </nav>
            </section>
        </>
    );
}

export default Basic_cardsMerchant;