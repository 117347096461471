import React from 'react';
import Main from '../../Components/Dashboard/FormsProfile/main'
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";

const Merchant = () => {

    return(
        <>
            <SideBar /> <Navigation />
            <div>
                <Main />
            </div>
        </>
    )
}

export default Merchant