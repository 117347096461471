import React from 'react';
import Menu from "../Components/navbar/menu";
import Top from "../Components/SectionDigitales/top";
import Slideshow from "../Components/SectionDigitales/slidehomeDigitalPayments";
import ProvidingShipping from "../Components/SectionDigitales/providingDigitalPayments";
import SlideV from "../Components/SectionDigitales/slideVDigitalPayments";
import StartedMerchant from "../Components/SectionDigitales/started";
import Footer from "../Components/footer/footer";

const DigitalPayments = () =>{
    return(
        <>
                <Menu />
                <Top />
                <Slideshow />
                <ProvidingShipping />
                <SlideV />
                <StartedMerchant />
                <Footer />
        </>
    )
}

export default  DigitalPayments;