import React from 'react';
import {Link} from "react-router-dom";

const Slidev = () =>{
    return(
        <>
            <section className="bg-gradient-to-l from-oscuro from-20% to-card2 to-90% w-full h-fit content-center">
                <nav className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center px-16">
                    <div>
                        <img src="/diagonal.png" alt="" className="h-full w-full" />
                    </div>
                    <div className="content-center">
                        <h1 className="text-white font-semibold text-4xl p-4">
                            Empowering Your Financial Management
                        </h1>
                        <p className="text-white text-md p-4">
                            Discover our reliable and secure payment network, designed with advanced risk management and
                            compliance measures. Enjoy quick and easy transactions with real-time processing.
                        </p>
                        <p className="text-white text-md p-4 mb-8">
                            Our platform integrates seamlessly with major banks, making it easy to transfer funds
                            securely between accounts.
                        </p>
                        <Link to="/pricing">
                            <button className="bg-blue-600 rounded-full">
                                <p className="text-white font-semibold p-3 mx-6">Learn More</p>
                            </button>
                        </Link>
                    </div>
                </nav>
            </section>
        </>
    );
}
export default Slidev;