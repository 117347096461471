import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import NavigationBar from "../../Components/Dashboard/GenericInAll/navigation";
import ShipmentOptions from "../../Components/Dashboard/FormsProfile/shipmentWoptions";

const ShipmentOPTI = () => {
    return(
        <>
            <SideBar/><NavigationBar/>
            <div>
                <ShipmentOptions />
            </div>
        </>
    )
}

export default ShipmentOPTI;