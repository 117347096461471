import React from 'react';
import Menu from "../Components/navbar/menu";
import Top from "../Components/SectionShipping/top";
import Slideshow from "../Components/SectionShipping/slidehomeShipping";
import ProvidingShipping from "../Components/SectionShipping/providingShipping";
import SlideV from "../Components/SectionShipping/slideVShipping";
import StartedMerchant from "../Components/SectionShipping/started_Shipping";
import Footer from "../Components/footer/footer";

const Shipping = () =>{
    return(
        <>
                <Menu />
                <Top />
                <Slideshow />
                <ProvidingShipping />
                <SlideV />
                <StartedMerchant />
                <Footer />
        </>
    )
}

export default  Shipping;