import React, {useState} from 'react';
import {useAuth} from "../../../context/AuthContext";
import {Link} from 'react-router-dom';
import HomeIcon from "@mui/icons-material/Home";
import toast, { Toaster } from 'react-hot-toast';


const Login = ({onlogin}) =>{
    const { admin_login } = useAuth();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const LoggedIn = () => {
        return fetch('http://127.0.0.1:5000/api/v6/login/main', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok){
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.error || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                if (data.access_token) {
                    admin_login(data.access_token, data.user);
                    onlogin();
                } else {
                    throw new Error(data.message || 'Failed to log in');
                }
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Failed to log in');
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await toast.promise(
                LoggedIn(),
                {
                    loading: 'Logging in...',
                    success: <b>Logged in successfully!</b>,
                    error: <b>Failed to log in.</b>,
                }
            );
        } catch (error) {
            console.error('Error:', error);
            toast.error(`Verify your credentials. Error: ${error.message}`);
        }
    };
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <section className={"w-full h-screen flex justify-center content-center items-center p-4 fullscreen-bg-log-admin"}>
                <nav className=" p-4 flex-col h-fit justify-center content-center bg-white rounded-tr-3xl rounded-br-3xl rounded-bl-3xl border border-gray-200 shadow-lg">
                    <div className="fixed top-5 left-8 flex justify-center p-2 rounded-md">
                        <Link to={"/"} className="flex justify-center content-center"><HomeIcon
                            sx={{width: 31, height: 31,}}
                        /><span className="text-gray-500 text-lg">Home</span></Link>
                    </div>
                    <h1 className="font-bold text-gray-500 text-4xl sm:text-4xl w-full text-center p-8">Hi, welcome back, please Log in!!</h1>
                    <form className="max-w-xl " onSubmit={handleSubmit}>
                        <div className="mb-5">
                            <label htmlFor="email"
                                   className="block mb-2 text-lg font-medium text-white dark:text-gray-800">Email
                                Address</label>
                            <input type="email" id="email_login" name="email" onChange={(e) => {
                                handleInputChange(e)
                            }}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                   placeholder="name@example.com" required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password"
                                   className="block mb-2 text-lg font-medium text-white  dark:text-gray-800">Password</label>
                            <input type="password" id="password" name="password" onChange={(e) => {
                                handleInputChange(e)
                            }}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                   required placeholder="*********"/>
                        </div>
                        <div className="text-center p-2 space-x-0 sm:space-x-4">
                            <button type="submit"
                                    className="text-white mb-4 bg-card2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Log In
                            </button>
                            <Link to="/login"><button type="reset"
                                    className="text-white bg-gray-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                            </button></Link>
                        </div>
                    </form>
                </nav>
                    <div className="fixed right-12 top-9 flex justify-center items-center">
                        <Link to="/"> <img src="/MoneSureLogoG.png" alt="" className="w-fit h-12"/></Link>
                    </div>
            </section>
        </>
    )
}

export default Login;