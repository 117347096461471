import React, {useEffect, useState} from 'react';
import ActivitiesSection from './activities/activitiesSections'

const ActivityCard = () => {

    const [infos, setInfos] = useState([]);
    const [parcels, setParcels] = useState([]);
    const [trackers, setTrackers] = useState([]);
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (!storedUser) {
            return; // Salir del efecto si storedUser no está definido
        }
        const fetchData = async () => {
        try {
            const response = await fetch('http://127.0.0.1:5000/api/get_activity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_profile_id: storedUser.profile.user_profile_id
                })
            });

            if (!response.ok) {
                throw new Error('Server responded with an error.');
            }

            const data = await response.json();
            setInfos(data);
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchData();

    }, []);
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (!storedUser) {
            return; // Salir del efecto si storedUser no está definido
        }

        const fetchData = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/get_activity_parcels', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_profile_id: storedUser.profile.user_profile_id
                    })
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                setParcels(data);
                console.log(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();

    }, []);
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (!storedUser) {
            return;
        }

        const fetchDataTrack = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/get_trackers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_profile_id: storedUser.profile.user_profile_id
                    })
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                setTrackers(data);
                console.log(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchDataTrack();

    }, []);
    return(
        <>
                <ActivitiesSection
                    activities={infos}
                    parcels = {parcels}
                    trackers = {trackers}
                />
        </>
    )
}

export default ActivityCard;