import React, {useEffect, useRef, useState} from 'react';
import Sidebar from '../../Components/Dashboard/GenericInAll/sidebar';
import Navigation from '../../Components/Dashboard/GenericInAll/navigation';
import DocumentsTable from "../../Components/Dashboard/checksTables/documents"
const ManageDocuments = () => {
    const storedUserRef = useRef(JSON.parse(localStorage.getItem('user')));
    const [documentsMethod, setDocsMethod] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const datas = {
                user_profile_id: storedUserRef.current.id
            };

            try {

                const response = await fetch('http://127.0.0.1:5000/api/v3/get_docs', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(datas)
                });

                if (!response.ok) {
                    throw new Error('Server responded with an error.');
                }

                const data = await response.json();
                console.log(data);
                setDocsMethod(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (storedUserRef.current && storedUserRef.current.id) {
            fetchData();
        }

    }, []);
    return(
        <>

            <Sidebar /><Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <DocumentsTable documentsMethod={documentsMethod}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ManageDocuments;