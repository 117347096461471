import React  from 'react';
import Menu from "../Components/navbar/menu";
import Top from "../Components/merchant/top";
import Slideshow from "../Components/merchant/slidehomeMerchant";
import ProvidingMerchant from "../Components/merchant/providingMerchant";
import SlideV from "../Components/merchant/slideVMerchant";
import ServicesMerchant from "../Components/merchant/servicesMerchant";
import AnnouncedMerchant from "../Components/merchant/announcedMerchant";
import Carrousel from "../Components/merchant/carrouseltestimonailMerchant";
import ThreeMerchant from "../Components/merchant/threeMerchant";
import Basic from "../Components/merchant/basic_cardsMerchant";
import StartedMerchant from "../Components/merchant/./started_Merchant";
import Footer from "../Components/footer/footer";
const Merchant = () => {
    return(
        <>
            <Menu />
            <Top />
            <Slideshow />
            <ProvidingMerchant />
            <SlideV />
            <ServicesMerchant  />
            <AnnouncedMerchant />
            <div className=" justify-center items-center hidden lg:flex mb-8">
                <Carrousel />
            </div>
            <ThreeMerchant />
            <Basic />
            <StartedMerchant />
            <Footer />
        </>
    );
}

export default Merchant;