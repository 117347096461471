import React, { useState} from 'react';
import PropTypes from 'prop-types';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Paper} from '@mui/material';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import {ModalAdmin,CreateModalAdmin}from "./dashboardModal/ModalAdmin";
import toast from "react-hot-toast";
import useFetchData from "../../../JS/useFetchData";
import {format, parseISO} from "date-fns";

const styles = {
        tableHeader: {
            backgroundColor: '#85cbe7'
        },
    };

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const transformData = (userData) => {
        const {
            id,
            name,
            location,
            email,
            phone_number,
            created_at,
            updated_at,
            status,
        } = userData;

        const baseData = createData(
            id,
            name,
            location,
            email,
            phone_number,
            created_at,
            updated_at,
            status,
        );

        return baseData;
    };

    function createData(id, name,location, email, phone_number, created_at, updated_at, status) {
        return {
            id,
            name,
            location,
            email,
            phone_number,
            created_at,
            updated_at,
            status,
        };
    }

    function Row(props) {
        const { row, handleOpenDetails} = props;
        // const [open, setOpen] = React.useState(false);
        const handleUpdateClick = () => {
            handleOpenDetails(row);
        };
        const handleChangeStatus = () => {
            const newStatus = !row.status;

            const formated = {
                parent_id: row.id,
                new_status: newStatus,
            };

            fetch('http://127.0.0.1:5000/api/v3/manageParentsIN', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formated),
            })
                .then(response => {
                    if (response.ok) {

                        return response.json();
                    } else {
                        throw new Error('Server responded with an error.');
                    }
                })
                .then(data => {
                    console.log(data);
                    toast.success('Status has been changed successfully')
                    props.onStatusChange(row.id, newStatus);
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error("Error changing status.")
                });
        };

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <StoreOutlinedIcon />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.location}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phone_number}</TableCell>
                    <TableCell align="right">{formatDate(row.created_at)}</TableCell>
                    <TableCell align="right">{formatDate(row.updated_at)}</TableCell>
                    <TableCell align="right">
                        <button  onClick={handleUpdateClick} className={"underline text-blue-500"}>Update</button>
                    </TableCell>
                    <TableCell align="right">
                        <label className="relative inline-block h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-gray-900">
                            <input
                                className="peer sr-only"
                                id="AcceptConditions"
                                type="checkbox"
                                checked={row.status}
                                onChange={handleChangeStatus}
                            />
                            <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-gray-300 ring-[6px] ring-inset ring-white transition-all peer-checked:start-8 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"></span>
                        </label>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    Row.propTypes = {
        row: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            phone_number: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            updated_at: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
        handleOpenDetails: PropTypes.func.isRequired,
        handleOpen: PropTypes.func.isRequired,
        onStatusChange: PropTypes.func.isRequired,
    };

    const AdminDashboards = () => {
        // const storedUser = JSON.parse(localStorage.getItem("user"));
        const [datas, setDatas] = useFetchData();
        const [selectedResponse, setSelectedResponse] = useState(null);
        const [open, setOpen] = useState(false);
        const [openC, setOpenCreate] = useState(false);
        const handleOpenDetails = (data) => {
            setSelectedResponse(data);
            setOpen(true);
        };
        const handleOpenCreate = () =>{
            setOpenCreate(true);
        }
        const handleCloseDetails = () => setOpen(false);
        const handleStatusChange = (userId, newStatus) => {
            const updatedDatas = datas.map(data => {
                if (data.id === userId) {
                    return { ...data, status: newStatus };
                }
                return data;
            });
            setDatas(updatedDatas);
        };
        const handleCloseCreate = () => setOpenCreate(false);
        if (!datas || datas.length === 0) {
            return (
                <div className="w-full flex justify-center items-center" role="status">
                    <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

    return (
        <>
            <ModalAdmin
                handleOpenDetails={handleOpenDetails}
                handleCloseDetails={handleCloseDetails}
                selectedResponse={selectedResponse}
                open={open}
            />
            <CreateModalAdmin
                handleOp={handleOpenCreate}
                close={handleCloseCreate}
                openCreate={openC}
            />
            <section className={"w-full grid grid-cols-1 justify-center p-4 lg:mb-24 md:mb-12 sm:mb-8"}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow style={styles.tableHeader}>
                                <TableCell/>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Location</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone Number</TableCell>
                                <TableCell align="center">Created At</TableCell>
                                <TableCell align="center">Updated At</TableCell>
                                <TableCell align="center">Update</TableCell>
                                <TableCell align="center">Set Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datas.map((data, index) => (
                                <Row key={index} row={transformData(data)} handleOpenDetails={handleOpenDetails}
                                     onStatusChange={handleStatusChange} align="center"/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>

            <div data-dial-init="" className="fixed end-6 bottom-6 group">
                <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default"
                        aria-expanded="false" onClick={handleOpenCreate}
                        className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                    <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 1v16M1 9h16"/>
                    </svg>
                    <span className="sr-only">Open actions menu</span>
                </button>
            </div>

        </>
    );
};

export default AdminDashboards;
