import React from 'react';
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const Desc = () => {
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();


    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return(
        <>
            <section className="bg-gradient-to-tl from-white from-20% to-gradR to-98% w-full h-fit content-center py-24 sm:py-12">
                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  justify-items-center content-center mt-2 sm:mt-24 mx-2 sm:mx-44">
                    <div className={`text-center content-center ${isVisibleCard21 ? 'animated-right' : 'visible'}`} ref={refCard21}>
                        <nav className="border-2 border-blue-800 p-4 mx-12 sm:mx-24 content-center w-4/5 h-fit sm:h-fit">
                            <h1 className="text-card text-3xl sm:text-6xl font-semibold p-8"><span
                                className="gradient-text">Mone</span><span className="gradient-text-green">Sure</span>
                            </h1>
                            <p className="text-lg sm:text-xl ">We are a leading provider of innovative financial technology solutions. Our team is dedicated
                            to using cutting-edge technology to make financial transactions faster, easier, and more
                            secure for individuals and businesses alike.</p>
                        <nav className="mt-4 justify-items-center content-center py-8">
                            <Link to="">
                                <button className="bg-primaryV rounded-full p-2"><p
                                    className="font-semibold text-white mx-6 text-sm">CONTACT US</p></button>
                            </Link>
                        </nav>
                        </nav>
                    </div>
                    <div
                        className={` hidden lg:block  md:hidden sm:hidden content-center ${isVisibleCard21 ? 'animated-up' : 'visible'}`}
                        ref={refCard21}>
                        <img src="/prici.png" alt="" className="object-cover h-4/5"/>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Desc;