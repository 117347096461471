import React, {useEffect, useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
import axios from "axios";

const CustomsItemsForm = () => {
    const { ref: refsCard25, isVisible: isVisiblesCard25 } = useVisibility();
    const [country, setCountry] = useState("");
    const [formData, setFormData] = useState({
        description:'',
        quantity:0,
        value: 0,
        weight:0,
        hs_tariff_number: '',
        origin_country: '',
        user_profile_id: 1,
    })
    const [buttonValueS, setButtonValueS] = useState('Send Information');
    const [isLoading, setIsLoadingForm] = useState(false);
    const HandleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'quantity' || name === 'value' || name === 'weight') {
            setFormData(prevState => ({
                ...prevState,
                [name]: parseInt(value, 10)
            }));
        } else {

            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    useEffect(() => {
        axios.get('http://localhost:5000/api/country_list')
            .then(response => {
                setCountry(response.data);
            })
            .catch(error => console.error('Error fetching:', error));
    }, []);

    const handleIDSubmited = (e) => {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formDataToSend = {
            ...formData,
            user_profile_id: storedUser.id
        };
        console.log(formDataToSend);
        fetch('http://127.0.0.1:5000/api/v5/customes_items', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataToSend)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                console.log(data)
                setIsLoadingForm(false);
                setButtonValueS('Send Information');
                const alerta = document.getElementById('alertaS');
                alerta.classList.remove('hidden');
                alerta.classList.add('flex');
                setTimeout(() => {
                    alerta.classList.add('hidden');
                    alerta.classList.remove('flex');
                }, 3000);
            })
            .catch(error => {
                console.error('An error occurred:', error);
                setIsLoadingForm(false);
                setButtonValueS('Send Information');
                const alertaE = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaE.classList.remove('hidden');
                alertaE.classList.add('flex');
                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaE.classList.add('hidden');
                    alertaE.classList.remove('flex');
                }, 3000);
            });
    }

    return(
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-blue-50 rounded-xl border-t-8 border-blue-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Create a Custom Information</h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can create a Customize Information</p>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Tracking was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p> </span>
                    </div>
                </div>
            </nav>
            <section
                className="w-full h-fit grid-cols-1 justify-center py-12 ">
                <form className="px-2 sm:px-24" onSubmit={handleIDSubmited}>
                    <nav
                        className={`grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center p-8 ${isVisiblesCard25 ? 'animated-right' : 'visible'}`}
                        ref={refsCard25}>
                        <dt><h1 className="text-3xl font-bold mb-4 p-4">Customs Items</h1></dt>
                        <div className={"w-full  rounded-xl border-2 p-8 col-span-2 bg-white shadow-3xl"}>
                            <div className="relative z-0 w-full mb-5 group">
                                <label htmlFor="message"
                                       className="block mb-2 text-base font-medium text-gray-900 dark:text-white">Description</label>
                                <textarea
                                    id="message" rows="1" name="description" onChange={(e) => {HandleInputChange(e)}}
                                    className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Write your short description Ex.[Sweet shirts]"></textarea>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="quantity" id="floating_quantity" onChange={(e) => {HandleInputChange(e)}}
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_quantity"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Quantity</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="weight" onChange={(e) => {HandleInputChange(e)}}
                                       id="floating_weight"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_weight"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Weight</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="value" onChange={(e) => {HandleInputChange(e)}} id="floating_customs_value"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_customs_value"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Value.</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="hs_tariff_number" onChange={(e) => {HandleInputChange(e)}}
                                       id="hs_tariff_number"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_hs_tariff_number"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Tariff
                                    Number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select
                                    id="underline_select_country"
                                    name="origin_country"
                                    value={formData.country}
                                    onChange={(e) => {
                                        HandleInputChange(e);
                                    }}
                                    className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                    <option value="">Origin Country</option>
                                    <option value="us">prueba</option>
                                    {Object.entries(country).map(([code, name]) => (
                                        <option key={code} value={code}>{name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </nav>
                    <div className="w-full flex justify-around mt-8 group">
                        <button type="submit"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base w-full sm:w-2/3 px-16 py-2 text-center"> {isLoading ? (
                            <div role="status" className="">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            buttonValueS
                        )}
                        </button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default CustomsItemsForm