    import React, {useEffect, useState} from 'react';
    import toast, {Toaster} from "react-hot-toast";

    const FunctionsParents = ({Relation}) =>{
        const [editMode, setEditMode] = useState(false);
        const [shipping, setShipping] = useState({});
        const [merchant, setMerchant] = useState({});
        const [checking, setChecking] = useState({});
        const [total, setTotal] = useState({});
        useEffect(() => {
            if (Relation.length > 0) {
                const initialFunctionsState = Relation.reduce((acc, relation) => {
                    acc[relation.parent_id] = {
                        shipping: relation.functions.shipping,
                        merchant: relation.functions.merchant,
                        checking: relation.functions.checking,
                        total: relation.functions.total,
                    };
                    return acc;
                }, {});
                setShipping(initialFunctionsState);
                setMerchant(initialFunctionsState);
                setChecking(initialFunctionsState);
                setTotal(initialFunctionsState);
            }
        }, [Relation]);
       const handleFunctionChange = (parentId, funcName) => {
            switch (funcName) {
                case 'shipping':
                    setShipping({
                        ...shipping,
                        [parentId]: { ...shipping[parentId], shipping: !shipping[parentId].shipping },
                    });
                    setTotal({
                        ...total,
                        [parentId]: { ...total[parentId], total: false },
                    });
                    break;
                case 'merchant':
                    setMerchant({
                        ...merchant,
                        [parentId]: { ...merchant[parentId], merchant: !merchant[parentId].merchant },
                    });
                    setTotal({
                        ...total,
                        [parentId]: { ...total[parentId], total: false },
                    });
                    break;
                case 'checking':
                    setChecking({
                        ...checking,
                        [parentId]: { ...checking[parentId], checking: !checking[parentId].checking },
                    });
                    setTotal({
                        ...total,
                        [parentId]: { ...total[parentId], total: false },
                    });
                    break;
                case 'total':
                    const totalValue = !total[parentId]?.total;
                    setTotal({
                        ...total,
                        [parentId]: { ...total[parentId], total: totalValue },
                    });
                    if (totalValue) {
                        setShipping({
                            ...shipping,
                            [parentId]: { ...shipping[parentId], shipping: true },
                        });
                        setMerchant({
                            ...merchant,
                            [parentId]: { ...merchant[parentId], merchant: true },
                        });
                        setChecking({
                            ...checking,
                            [parentId]: { ...checking[parentId], checking: true },
                        });
                    }
                    break;
                default:
                    break;
            }
        };
        const handleUpdateAccess = async (parentId,e) => {
            e.preventDefault();

            const updatedFunctions = {
                shipping: shipping[parentId].shipping,
                merchant: merchant[parentId].merchant,
                checking: checking[parentId].checking,
                total: total[parentId].total,
                parents_id: parentId
            };
            //
            console.log(JSON.stringify(updatedFunctions));
            fetch('http://127.0.0.1:5000/api/v6/relations/setUpdate',{

                method: 'POST',
                headers: {
                    'Content-Type': `application/json`,
                },
                body: JSON.stringify(updatedFunctions)
            })
                .then(response =>{
                    if (response.ok){
                        return response.json();
                    }else {
                        return response.json().then(error => {
                            throw new Error(error.message || 'Server responded with an error.');
                        });
                    }
                })
                .then(data => {
                    console.log(data)
                    toast.success('Updated successfully');
                })
                .catch(error => {
                    console.error(error)
                    toast.error(`Server responded with an Error: ${error.message}`);
                })
        }
        if(Relation === '' || Relation === undefined || Relation.length === 0){
           return <div role="status" className={"w-full flex items-center justify-center"}>
                <svg aria-hidden="true"
                     className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        }
        return (
            <>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <section className="w-full p-8">
                    <nav className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8">
                        {Relation.map((relation) => (
                            <div key={relation.parent_id} className="im rounded-3xl border border-gray-200 shadow-xl">
                                <div className="w-full flex justify-end rounded-2xl" >
                                    <button
                                        type="button"
                                        className="focus:outline-none focus:ring-2 focus:ring-green-300 p-3 w-fit flex focus:ring-lime-200 rounded-tr-3xl rounded-bl-xl"
                                        onClick={() => {
                                            setEditMode(!editMode);
                                        }}
                                    >
                                        <h1 className="px-2 font-semibold text-md group">Update Access</h1>
                                        <svg
                                            className="w-6 h-6 text-gray-800 dark:text-white group"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="square"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <nav className="p-4 w-full ">
                                    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 p-2 gap-8">
                                        <div className="w-full bg-green-100 p-2 rounded-2xl shadow-xl">
                                            <h1 className={"font-bold text-gray-500 text-xl"}>Basic Info</h1>
                                            <nav className="text-center text-gray-500 mt-4">
                                                <h1 className={"font-bold text-teal-800"}>Name</h1>
                                                <p>{relation.name}</p>
                                            </nav>
                                            <nav className="text-center text-gray-500 py-4">
                                                <h1 className="font-bold text-teal-800">Email</h1>
                                                <p className="w-auto break-words">{relation.email}</p>
                                            </nav>
                                            <nav className="text-center text-gray-500 py-4">
                                                <h1 className={"font-bold text-teal-800"}>Phone</h1>
                                                <p>{relation.phone_number}</p>
                                            </nav>
                                        </div>
                                        <div className={"bg-blue-100 rounded-2xl shadow-xl p-2"}>
                                           <h1 className={"font-bold text-xl text-center w-full"}>Functions</h1>
                                            <form className={"text-center"} onSubmit={(e) => handleUpdateAccess(relation.parent_id, e)}>
                                                <ul className={"py-6 text-center w-full font-semibold text-gray-500"}>
                                                    <li className={"mb-3"}>
                                                        <h1 className={"font-bold text-teal-800"}>Shipping:{' '}</h1>
                                                        {editMode ? (

                                                            <label className="checkbox">
                                                                <input type="checkbox"  checked={shipping[relation.parent_id]?.shipping} onChange={() => handleFunctionChange(relation.parent_id, 'shipping')}/>
                                                                    <span className="checkmark"></span>
                                                                    <span className="text">Activate</span>
                                                            </label>

                                                            ) : shipping[relation.parent_id]?.shipping ? (
                                                            'Enabled'
                                                        ) : (
                                                            'Disabled'
                                                        )}
                                                    </li>
                                                    <li className={"mb-3"}>
                                                        <h1 className={"font-bold text-teal-800"}>Merchant:{' '}</h1>
                                                        {editMode ? (
                                                            <label className={"checkbox"}>
                                                                <input type="checkbox" checked={merchant[relation.parent_id]?.merchant} onChange={() => handleFunctionChange(relation.parent_id, 'merchant')}/>
                                                                <span className="checkmark"></span>
                                                                <span className="text">Activate</span>
                                                            </label>
                                                        ) : merchant[relation.parent_id]?.merchant ? (
                                                            'Enabled'
                                                        ) : (
                                                            'Disabled'
                                                        )}
                                                    </li>
                                                    <li className={"mb-3"}>
                                                        <h1 className={"font-bold text-teal-800"}>Checking:{' '}</h1>
                                                        {editMode ? (
                                                            <label className={"checkbox"}>
                                                                <input type="checkbox" checked={checking[relation.parent_id]?.checking} onChange={() => handleFunctionChange(relation.parent_id, 'checking')}/>
                                                                <span className="checkmark"></span>
                                                                <span className="text">Activate</span>
                                                            </label>
                                                        ) : checking[relation.parent_id]?.checking ? (
                                                            'Enabled'
                                                        ) : (
                                                            'Disabled'
                                                        )}
                                                    </li>
                                                    <li className={"mb-3"}>
                                                        <h1 className={"font-bold text-teal-800"}> Total:{' '}</h1>
                                                            {editMode ? (
                                                                <label className={"checkbox"}>
                                                                    <input type="checkbox"
                                                                           checked={total[relation.parent_id]?.total}
                                                                           onChange={() => handleFunctionChange(relation.parent_id, 'total')}/>
                                                                    <span className="checkmark"></span>
                                                                    <span className="text">Activate</span>
                                                                </label>
                                                            ) : total[relation.parent_id]?.total ? (
                                                                'Enabled'
                                                            ) : (
                                                                'Disabled'
                                                            )}
                                                    </li>
                                                </ul>
                                                {editMode &&
                                                    <>
                                                        <div className={"grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 p-2 gap-8"}>
                                                            <button type={"submit"}
                                                                className="cursor-pointer font-semibold overflow-hidden relative z-100 border border-green-500 group px-4 py-2 rounded-2xl">
                                                        <span
                                                            className="relative z-10 text-green-500 group-hover:text-white text-sm duration-500">Update</span>
                                                                <span
                                                                    className="absolute w-full h-full bg-green-500 -left-32 top-0 -rotate-45 group-hover:rotate-0 group-hover:left-0 duration-500"></span>
                                                                <span
                                                                    className="absolute w-full h-full bg-green-500 -right-32 top-0 -rotate-45 group-hover:rotate-0 group-hover:right-0 duration-500"></span>
                                                            </button>
                                                            <button type={"reset"} onClick={() => {
                                                                setEditMode(!editMode);
                                                            }}
                                                                className="cursor-pointer font-semibold overflow-hidden relative z-100 border border-red-500 group px-4 py-2 rounded-2xl">
                                                    <span
                                                        className="relative z-10 text-red-500 group-hover:text-white text-sm duration-500">Cancel</span>
                                                                <span
                                                                    className="absolute w-full h-full bg-red-500 -left-32 top-0 -rotate-45 group-hover:rotate-0 group-hover:left-0 duration-500"></span>
                                                                <span
                                                                    className="absolute w-full h-full bg-red-500 -right-32 top-0 -rotate-45 group-hover:rotate-0 group-hover:right-0 duration-500"></span>
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        ))}
                    </nav>
                </section>
            </>
        )
    }

    export default FunctionsParents;