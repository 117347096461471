import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../Components/context/AuthContext';
import Profile from "./profile";
import Merchant from "./merchant"
import Shipping from "./Shipping";
import Checks from "./checks";
import ShippingAddress from "./ship_address";
import ShipInsurances from "./ship_insurances";
import Trackers from "./Trackers";
import Parcels from "./Parcels";
import ShipmentWithOPT from "./ShipmentOptions";
import Batches from "./Batches";
import CustomsInfo from "./customsinfo";
import CustomItems from "./CustomsItems"
import EndShipperPage from "./EndShipper";
import AddPayment from "./AddPayment";
import PaymentsPage from "./PaymentsPage";
import TrackerPayment from "./trackerPayment";
import UpdatePayment from "./updatePayment";
import Bank from "./ManageBanks";
import AddDocuments from "./addDocument";
import Metadata from "./carrierMeta";
import Documents from "./ManageDocuments"
import Activity from "./activityPage";
import Dashboard from "./Dashboard"
import Settings from "./settings";
import AdminPage from "../Admin/Main"
import MainUsersPage from "../Admin/MainUsers";
import ManageFunction from "../Admin/ManageFunction";
const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();

    return (
        isLoggedIn ? <Profile {...rest} /> : <Navigate to="/login" />
    );
};
const MerchantRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn,merchantAccess } = useAuth();
    console.log(merchantAccess, isLoggedIn);
    return (
        isLoggedIn && merchantAccess ? <Merchant {...rest} /> : <Navigate to="/login" />
    );
};
const ShippingRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <Shipping {...rest} /> : <Navigate to="/login" />
    )
};
const ShipAddressRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return (
    isLoggedIn && shippingAccess ? <ShippingAddress {...rest} /> : <Navigate to="/login" />
    )
}
const AddChecks = ({ element: Component, ...rest }) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <Checks {...rest} /> : <Navigate to="/login" />
    )
}
const ShipInsurancesRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <ShipInsurances {...rest} /> : <Navigate to="/login" />
    )
}
const TracKerRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <Trackers {...rest} /> : <Navigate to="/login" />
    )
}
const ParcelsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <Parcels {...rest} /> : <Navigate to="/login" />
    )
}
const ShipWoptionsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <ShipmentWithOPT {...rest} /> : <Navigate to="/login" />
    )
}
const BatchesRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <Batches {...rest} /> : <Navigate to="/login" />
    )
}
const CustomsInfoRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <CustomsInfo {...rest} /> : <Navigate to="/login" />
    )
}
const CustomsItemsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <CustomItems {...rest} /> : <Navigate to="/login" />
    )
}
const EndShipperRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, shippingAccess } = useAuth();
    return(
        isLoggedIn && shippingAccess ? <EndShipperPage {...rest} /> : <Navigate to="/login" />
    )
}
const AddPaymentRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <AddPayment {...rest} /> : <Navigate to="/login" />
    )
}
const PaymentsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <PaymentsPage {...rest} /> : <Navigate to="/login" />
    )
}
const TrackerPaymentsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <TrackerPayment {...rest} /> : <Navigate to="/login" />
    )
}
const UpdatePaymentRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <UpdatePayment {...rest} /> : <Navigate to="/login" />
    )
}
const AddDocumentsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <AddDocuments {...rest} /> : <Navigate to="/login" />
    )
}
const MetadataCarrierRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <Metadata {...rest} /> : <Navigate to="/login" />
    )
}
const BankRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn && checkingAccess ? <Bank {...rest} /> : <Navigate to="/login" />
    )
}
const DocumentsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, checkingAccess } = useAuth();
    return(
        isLoggedIn  && checkingAccess ? <Documents {...rest} /> : <Navigate to="/login" />
    )
}
const ActivityRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn, totalAccess } = useAuth();
    return(
        isLoggedIn && totalAccess ? <Activity {...rest} /> : <Navigate to="/login" />
    )
}
const DashboardRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn, isAdmin } = useAuth();
    return isLoggedIn && isAdmin ? <Dashboard {...rest} /> : <Navigate to="/profile" />;
};
const SettingsRoute= ({element: Component, ...rest}) => {
    const { isLoggedIn } = useAuth();
    return(
        isLoggedIn ? <Settings {...rest} /> : <Navigate to="/login" />
    )
}
const AdminPageRoute= ({element: Component, ...rest}) => {
    const { isAdminLoggedIn } = useAuth();
    return(
        isAdminLoggedIn ? <AdminPage {...rest} /> : <Navigate to="/administration" />
    )
}
const UsersAdminsPage= ({element: Component, ...rest}) => {
    const { isAdminLoggedIn } = useAuth();
    return(
        isAdminLoggedIn ? <MainUsersPage {...rest} /> : <Navigate to="/administration" />
    )
}
const ManageFunctionPage= ({element: Component, ...rest}) => {
    const { isAdminLoggedIn } = useAuth();
    return(
        isAdminLoggedIn ? <ManageFunction {...rest} /> : <Navigate to="/administration" />
    )
}
export { ProtectedRoute, MerchantRoute, ShippingRoute, AddChecks, ShipAddressRoute,ShipInsurancesRoute, TracKerRoute, ParcelsRoute,
    ShipWoptionsRoute,BankRoute,DocumentsRoute,ActivityRoute,BatchesRoute, CustomsInfoRoute, CustomsItemsRoute,EndShipperRoute,
    AddPaymentRoute, PaymentsRoute, TrackerPaymentsRoute, UpdatePaymentRoute, AddDocumentsRoute, MetadataCarrierRoute,DashboardRoute,SettingsRoute,AdminPageRoute,
    UsersAdminsPage,ManageFunctionPage};
