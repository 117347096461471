import React from 'react';
import {Link} from "react-router-dom";

const AnnouncedMerchant = () => {
    return(
        <>
            <section className="bg-gray-900">
                <div className="content-start p-14">
                    <h1 className="font-bold text-4xl text-white"><span className="text-primaryV">To Be</span> Annouced</h1>
                    <p className="text-white mb-8">We offer a payment network that is reliable and secure, with advanced risk
                        management and AML measures in place. Plus, our real-time payment processing ensures that you can get paid quickly and easily.</p>
                    <Link to="/contact">
                        <button className="bg-primaryV rounded-full"><p className="text-white font-semibold p-2">Contact Us</p></button>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default AnnouncedMerchant;