import React from 'react';


const NotFound = () => {
    const playerStyle = {
        width: '600px',
        height: '600px',
    };

    const url = window.location.pathname;

    return(
        <>
            <div className="grid grid-cols-1 justify-center content-center w-[100%] h-[100%] overf">
                <nav className="flex justify-center items-center w-full h-full">
                    <dotlottie-player src="https://lottie.host/e95d6a27-ba87-4533-bebd-a106649d88ef/76irKOD0DZ.json" speed="1" style={playerStyle} loop autoplay></dotlottie-player>
                </nav>
                <h1 className="font-black text-center text-2xl text-card">404 Page <span className="font-bold text-primary mx-4">"{url}"</span> Not Found </h1>
                <h1 className="font-semibold text-center text-md text-card">You should try with "/"</h1>
            </div>
        </>
    )
}
export default NotFound;