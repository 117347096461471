import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow"

export default function Top (){
    const texts = [
        'Your Financial Solutions Partner',
        'Complete Global Protection',
        'On Demand Same Day Delivery',
        'Guaranteeing Against Chargebacks',
        'Keep Your Current Technology Seamlessly Integrated Systems',
        'Thousands of Technology Options and Integrations'
    ];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [texts.length]);
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();
    return(
        <>
            <nav className="mt-24 diagonal-gradient w-full lg:h-[850px] md:h-[650px] sm:h-auto py-12 sm:py-2">
                <div className="conic-gradient w-[25%] h-60 hidden lg:block absolute opacity-75 rounded-br-full"></div>
                <section className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2 lg:mx-28 md:mx-8 sm:mx-8 lg:py-32 md:py-4 sm:py-4 ">
                        <nav className={`p-2 sm:p-32 text-center sm:text-start  ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}>
                            <h1 className="font-bold text-4xl sm:text-5xl p-2"><span className="gradient-text">Mone</span><span className="gradient-text-green">Sure</span> is</h1>
                            <h2 className="text-xl sm:text-2xl p-4 font-bold text-primaryV animate-pulse">{texts[currentTextIndex]}</h2>
                            <p className="font-semibold text-sm sm:text-lg p-2">Welcome to the World's Trusted Network for Multi-Payment Management and Guarantee:</p>
                            <p className="text-sm sm:text-lg p-2 mb-4">Welcome to our secure platform for managing diverse payments efficiently. Trust us for seamless transactions and guaranteed services.</p>
                            <div className="text-center sm:text-start ">
                                <Link to="/service"><button className="bg-primaryV p-3 rounded-full hover:bg-blue-700"><p className="font-bold text-white dark:text-gray-800 px-4">View Works</p></button></Link>
                                <Link to="/contact"><button className="bg-gray-100 dark:bg-green-300 border border-green-800 p-3 mx-4 rounded-full hover:bg-green-600 hover:text-white"><p className="font-bold text-gray-800 dark:text-gray-800 px-4">Contact us</p></button></Link>
                            </div>
                        </nav>
                        <nav className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden justify-center content-center ${isVisibleCard21 ? 'animated-left' : 'visible'}`} ref={refCard21}>
                            <img src="/img.jpg" alt="default" className="object-cover rounded-3xl h-96"/>
                        </nav>
                </section>
            </nav>
        </>
    );
};
