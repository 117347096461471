import React from 'react';
import ShipInsurances from '../../Register/forms/shipInsurances_form';

const Insurances = () =>{
    return(
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl- lg:pr-10 md:pl-72 py-2">
                        <ShipInsurances/>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Insurances