import React from 'react';
import {Link} from "react-router-dom";

const SlideVShipping = () =>{
    return(
        <>
            <section className="bg-gradient-to-l from-oscuro from-40% to-card2 to-90% w-full h-fit content-center">
                <nav
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center px-16">
                    <div>
                        <img src="/loginBg.svg" alt="" className="h-full w-full"/>
                    </div>
                    <div className="content-center ">
                        <h1 className="text-white font-semibold text-4xl p-4">Empowering You to Manage Your
                            Shipping</h1>
                        <p className="text-white text-md p-4">We offer a shipping network that is reliable and secure,
                            with advanced tracking and risk management measures in place. Plus, our real-time shipping
                            updates ensure that you stay informed and in control of your deliveries.</p>
                        <p className="text-white text-md p-4 mb-8">Our platform integrates with major carriers, allowing
                            you to easily and securely manage shipments across different services.</p>
                        <Link to="/pricing">
                            <button className="bg-blue-600 rounded-full"><p
                                className="text-white font-semibold p-3 mx-6">Learn More</p></button>
                        </Link>
                    </div>

                </nav>
            </section>
        </>
    );
}
export default SlideVShipping;