import React, {useEffect, useState} from 'react';
import useVisibility from "../../../JS/Lazylow";
import axios from "axios";

const ShippingAddressForm = () => {
    const { ref: refCard, isVisible: isVisibleCard } = useVisibility();
    const { ref: refCard2, isVisible: isVisibleCard2} = useVisibility();
    const [street1, setStreet1]= useState("");
    const [street2, setStreet2] = useState("");
    const [city, setCity] = useState("");
    const [states1, setStates1] = useState("");
    const [zip, setZip] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [countrys, setCountrys] = useState([]);
    useEffect(() => {
        axios.get('http://localhost:5000/api/country_list')
            .then(response => {
                setCountrys(response.data);
            })
            .catch(error => console.error('Error fetching languages:', error));

    }, []);
    const [formData, setFormData] = useState({
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        company: '',
        phone: '',
        country: '',
        user_profile_id: '',
    })
    const [buttonValue, setButtonValue] = useState('Shipping Address');
    const [isLoading, setIsLoading] = useState(false);
    const validation = (value, pattern) => {
        return pattern.test(value);
    };
    const handle = (e) =>{
        e.preventDefault();
        const { name, value } = e.target;
        let pattern;
        switch(name) {
            case "street1":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            case "street2":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s\-']+$/;
                break;
            case "city":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "state":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "company":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "zip":
                pattern = /^\d{1,28}$/;
                break;
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            default:
                pattern = null;
        }

        const formattedValues = value.replace(/-/g, '');
        const isValid = pattern && validation(formattedValues, pattern);

        if (value === '' || isValid) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = formattedValues.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "street1":
                    setStreet1(formattedInput);
                    break;
                case "street2":
                    setStreet2(formattedInput);
                    break;
                case "city":
                    setCity(formattedInput);
                    break;
                case "company":
                    setCompany(formattedInput);
                    break;
                case "zip":
                    setZip(formattedInput);
                    break;
                case "state":
                    setStates1(formattedInput);
                    break;
                case "phone":
                    setPhone(formattedInput);
                    break;
                default:
            }
        }
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const formatedData = {
            ...formData,
            user_profile_id: storedUser.profile.user_profile_id
        };
        console.log(formatedData)
        fetch('http://127.0.0.1:5000/api/v5/address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formatedData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(error => {
                        throw new Error(error.message || 'Server responded with an error.');
                    });
                }
            })
            .then(data => {
                console.log(data)
                    setIsLoading(false)
                    setButtonValue('Send Information');
                    const alertaFine = document.getElementById('alertaS');
                    alertaFine.classList.remove('hidden');
                    alertaFine.classList.add('flex');
                    setTimeout(() => {
                        alertaFine.classList.add('hidden');
                        alertaFine.classList.remove('flex');
                    }, 3000);

            })
            .catch(error => {
                setIsLoading(false)
                setButtonValue('Send Information');
                const alertaErr = document.getElementById('alertaE');
                const text = document.getElementById('text');
                alertaErr.classList.remove('hidden');
                alertaErr.classList.add('flex');

                text.innerText = 'An error occurred: ' + error.message;
                setTimeout(() => {
                    alertaErr.classList.add('hidden');
                    alertaErr.classList.remove('flex');
                }, 3000);
            });
    }
    return (
        <>
            <div className="w-full flex flex-col justify-center items-center">
                <dl className={"w-2/3 p-4 bg-blue-50 rounded-xl border-t-8 border-blue-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Shipping Address</h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can shipping an Address</p>
                </dl>
            </div>
            <nav id={"alertaS"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium">Success alert! </span>Shipment address was completed successfully.
                    </div>
                </div>
            </nav>
            <nav id={"alertaE"} className={"fixed z-50 top-8 right-0 left-0 justify-center items-center w-full hidden"}>
                <div
                    className="w-fit flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                    role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        <span className="font-medium space-x-2">Error alert! <p id={"text"}></p></span>
                    </div>
                </div>
            </nav>
            <section className="w-full h-fit grid-cols-1 justify-center px-4 sm:px-24">
                <nav className="w-full py-24 ">
                    <form onSubmit={handleSubmit}>
                        <dt className={` rounded-xl border-2 col-span-2 bg-white shadow-3xl p-8 ${isVisibleCard ? 'animated-right' : 'visible'}`}
                            ref={refCard}>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="street1" value={street1} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }} id="floating_street"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_street"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                                        Street</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="street2" value={street2} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }} id="floating_street2"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_street2"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Second
                                        Street</label>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="city" value={city} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }} id="floating_city"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder="" required/>
                                    <label htmlFor="floating_cirty"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">City
                                        Name</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="state" value={states1} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }} id="floating_statE"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required maxLength={2}/>
                                    <label htmlFor="floating_statE"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">State
                                        Initials</label>
                                </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="zip" value={zip} onChange={(e) => {
                                    handleInputChange(e);
                                    handle(e)
                                }} id="floating_zip"
                                       className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_zip"
                                       className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Zip
                                    number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select
                                    id="underline_select_country"
                                    name="country"
                                    value={formData.country}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    className="block py-2.5 px-0 w-full text-base text-gray-600 bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                    <option value="" disabled>Country (US, MX)</option>
                                    {Object.entries(countrys).map(([code, name]) => (
                                        <option key={code} value={code}>{name}</option>
                                    ))}

                                </select>
                            </div>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="company" value={company} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }}
                                           id="floating_company"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_company"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company
                                        Name</label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input type="text" name="phone" value={phone} onChange={(e) => {
                                        handleInputChange(e);
                                        handle(e)
                                    }} id="floating_phone"
                                           className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                           placeholder=" " required/>
                                    <label htmlFor="floating_phone"
                                           className="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company
                                        Phone number</label>
                                </div>
                            </div>
                        </dt>
                        <div
                            className={`flex justify-center sm:mx-28 space-x-3 sm:space-x-26 mt-12 ${isVisibleCard2 ? 'animated-card' : 'visible'}`}
                            ref={refCard2}>
                            <button type="submit" disabled={isLoading}
                                    className=" text-gray-200 bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-2/3 px-5 sm:px-24 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{isLoading ? (
                                <div role="status" className="">
                                    <svg
                                        aria-hidden="true"
                                        className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                buttonValue
                            )}
                            </button>

                        </div>
                    </form>
                </nav>
            </section>
        </>
    )
}

export default ShippingAddressForm;