import React from 'react';
import {Link} from "react-router-dom";
// import {useState, useEffect} from 'react';


const Previews = () => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    return(
        <>
            <section className="w-full h-fit grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:justify-center md:justify-end sm:justify-center gap-8 px-4">
                <div
                    className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 bg-green-100 w-full p-8 rounded-3xl col-span-2 ">
                    <nav className="flex flex-col justify-center">
                        <h1 className="font-bold text-2xl text-green-800">Welcome back!!</h1>
                        <h2 className="font-semibold text-lg text-green-800">{storedUser.name}</h2>
                        <h2 className="font-semibold text-lg text-green-800">{storedUser.email}</h2>
                        <p className="py-8">Go to manage all user in your company.</p>
                        <Link to={"/profile/dash"}>
                            <button className="bg-card2 rounded-lg p-2 font-bold text-white w-24">
                                Go Now
                            </button>
                        </Link>
                    </nav>
                    <nav className="flex justify-center items-center w-full ">
                        <img src="/card1.svg" alt="img-default" className="object-contain h-44"/>
                    </nav>
                </div>
                <div id="default-carousel" className="relative w-full content-center hidden sm:grid" data-carousel="slide">
                    <div className="relative h-96 overflow-hidden rounded-2xl sm:h-96 ">
                        <div className=" duration-2000 ease-in-out" data-carousel-item="">
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <img src="/slide1.jpg" className="object-cover w-full h-full" alt="..."/>
                                <nav className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-primary">
                                    <h1 className="text-white font-bold text-lg">Full access</h1>
                                    <p className="text-white">On this admin pag you can manage your users and their activity.</p>
                                </nav>
                            </div>
                        </div>
                        <div className="hidden duration-2000 ease-in-out" data-carousel-item="">
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <img src="/sldie2.jpg" className="object-cover w-full h-full" alt="..."/>
                                <nav className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-primary">
                                    <h1 className="text-white font-bold text-lg">Slide 2</h1>
                                    <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ante ipsum primis.</p>
                                </nav>
                            </div>
                        </div>
                        <div className="hidden duration-2000 ease-in-out" data-carousel-item="">
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <img src="/slide3.jpg" className="object-cover w-full h-full" alt="..."/>
                                <nav className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-primary">
                                    <h1 className="text-white font-bold text-lg">Slide 3</h1>
                                    <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ante ipsum primis.</p>
                                </nav>
                            </div>
                        </div>
                        <div className="hidden duration-2000 ease-in-out" data-carousel-item="">
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <img src="/slide4.jpg" className="object-cover w-full h-full" alt="..."/>
                                <nav className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-primary">
                                    <h1 className="text-white font-bold text-lg">Slide 4</h1>
                                    <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ante ipsum primis.</p>
                                </nav>
                            </div>
                        </div>
                        <div className="hidden duration-2000 ease-in-out" data-carousel-item="">
                            <div className="w-full h-full flex flex-col justify-center items-center">
                                <img src="/slide5.jpg" className="object-cover w-full h-full" alt="..."/>
                                <nav className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-primary">
                                    <h1 className="text-white font-bold text-lg">Slide 5</h1>
                                    <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ante ipsum primis.</p>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <button type="button"
                            className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-prev="">
                            <span
                                className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-primary dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 1 1 5l4 4"/>
                                </svg>
                                <span className="sr-only">Previous</span></span>
                    </button>
                    <button type="button"
                            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                            data-carousel-next="">
                            <span
                                className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-primary dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="m1 9 4-4-4-4"/>
                                </svg>
                                <span className="sr-only">Next</span> </span>
                    </button>
                </div>
            </section>
        </>
    )
}

export default Previews