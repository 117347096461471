import React from 'react';
import {Link} from "react-router-dom";
import useVisibility from "../../JS/Lazylow";

const Sales = () => {
    const { ref: refCard20, isVisible: isVisibleCard20 } = useVisibility();
    const { ref: refCard21, isVisible: isVisibleCard21 } = useVisibility();
    // ${isVisibleCard20 ? 'animated-right' : 'visible'}`} ref={refCard20}
    return(
        <>
            <nav
                className="bg-gradient-to-tl from-white from-20% to-gradL to-98%  w-full h-fit  text-center sm:text-start">
                <div
                    className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  sm:gap-1  justify-items-center mx-2 lg:mx-44 md:mx-8 sm:mx-8 lg:py-18 md:py-4 sm:py-4 ">
                    <nav className={`p-8 content-center ${isVisibleCard20 ? 'animated-right' : 'visible'}`}
                         ref={refCard20}>
                        <h1 className="text-3xl text-center sm:text-start font-semibold p-6"><span
                            className="text-oscuro font-bold">Sales Marketing</span> And Advertising</h1>
                       <p className="text-sm mx-6 mb-2">Through our integrated partnership with esellex.com, you can effortlessly send invoices to thousands of recipients and independently sell your products without altering your existing setup.
                           Our platform also offers seamless advertising opportunities across various channels, including websites, texts, billboards, emails, and TV, allowing you to reach your target market with ease.</p>
                        <p className="text-sm mx-6">Sign up today to achieve your financial goals and experience the following benefits:Sign up today to achieve your financial goals and experience the following benefits:</p>
                        <ul className="list-disc px-8">
                            <li className="mx-6 py-2"><p><span className="text-lg font-bold">FDIC-Insured Accounts:</span> Rest
                                assured knowing that your funds are protected by the US government.</p></li>
                            <li className="mx-6 py-2"><p><span className="text-lg font-bold">Multimedia Invoicing: </span> Our
                                innovative invoice system streamlines the selling process, enabling you to market your
                                products efficiently without disrupting your current sales channels.</p></li>
                            <li className="mx-6 py-2"><p><span className="text-lg font-bold">Dynamic Pricing: </span> Easily tailor your product offerings to different groups with integrated payment solutions, all with the click of a button.</p></li>
                        </ul>
                        <p className="text-sm mx-6 py-2">Join us at MoneSure and esellex.com to streamline your business operations and maximize your sales potential.</p>
                        <nav className="mt-4 justify-items-center content-center ">
                            <Link to="/contact">
                                <button className="bg-primaryV rounded-full p-2"><p
                                    className="font-semibold text-white mx-6 text-sm">CONTACT US</p></button>
                            </Link>
                        </nav>
                    </nav>
                    <nav
                        className={`p-2 sm:p-12 hidden lg:block  md:hidden sm:hidden content-center ${isVisibleCard21 ? 'animated-up' : 'visible'}`}
                        ref={refCard21}>
                        <img src="/sales.jpg" alt="" className="object-cover h-80 rounded-tl-3xl rounded-br-3xl shadow"/>
                    </nav>

                </div>
            </nav>
        </>
    );
}
export default Sales;