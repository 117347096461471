import React from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
};
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};
const DocsModal = ({handleCloseDelete, openD, open, handleCloseDetails, selectedDoc  }) => {

return(
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleCloseDetails}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}>
                    <Fade in={open}>
                        <Box sx={style}>
                            {selectedDoc && (
                                <>
                                    <div
                                        className="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex justify-end px-4 pt-4">
                                            <button id="dropdownButton" onClick={handleCloseDetails}
                                                    className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                                    type="button">
                                                <span className="sr-only">Open dropdown</span>
                                                <svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                     height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round" strokeWidth="1"
                                                          d="M6 18 17.94 6M18 18 6.06 6"/>
                                                </svg>

                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center pb-10">
                                            <img className="w-24 h-24 mb-3 rounded-full shadow-lg"
                                                 src="/testimonial-avatar.png" alt="Avatars"/>
                                            <dt className={"grid grid-cols-1 justify-center text-center items-center"}>
                                                <h1 className={"font-bold text-xl "}>Recipient Name:</h1>
                                                <p
                                                    className="mb-1 text-md text-gray-900 dark:text-white">{selectedDoc.recipient}, {selectedDoc.id}</p>
                                            </dt>
                                            <dt className={"grid grid-cols-1 justify-center text-center items-center"}>
                                                <h1 className={"font-bold text-xl"}>Internal Reference</h1>
                                                <p className="mb-1 text-md text-gray-900 dark:text-white">{selectedDoc.ref}</p>
                                            </dt>
                                            <dt className={"grid grid-cols-1 justify-center text-center items-center"}>
                                                <h1 className={"font-bold text-xl"}>City</h1>
                                                <span
                                                    className="text-sm text-gray-500 dark:text-gray-400 text-center p-4">{selectedDoc.city}</span>
                                            </dt>
                                            <dt className={"grid grid-cols-1 justify-center text-center items-center"}>
                                                <h1 className={"font-bold text-xl"}>State</h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{selectedDoc.state}</span>
                                            </dt>
                                            <dt className={"grid grid-cols-1 justify-center text-center items-center"}>
                                                <h1 className={"font-bold text-xl"}>Date to creation</h1>
                                                <span
                                                    className="text-base text-gray-800 dark:text-gray-400 text-center font-medium p-2">{formatDate(selectedDoc.date)}</span>
                                            </dt>

                                        </div>
                                    </div>

                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openD}
                    onClose={handleCloseDelete}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openD}>
                        <Box sx={style}>
                            {selectedDoc && (
                                <>
                                    <div
                                        className=" w-full grid justify-center  p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
                                        role="alert">
                                        <div className="flex flex-col text-lg content-center">
                                            <span className="font-medium ">
                                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                                                                                 xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                                                 viewBox="0 0 20 20">
                                                <path
                                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                            </svg>Danger alert!</span>
                                            <span className="sr-only">Info</span>
                                            <h2>Are you sure to delete this {selectedDoc.recipient} account with
                                                wl_id "{selectedDoc.wl_id}"?</h2>
                                        </div>
                                        <div className={"w-full flex justify-center mt-8 space-x-4"}>
                                            <button
                                                className={"bg-red-700 text-white p-2  rounded-2xl w-fit text-md font-medium"}>Yes,
                                                I'm
                                                sure
                                            </button>
                                            <button onClick={handleCloseDelete}
                                                    className={"bg-blue-500 text-white p-2  rounded-2xl w-fit text-md font-medium"}>Cancel
                                            </button>
                                        </div>

                                    </div>
                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

    export default DocsModal;