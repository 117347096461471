import React, {useState} from 'react';

const MarketingStarted = () => {

    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");

    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;

        switch(name) {
            case "username":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            default:
                pattern = null;
        }

        const isValid = pattern ? pattern.test(value) : true;

        if (value === '' || isValid) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "username":
                    setUsername(formattedInput);
                    break;
                case "phone":
                    setPhone(formattedInput);
                    break;
                default:
            }
        }
    };
    const [buttonValue, setButtonValue] = useState('Send Email');
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        username: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        setButtonValue('Sending...');
        fetch('http://127.0.0.1:5000/api/v4/send_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    setButtonValue('Send Email');
                    alert('Sent!');
                } else {
                    throw new Error('Failed to send email');
                }
            })
            .catch((err) => {
                setButtonValue('Send Email');
                alert(err.message);
            });
    };


    return (
        <>
            <section className="bg-gradient-to-br from-card2 from-8% to-card3 to-10% w-full h-fit content-center py-12" id="seccion-destine1">
                <nav className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  justify-items-center mt-2 sm:mt-24 mx-2 sm:mx-44">
                    <h1 className="font-bold text-5xl text-start"><span className="text-card">Get</span> Started</h1>
                    <div className="text-start sm:text-center">
                        <p className="text-md text-center sm:text-start w-auto">
                            In Marketing section As a cloud based next generation platform, we provide global solutions that reduce risk,
                            increase sales, and provides credibility to our members while providing fraud protection and other loss issues.
                        </p>
                    </div>
                    <div className="bg-gradient-to-t from-oscuro to-card2 p-8 mt-8 w-auto sm:w-4/5 h-fit rounded-2xl">
                        <form  id="form" onSubmit={handleSubmit}  className="mt-12" >
                            <h1 className="text-4xl text-white font-bold p-4">Contact Us</h1>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="email" name="email"
                                       pattern="[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-]([\.]?[a-zA-Z0-9!#$%&'*\/=?^_`\{\|\}~\+\-])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?"
                                       id="floating_email"
                                       onChange={(e) => {
                                           handleInputChange(e)}}
                                       className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-800 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_email"
                                       className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email
                                    address</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="username" value={username} onChange={(e) => {
                                    handleInputChange(e)
                                    handle(e);
                                }} id="floating_first_name"
                                       className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_first_name"
                                       className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                            </div>

                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="phone" value={phone} onChange={(e) => {
                                    handleInputChange(e)
                                    handle(e);
                                }}
                                       id="floating_phone"
                                       className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_phone"
                                       className="peer-focus:font-medium absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                                    number (123-456-7890)</label>
                            </div>

                            <button type="submit" id="button"
                                    className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{buttonValue}
                            </button>
                        </form>
                    </div>
                    <div className="hidden lg:block justify-center items-center">
                        <img src="/started.png" alt=""
                             className="transform -translate-x-1/3 left-2/2 object-cover lg:h-[450px]"/>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default MarketingStarted;