import React from 'react';
import ParcelsForms from '../../Register/forms/Parcels_forms'
const Parcele = () => {

    return (
        <>
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 lg:pr-10 md:pl-72 py-2">
                        <ParcelsForms />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Parcele;