import React  from 'react';
import Menu from "../Components/navbar/menu";
import Top from "../Components/home/top";
import Slideshow from "../Components/home/slidehome";
import Providing from "../Components/home/providing";
import SlideV from "../Components/home/slideV";
import Services from "../Components/home/services";
import Announced from "../Components/home/announced";
import Carrousel from "../Components/home/carrouseltestimonail";
import Three from "../Components/home/three";
import Basic from "../Components/home/basic_cards";
import Started from "../Components/home/started";
import Footer from "../Components/footer/footer";
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";
const Home = () => {
    return(
        <>
            <Menu/>
            <Top/>
            <Slideshow/>
            <Providing/>
            <SlideV/>
            <Services/>
            <Announced/>
            <div className=" justify-center items-center hidden lg:flex mb-8">
                <Carrousel/>
            </div>
            <Three/>
            <Basic/>
            <Started/>
            <div>
                <CookieConsent
                    location="bottom"
                    buttonText="Yes, I agree !!"
                    cookieName="cookie permission"
                    style={{background: "#2B373B"}}
                    buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                    expires={150}
                >
                    Cookies Notice

                    We use cookies to enhance your experience, analyze site usage, and assist in our marketing efforts.
                    By clicking "Accept All Cookies," you agree to the storing of cookies on your device. For more
                    detailed information{" "}
                    <span style={{fontSize: "10px"}}><Link to="" className="underline hover:text-card">Visit our Cookies Policy</Link></span>
                </CookieConsent>
            </div>
            <Footer/>
        </>
    );
}

export default Home;