import React from 'react';
import { useLocation } from 'react-router-dom';
const Policy = () =>{
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pdfName = queryParams.get('pdf');

    return(
        <>
            <div style={{position: 'absolute', width: '100%', height: '100%'}}>
                <object data={require(`../anexos/${pdfName}`)}
                        width={"100%"} height={"100%"} type="application/pdf" aria-label="Descripción del PDF"></object>
            </div>

        </>
    );
}

export default Policy;