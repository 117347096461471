import React from 'react';

import Register from "../Components/Register/Basic/basic_register";

const RegisterUser = () => {
    return(
        <>
            <Register />
        </>
    )
}

export default RegisterUser;