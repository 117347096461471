import React, {useState} from 'react';
import ChecksModal from './BankModal';
import CreateModal from "./CreateModal";

const BankAccounts = ({bankAccounts}) => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectAccountChecks, setSelectAccountChecks] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpenDetails = (account) => {
        setSelectedAccount(account)
        setOpen(true);
    }
    const handleCloseDetails = () => setOpen(false);

    const [openD, setOpenD] = useState(false);
    const handleOpenDelete = (account) => {
        setSelectedAccount(account)
        setOpenD(true);
    }
    const handleCloseDelete = () => setOpenD(false);

    const [openM, setOpenM] = useState(false);
    const handleOpenModal = (account) => {
        setSelectAccountChecks(account)
        setOpenM(true);
    }
    const handleCloseModal = () => setOpenM(false);

    return(
        <>

            <ChecksModal
                handleOpenDetails={handleOpenDetails}
                handleOpenDelete={handleOpenDelete}
                handleCloseDetails={handleCloseDetails}
                handleCloseDelete={handleCloseDelete}
                selectedAccount={selectedAccount}
                open={open}
                openD={openD}
            />
            <CreateModal
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                selectAccountChecks = {selectAccountChecks}
                openM={openM}
            />
            <div className="w-full flex flex-col justify-center items-center mb-12">
                <dl className={"w-2/3 p-4 bg-green-50 rounded-xl border-t-8 border-green-300 flex flex-col justify-center items-center text-center sm:text-start"}>
                    <h1 className={"font-bold text-4xl"}>Bank Accounts </h1>
                    <p className={"py-2 text-md text-gray-400"}>Here you can manage the bank accounts</p>
                </dl>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Account Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Account Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Bank Names
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Bank Address
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Signature
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Account Details
                        </th>
                        <th>
                            Create a Check
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {bankAccounts.map(account => (

                        <tr key={account.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {account.name}
                            </th>
                            <td className="px-6 py-4">
                                {account.descrip}
                            </td>
                            <td className="px-6 py-4">
                                {account.acct_type}
                            </td>
                            <td className="px-6 py-4">
                                {account.bank_name}
                            </td>
                            <td className="px-6 py-4 space-x-2">
                                {account.bank_addr}
                            </td>
                            <td className="px-6 py-4 space-x-2">
                                {account.signature && (
                                    <img src={`${account.signature}`} alt="Bank Account" />
                                )}
                            </td>
                            <td className="px-6 py-4 space-x-2">
                                <button value={account.id} onClick={() => handleOpenDetails(account)}
                                        className="border-2 border-blue-400 p-2 rounded-xl text-blue-600 hover:text-white hover:bg-blue-500">Details
                                </button>
                            </td>
                            <td>
                                <button value={account.id} onClick={() =>  handleOpenModal(account)}
                                        className="border-2 border-green-400 p-2 rounded-xl text-green-600 hover:text-white hover:bg-green-500">Create
                                </button>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
                <div>
                    {/*<button value={account.id}*/}
                    {/*        className={"fixed bottom-8 right-8 bg-green-200 rounded-full w-16 h-16 hover:bg-card2"}*/}
                    {/*        onClick={() => {*/}
                    {/*            handleOpenModal(account)*/}
                    {/*        }}>*/}
                    {/*    <span className={"font-medium text-3xl space-x-4"}>+</span>*/}
                    {/*</button>*/}

                </div>
            </div>
        </>
    )
}

export default BankAccounts;