import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
import toast, {Toaster} from "react-hot-toast";

const Registers = () => {
    const [phone, setPhone] = useState("");
    const [first_name, setFirst] = useState("");
    const [last_name, setLast] = useState("");
    const [parent, setParents] = useState([]);
    const handle = (event) => {
        const { name, value } = event.target;
        let pattern;
        let sqlInjectionPattern;

        // Patrón para validación de entrada
        switch(name) {
            case "phone":
                pattern = /^\d{0,3}-?\d{0,3}-?\d{0,4}$/;
                break;
            case "name":
            case "last_name":
                pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\-']+$/;
                break;
            default:
                pattern = null;
        }

        // Patrón para prevenir la inyección SQL
        sqlInjectionPattern = /(;|\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER|CREATE|TRUNCATE|EXEC|UNION|FROM|WHERE|AND|OR)\b|[-'";\\])/i;

        const isValid = pattern ? pattern.test(value) : true;
        const isSafeFromInjection = !sqlInjectionPattern.test(value);

        if ((value === '' || isValid) && isSafeFromInjection) {
            let formattedInput = value;
            if (name === "phone") {
                formattedInput = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
            switch(name) {
                case "phone":
                    setPhone(formattedInput);
                    break;
                case "name":
                    setFirst(formattedInput);
                    break;
                case "last_name":
                    setLast(formattedInput);
                    break;
                default:
            }
        }
    }
    const [formData, setFormData] = useState({
        password: '',
        repeatPassword: '',
        name: '',
        last_name: '',
        parent_id: '',
        phone: '',
        email: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const Sign_in = () =>{

        return fetch('http://127.0.0.1:5000/api/v6/registro', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(error => {
                    throw new Error(error.error || 'Server responded with an error.');
                });
            }
        }).catch((err) => {
            console.log(err);
            throw new Error('Failed to log in');
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.password !== formData.repeatPassword) {
            toast('Please check passwords, are different');
            return;
        } else {
            try {
                await toast.promise(
                    Sign_in(),
                    {
                        loading: 'Sign in...',
                        success: <b>Register was successfully!</b>,
                        error: <b>Failed to Register.</b>,
                    }
                );
            } catch (error) {
                console.error('Error:', error);
                toast.error('Verify your information.');
            }
        }
    }
    useEffect(() => {
        axios.get('http://127.0.0.1:5000/api/get_parents')
            .then(response => {
                console.log(response.data)
                setParents(response.data)
            })
            .catch(error => console.error('Error fetching languages:', error));
    }, []);
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <section className={"w-full h-screen flex justify-center"}>
                <nav className="w-3/4 hidden sm:flex p-4">
                    <div className="w-full grid grid-cols-1 justify-items-center content-center">
                        <nav className="w-1/2">
                            <dotlottie-player
                                src="https://lottie.host/03ee8df9-0c21-4c0d-8ae5-6075f0af7dca/eKCtIvKZjn.json"
                                background="transparent" speed="0.5" loop
                                autoplay></dotlottie-player>
                        </nav>
                    </div>
                    <div className="fixed left-12 top-12 flex justify-center items-center">
                        <Link to="/"> <img src="/MoneSureLogoG.png" alt="" className="w-fit h-12"/></Link>
                    </div>
                </nav>
                <nav className="w-full sm:w-1/4 gradient-card-green p-4 flex-col justify-center content-center">
                    <div className="fixed top-12 right-8 flex justify-center">
                        <Link to={"/"} className="flex justify-center content-center"><HomeIcon
                            sx={{color: '#ffffff', width: 31, height: 31,}}
                        /><span className="text-white text-2xl">Home</span></Link>
                    </div>
                    <h1 className="font-bold text-white text-4xl sm:text-4xl w-full text-center p-8">Get started</h1>
                    <form className="mx-auto px-4" onSubmit={handleSubmit}>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="email" name="email" onChange={(e) => {
                                handleInputChange(e)
                            }} id="floating_email"
                                   className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_email"
                                   className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email
                                address</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="password" name="password" minLength="8" onChange={(e) => {
                                handleInputChange(e)
                            }} id="floating_password"
                                   className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_password"
                                   className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input type="password" name="repeatPassword" minLength="8" onChange={(e) => {
                                handleInputChange(e)
                            }} id="floating_repeat_password"
                                   className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                   placeholder=" " required/>
                            <label htmlFor="floating_repeat_password"
                                   className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm
                                password</label>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="name" value={first_name} onChange={(e) => {
                                    handleInputChange(e)
                                    handle(e);
                                }} id="floating_first_name"
                                       className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_first_name"
                                       className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                                    name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="last_name" value={last_name} onChange={(e) => {
                                    handleInputChange(e)
                                    handle(e);
                                }} id="floating_last_name"
                                       className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_last_name"
                                       className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last
                                    name</label>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">

                            <div className="relative z-0 w-full mb-5 group">
                                <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone" value={phone}
                                       onChange={(e) => {
                                           handleInputChange(e)
                                           handle(e);
                                       }}
                                       id="floating_phone"
                                       className="block py-2.5 px-0 w-full text-lg text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       placeholder=" " required/>
                                <label htmlFor="floating_phone"
                                       className="peer-focus:font-medium absolute text-lg text-white dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                                    number</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <select
                                    id="underline_select_parent"
                                    name="parent_id"
                                    value={formData.parent}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    className="block py-2.5 px-0 w-full text-base text-white bg-transparent font-semibold border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                    <option value="1">Company</option>
                                    {Object.entries(parent).map(([code, name]) => (
                                        <option key={code} value={code} className={"text-gray-900"}>{name}</option>
                                    ))}

                                </select>

                            </div>
                        </div>
                        <nav className="text-center space-x-0 sm:space-x-9 py-4">
                            <button type="submit"
                                    className="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign
                                Up
                            </button>
                            <Link to="/">
                                <button type="reset"
                                        className="text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel
                                </button>
                            </Link>
                        </nav>
                    </form>
                    <div className='flex p-4 mt-8'>
                        <p className="text-md font-semibold text-white px-4">Have an account?</p><Link to="/login">
                        <button className="text-center text-blue-700 font-bold underline ">Log in</button>
                    </Link>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Registers;