import React, {useEffect, useState} from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/Admin/sidebarAdmin";
import Navigation from "../../Components/Dashboard/GenericInAll/Admin/navigationAdmin";
import ManageUsersAdmin from "../../Components/Dashboard/AdminComponents/ManageUsersAdmin";
import axios from "axios";
import {CreateModal} from "../../Components/Dashboard/AdminComponents/dashboardModal/ModalAdmin";

const MainUsersPage = () => {
        const [companyUsers, setcompanyUsers] = useState([]);
        const [Parents, setParents] = useState([]);
        const [openCRT, setOpenCRT] = useState(false)

        useEffect(() => {
            fetchData();
            fetchParentsData();
        }, []);
        const handleCloseCreate = () => setOpenCRT(false);
        const handleCreate = () =>{
        setOpenCRT(true)
    }
        const fetchData = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:5000/api/get_parents_and_users');
                const jsonData = JSON.parse(JSON.stringify(response.data));
                setcompanyUsers(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchParentsData = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:5000/api/get_parents');
                const jsonData = JSON.parse(JSON.stringify(response.data));
                setParents(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    return(
        <>
            <SideBar />
            <Navigation />
            <section className="sm:flex">
                <div className="flex-1">
                    <div className="lg:pl-80 md:pl-72 py-2">
                        <ManageUsersAdmin companyUsers={companyUsers} Parents={Parents}/>
                    </div>
                    <CreateModal
                        handleCreate={handleCreate}
                        handleCloseCreate={handleCloseCreate}
                        Parents={Parents}
                        openC={openCRT}
                        fetchData = {fetchData}
                    />
                    <div className={"fixed bottom-4 right-4"}>
                        <button type="button" onClick={handleCreate}
                                data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default"
                                aria-expanded="false"
                                className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                            <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M9 1v16M1 9h16"/>
                            </svg>
                            <span className="sr-only">Open actions menu</span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainUsersPage;