import React from 'react';
import {Link} from 'react-router-dom';
const MarketingServices = () => {
    return(
        <>

            <section className="bg-gradient-to-l from-gradR to-gradL w-full h-fit content-center grid justify-items-center" id="seccion-destine">
                <h1 className="font-semibold text-4xl mt-8 sm:mt-12"><span
                    className="text-primaryV font-semibold">More </span><span className="gradient-text">Mone</span><span
                    className="gradient-text-green">Sure</span> Solution</h1>
                <p className="text-lg font-semibold p-4">Some Of The Key Features Of Our Platform Include</p>
                <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1  justify-items-center">
                    <nav className="border-2 border-gray-300 w-auto bg-white sm:w-96 cursor-pointer transition-colors duration-700 ease-in-out hover:text-white hover:bg-gradient-to-t hover:from-oscuro hover:to-card2">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">Local Solutions</h1>
                        <p className="text-base p-6 text-start">Tailored marketing strategies to enhance your local presence and attract more
                            customers.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white w-auto sm:w-96 transition-colors duration-700 ease-in-out hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">Content Creation</h1>
                        <p className="text-base p-6 text-start">Professional creation of posts, pictures, and videos for your website and
                            social media platforms.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeWidth="1"
                                      d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">SEO and Analytics</h1>
                        <p className="text-base p-6 text-start">Comprehensive SEO strategies and analytics to track your performance and
                            refine your approach</p>
                        <div className="p-4">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M4 4.5V19a1 1 0 0 0 1 1h15M7 14l4-4 4 4 5-5m0 0h-3.207M20 9v3.207"/>
                            </svg>
                        </div>
                    </nav>
                    <nav
                        className="lg:col-span-3 border-2 border-gray-300 bg-white transition-colors duration-700 ease-in-out w-auto sm:w-96 hover:bg-gradient-to-t hover:from-oscuro hover:to-card2 cursor-pointer hover:text-white">
                        <h1 className="font-semibold text-lg mt-8 mx-8 text-start">Reputation and Privacy Management</h1>
                        <p className="text-sm p-6 text-start">Tools to manage your online reputation and ensure your
                            business's privacy and security.</p>
                        <div className="p-8">
                            <svg className="w-20 h-20" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="1"
                                      d="M21 12a28.076 28.076 0 0 1-1.091 9M7.231 4.37a8.994 8.994 0 0 1 12.88 3.73M2.958 15S3 14.577 3 12a8.949 8.949 0 0 1 1.735-5.307m12.84 3.088A5.98 5.98 0 0 1 18 12a30 30 0 0 1-.464 6.232M6 12a6 6 0 0 1 9.352-4.974M4 21a5.964 5.964 0 0 1 1.01-3.328 5.15 5.15 0 0 0 .786-1.926m8.66 2.486a13.96 13.96 0 0 1-.962 2.683M7.5 19.336C9 17.092 9 14.845 9 12a3 3 0 1 1 6 0c0 .749 0 1.521-.031 2.311M12 12c0 3 0 6-2 9"/>
                            </svg>
                        </div>
                    </nav>
                </div>
                <Link to="/service" className="flex justify-center py-8">
                    <p className="font-bold text-lg">View All Services</p>
                    <button className="bg-primaryV rounded-full mx-4">
                        <svg className="w-8 h-8 text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                  d="m7 16 4-4-4-4m6 8 4-4-4-4"/>
                        </svg>
                    </button>
                </Link>
            </section>
        </>
    );
}

export default MarketingServices;