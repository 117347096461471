import React from 'react';
import SideBar from "../../Components/Dashboard/GenericInAll/sidebar";
import Navigation from "../../Components/Dashboard/GenericInAll/navigation";
import Batches from "../../Components/Dashboard/FormsProfile/batches"

const Batch = () => {
    return(
        <>
            <SideBar /><Navigation />
            <div>
                <Batches />
            </div>
        </>
    )
}

export default Batch