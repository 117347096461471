import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchParents = () => {
    const [datas, setDatas] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get('http://127.0.0.1:5000/api/get_parents/admin');
            setDatas(response.data);
        } catch (error) {
            console.error('Error fetching parents:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return [datas, fetchData];
};

export default useFetchParents;