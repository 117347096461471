import React from 'react';
import {Link} from "react-scroll";

const Maintable = () =>{
    return(
        <>
            <section className="w-auto h-fit flex justify-center content-center py-12 px-2">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead
                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-2 py-3">
                                Product name
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Color
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Category
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Price
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Action
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row"
                                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Apple MacBook Pro 17"
                            </th>
                            <td className="px-2 py-4">
                                Silver
                            </td>
                            <td className="px-2 py-4">
                                Laptop
                            </td>
                            <td className="px-2 py-4 text-red-400">
                                Disable
                            </td>
                            <td className="px-2 py-4">
                                $2999
                            </td>
                            <td className="px-2 py-4">
                                <Link to={"#"}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</Link>
                            </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row"
                                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Microsoft Surface Pro
                            </th>
                            <td className="px-2 py-4">
                                White
                            </td>
                            <td className="px-2 py-4">
                                Laptop PC
                            </td>
                            <td className="px-2 py-4 text-red-400">
                                Disable
                            </td>
                            <td className="px-2 py-4">
                                $1999
                            </td>
                            <td className="px-2 py-4">
                                <Link to={"#"}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</Link>
                            </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row"
                                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Magic Mouse 2
                            </th>
                            <td className="px-2 py-4">
                                Black
                            </td>
                            <td className="px-2 py-4">
                                Accessories
                            </td>
                            <td className="px-2 py-4 text-green-300">
                                Active
                            </td>
                            <td className="px-2 py-4">
                                $99
                            </td>
                            <td className="px-2 py-4">
                                <Link to={"#"}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</Link>
                            </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <th scope="row"
                                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Google Pixel Phone
                            </th>
                            <td className="px-2 py-4">
                                Gray
                            </td>
                            <td className="px-2 py-4">
                                Phone
                            </td>
                            <td className="px-2 py-4 text-green-300">
                                Active
                            </td>
                            <td className="px-2 py-4">
                                $799
                            </td>
                            <td className="px-2 py-4">
                                <Link to={"#"}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</Link>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row"
                                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Apple Watch 5
                            </th>
                            <td className="px-2 py-4">
                                Red
                            </td>
                            <td className="px-2 py-4">
                                Wearables
                            </td>
                            <td className="px-2 py-4 text-red-400">
                                Disable
                            </td>
                            <td className="px-2 py-4">
                                $999
                            </td>
                            <td className="px-2 py-4">
                                <Link to={"#"}
                                   className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </section>
        </>
    )
}

export default Maintable