import React from "react";
import ManageSellesure from "../Components/sellesure/manageSellesure";
import Menu from "../Components/navbar/menu";
// import Footer from "../Components/footer/footer";
const Sellesure =() =>{
    return(
        <>
            <Menu />
                <ManageSellesure/>
        </>
    )
}

export default Sellesure